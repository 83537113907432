import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ModalComponent from "../../shared/Modal/Modal";
import { getBlockedAccounts } from "../../../store/actions/settingsActions";
import { blockUser } from "../../../store/actions/practitionerRegAction";

class BlockedAccounts extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      modalOpen: false,
      selectedAccount: null,
    };
  }

  componentDidMount = () => {
    const { getBlockedAccounts } = this.props;
    getBlockedAccounts();
  };

  onHideModal = () => {
    this.setState({ modalOpen: false });
  };

  removeBlockedUser = (id) => {
    const { blockUser } = this.props;
    blockUser(id);
    this.setState({ modalOpen: false, selectedAccount: null });
  };

  render() {
    const { blockedAccounts } = this.props;
    const { modalOpen, selectedAccount } = this.state;
    return (
      <div id="blocked-accounts">
        <h1 className="blocked-accounts-title">Blocked Accounts</h1>
        <p className="blocked-accounts-subtitle">
          Manage the accounts whom you blocked.
        </p>
        {blockedAccounts.length ? (
          <div className="table-responsive-md">
            <table className="blocked-accounts-table table table-borderless">
              <thead>
                <tr>
                  <th scope="col" className="practitioner-table-head">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="practitioner-table-head text-right"
                  >
                    Location
                  </th>
                </tr>
              </thead>
              <tbody>
                {blockedAccounts.map((profile, i) => (
                  <tr key={profile._id}>
                    <td>
                      <div className="d-flex flex-row">
                        <img
                          src="/images/user.svg"
                          className="practitioner-profile-image"
                          alt="prac_profile"
                        />
                        <div className="d-flex flex-column ml-2">
                          <span className="practitioner-name">
                            {profile.blocked_practitioner.u_first_name}{" "}
                            {profile.blocked_practitioner.u_last_name}
                          </span>
                          <span className="practitioner-email">
                            {profile.blocked_practitioner.u_email}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      {profile.blocked_by_practitionerId.location &&
                      profile.blocked_by_practitionerId.location.address
                        ? profile.blocked_by_practitionerId.location.address.split(
                            ",",
                            2
                          )[0]
                        : "-"}
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        className="btn outline"
                        style={{ color: "#d4af37", fontSize: "0.75rem" }}
                        onClick={() =>
                          this.setState({
                            modalOpen: !modalOpen,
                            selectedAccount: profile.blocked_practitioner,
                          })
                        }
                      >
                        Unblock
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}

        <ModalComponent
          size="small"
          show={modalOpen}
          title={
            selectedAccount
              ? "Unblock " +
                selectedAccount.u_first_name +
                " " +
                selectedAccount.u_last_name
              : null
          }
          onHide={this.onHideModal}
        >
          <div>
            <p
              className="unblockHeading"
              style={{ fontSize: 19, fontWeight: "bold", color: "#212121" }}
            >
              Are you sure you want to unblock{" "}
              {selectedAccount
                ? selectedAccount.u_first_name +
                  " " +
                  selectedAccount.u_last_name
                : null}{" "}
              ?
            </p>
            <p
              className="unblockSubHeading mb-5"
              style={{ fontSize: 14, color: "#9c9c9c" }}
            >
              Unblocking this user will allow them to view your profile,
              content, and message you.
            </p>
            <div className="d-flex">
              <button
                type="button"
                onClick={() => this.onHideModal()}
                className="btn btn-outline-white mr-2 ml-auto"
                style={{ width: 109 }}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => this.removeBlockedUser(selectedAccount._id)}
                className="btn gt-btn"
              >
                Confirm
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getBlockedAccounts,
  blockUser,
};
const mapStateToProps = (state) => ({
  blockedAccounts: state.settings.blockedAccounts,
});
BlockedAccounts.propTypes = {
  getBlockedAccounts: PropTypes.func.isRequired,
  blockUser: PropTypes.func.isRequired,
};
BlockedAccounts.defaultProps = {
  blockedAccounts: [],
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockedAccounts);
