/* eslint-disable no-case-declarations */
import {
  ON_PAYMENT,
  SET_PAYMENT_LOADER,
  MEMBERSHIP_DETAILS,
  CLEAR_PAYMENT_FORM,
  SET_CREDIT_CARD_LOADER,
  ON_UPDATE_CARD,
  SET_PREMIUM_USER,
  SET_PURCHASED_CONTENT,
  SET_PROMO_CODE
} from '../actions/types';

const initialState = {
  membershipDetails: null,
  paymentMessage: {
    status: 'proccessing',
    head: 'Processing',
    subHead: 'Your Payment is Processing. Please wait for few seconds.',
  },
  paymentLoader: false,
  creditCardLoader: false,
  premiumUser: false,
  clearPaymentForm: false,
  cardUpdated: false,
  contentPurchased: false,
  promotions: null
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ON_PAYMENT:
      return {
        ...state,
        paymentMessage: payload,
        premiumUser: true,
      };
    case SET_PREMIUM_USER:
      return {
        ...state,
        premiumUser: payload,
      };
    case SET_PAYMENT_LOADER:
      return {
        ...state,
        paymentLoader: payload,
      };
    case SET_CREDIT_CARD_LOADER:
      return {
        ...state,
        creditCardLoader: payload,
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        promotions: payload,
      };
    case MEMBERSHIP_DETAILS:
      return {
        ...state,
        premiumUser: payload.premiumUser,
        membershipDetails: payload.membershipDetails,
      };
    case CLEAR_PAYMENT_FORM:
      return {
        ...state,
        clearPaymentForm: payload,
      };
    case ON_UPDATE_CARD:
      return {
        ...state,
        cardUpdated: payload.updated,
      };
    case SET_PURCHASED_CONTENT:
      return {
        ...state,
        contentPurchased: payload,
      };
    default:
      return state;
  }
};
export default searchReducer;
