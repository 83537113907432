import React, { Component } from 'react';
import { connect } from 'react-redux';
import './PractitionerLocationMap.css';

class PractitionerLocationMap extends Component {
  render() {
    const { locationAddress } = this.props;
    const address = locationAddress.split(",", 2);
    return (
      <div className="prac_location_map">
        <div id="map" className="map" />
        <div className="prac_location_body d-flex align-items-center justify-content-between">
          <div className="map_details p-3">
            <h6 className="mb-1">{address[0]}</h6>
            <p className="mb-0">{locationAddress.slice(address[0].length + 1)}</p>
          </div>
          <div className="map_icon m-3 px-2 py-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M12.782.105a.343.343 0 0 1 .166.291v10.177a.315.315 0 0 1-.053.179.382.382 0 0 1-.14.125l-4.067 2.034a.364.364 0 0 1-.305 0l-3.915-1.96-3.922 1.96a.206.206 0 0 1-.073.027.483.483 0 0 1-.172-.003.247.247 0 0 1-.086-.037.343.343 0 0 1-.16-.291V2.43a.347.347 0 0 1 .185-.305L4.316.092A.364.364 0 0 1 4.468.06c.053 0 .101.011.145.033l3.922 1.961 3.922-1.96a.309.309 0 0 1 .163-.037c.06.002.113.019.162.05zM4.123 10.361V.947L.731 2.643v9.414l3.392-1.696zM4.806.947v9.414l3.391 1.696V2.643L4.806.947zm7.46 9.414V.947L8.872 2.643v9.414l3.392-1.696z"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
  };
};

export default connect(mapStateToProps)(PractitionerLocationMap);
