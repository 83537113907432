import {
  LOGIN_SUCCESS,
  SET_CURRENT_USER,
  SET_AUTH_ERROR,
  SET_OAUTH_USER,
  SET_PROFILE,
  SET_USER_TYPE,
  SET_UPDATE,
  ANSWERING,
} from '../actions/types';

const initialState = {
  isAuthenticated: false,
  isOAuthenticated: false,
  userType: '',
  socialUser: null,
  profile: null,
  currentUser: null,
  error: null,
  isAnswering: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.user,
        error: null,
      };
    case SET_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        error: null,
        currentUser: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_OAUTH_USER:
      return {
        ...state,
        socialUser: action.payload,
        // error: null,
        // currentUser: action.payload,
        // isOAuthenticated: true,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      }
    case SET_UPDATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          assessmentResult: {
            ...state.profile.assessmentResult,
            answers: state.profile.assessmentResult.answers.map((answer) => {
              if (answer.question_id._id === action.payload.question_id) {
                return {
                  ...answer,
                  assessment_answer: action.payload.assessment_answer,
                  additional_detailes:action.payload.additional_detailes
                }
              }
              return answer
            }),
          },
        },
      }
    case ANSWERING:
      return {
        ...state,
        isAnswering: action.payload,
      }
    default:
      return state;
  }
};

export default authReducer;
