import React from 'react';
import { Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import './GTCarousel.css';

const GTCarousel = ({ testimonials }) => {
  const carouselItems = testimonials.map(item => (
    <Carousel.Item key={item._id}>
      <div className="px-5 mb-5" style={{ color: '#fff' }}>
        <p className="testimonial-text">{item.t_message}</p>
        <p className="text-center testimony text-capitalize">
          <img src={item.t_image_url || '/images/user.svg'} alt="testimony" />
          - { item.t_name }
        </p>
      </div>
    </Carousel.Item>
  ));

  return (
    <div className="text-center">
      <img className="mb-4" src="/images/quate.png" alt="qaute-icon" />
      <Carousel direction={null} controls={false}>
        {carouselItems}
      </Carousel>
    </div>
  );
};

const mapStateToProps = state => ({
  testimonials: state.app.testimonials
})

export default connect(mapStateToProps)(GTCarousel);
