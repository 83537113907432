/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './UserFeeds.css';
import Navbar from '../../shared/Navbar/Navbar';
import { getFeedContents } from '../../../store/actions/contentAction';
// import FeedTypeTwo from './FeedTypeTwo';
import Footer from '../../shared/Footer/Footer';
import Focus from '../Landing/Focus';
import SlidingCard from '../../shared/SlidingCard/SlidingCard';
import categorySliderSettings from '../Landing/categorySliderSettings';
import { logoutUser } from '../../../store/actions/authActions';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import LandingSkeleton from '../Landing/LandingSkeleton';
import PractitionerCard from '../../shared/Cards/PractitionerCard/PractitionerCard';
import settings from '../../shared/SlidingCard/settings';
import ContentListCard from '../../shared/Cards/ContentListCard/ContentListCard'

class UserFeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  goToDetails = id => {
    const { history } = this.props;
    history.push(`/content/${id}`);
  };

  componentDidMount = async () => {
    const { getFeedContents, logoutUser } = this.props;
    this.setState({ loading: true });
    getFeedContents(err => {
      this.setState({ loading: false });
      if (err && err.errorCode === 401) {
        logoutUser();
      }
    });
  };
  renderSmallTiles = (contents) => {
    // const { content, history } = this.props;
    const List = contents.map(cont => (
      <ContentListCard
        key={cont._id}
        content={cont}
        onClick={() => this.goToDetails(cont._id)}
      />
    ));
    return List;
  };

  render() {
    const { focuses, recommended, popular, hotNew, recent, featuredPractitioners, newPractitioners, recommendedPrac, history } = this.props;
    const { loading } = this.state;
    const Focuses = focuses.map(focus => <Focus key={focus._id} focus={focus} />);

    const RecommendedContents = recommended.map(content => (
      <div style={{flex: "0 0 32%"}}><ContentCard content={content} onClick={() => this.goToDetails(content._id)} /></div>
    ));
    const PopularContents = popular.map(content => (
      <div style={{flex: "0 0 32%"}}><ContentCard content={content} onClick={() => this.goToDetails(content._id)} /></div>
    ));
    const HotNewContents = hotNew.map(content => (
      <ContentCard content={content} onClick={() => this.goToDetails(content._id)} />
    ));
    const FeaturedPractitioners = featuredPractitioners.map(practitioner => (
      <PractitionerCard
        onClick={username => history.push(`/${username}`)}
        // key={practitioner._id}
        practitioner={practitioner}
      />
    ));
    const NewPractitioners = newPractitioners.map(practitioner => (
      <PractitionerCard
        onClick={username => history.push(`/${username}`)}
        // key={practitioner._id}
        practitioner={practitioner}
      />
    ));
    // const NewPractitionerContents = newPractitioner.map(content => (
    //   <ContentCard content={content} onClick={() => this.goToDetails(content._id)} />
    // ));
    const RecentContents = recent.map(content => (
      <div style={{flex: "0 0 32%"}}><ContentCard content={content} onClick={() => this.goToDetails(content._id)} /></div>
    ));
    const RecommendedPrac = recommendedPrac.map(prac => (
      <PractitionerCard onClick={username => history.push(`/${username}`)} practitioner={prac} />
    ));

    return (
      <>
        <Navbar />
        <div className="container my-4" style={{ minHeight: '80vh' }}>
          {loading ? (
            <LandingSkeleton />
          ) : (
            <>
              <section className="overflow-hidden mb-4" id="focuses-section">
                <SlidingCard
                  settings={categorySliderSettings}
                  cardNumber={5.5}
                  heading="Choose Your Focus"
                >
                  {Focuses}
                </SlidingCard>
              </section>
              {
                RecommendedPrac.length > 0 &&
                <section className="mb-4 position-relative">
                  <Link to="/recommended-practitioners" className="btn btn-link top-right pr-0">
                    View all
                  </Link>
                  <SlidingCard
                    settings={settings}
                    cardNumber={4}
                    arrows={false}
                    heading="Recommended Practitioners"
                  >
                    {RecommendedPrac}
                  </SlidingCard>
                </section>
              }
              {
                RecommendedContents.length > 0 &&
                <section className="mb-4 position-relative">
                  <div
                      className="section-header d-flex justify-content-between align-items-center py-2 mb-3"
                      style={{ borderBottom: 'solid 1px #eaedf3' }}
                  >
                    <h2 className="slider-title h2 m-0 text-truncate mr-5">Recommended For You</h2>
                  </div>
                  <Link to="/recommended-for-you" className="btn btn-link top-right pr-0">
                    View all
                  </Link>                
                  <div style={{display: "flex"}}>
                  <div style={{display: "flex", flex: "1 2 75%", justifyContent:"space-between", width: "75%"}}>                 
                    {RecommendedContents.slice(0, 3)}
                  </div>
                  <div className="section-hide" style={{flex: "1 2 25%", marginTop: "-23px", marginLeft:"20px"}}>
                  {this.renderSmallTiles(recommended.slice(3))}
                  </div>
                  </div>
                </section>
              }       
              {FeaturedPractitioners.length > 0 && (
                <section className="overflow-hidden">
                  <SlidingCard settings={settings} cardNumber={4} heading="Featured Practitioners">
                    {FeaturedPractitioners}
                  </SlidingCard>
                </section>
              )}
              {
                RecentContents.length > 0 &&
                <section className="mb-4 position-relative">
                  <div
                    className="section-header d-flex justify-content-between align-items-center py-2 mb-3"
                    style={{ borderBottom: 'solid 1px #eaedf3' }}
                  >
                    <h2 className="slider-title h2 m-0 text-truncate mr-5">Popular</h2>
                  </div>
                  <Link to="/popular" className="btn btn-link top-right pr-0">
                    View all
                  </Link>               
                  <div style={{display: "flex"}}>
                    <div style={{flex: "1 2 25%", marginTop: "-23px", marginRight:"20px"}}>
                    {this.renderSmallTiles(popular.slice(3))}
                    </div>
                    <div className="section-hide" style={{display: "flex", flex: "1 2 75%", justifyContent:"space-between"}}>                 
                      {PopularContents.slice(0, 3)}
                    </div>                  
                  </div>
                </section>
              }
              {NewPractitioners.length > 0 && (
                <section className="overflow-hidden">
                  <SlidingCard settings={settings} cardNumber={4} heading="New Practitioners">
                    {NewPractitioners}
                  </SlidingCard>
                </section>
              )}
              {
                HotNewContents.length > 0 &&
                <section className="mb-4 position-relative">
                  <Link to="/hot-and-new" className="btn btn-link top-right pr-0">
                    View all
                  </Link>
                  <SlidingCard
                    settings={settings}
                    cardNumber={4}
                    arrows={false}
                    heading="Hot & New"
                  >
                    {HotNewContents}
                  </SlidingCard>
                </section>
              }                    
              {
                RecentContents.length > 0 &&
                <section className="mb-4 position-relative">
                  <div
                    className="section-header d-flex justify-content-between align-items-center py-2 mb-3"
                    style={{ borderBottom: 'solid 1px #eaedf3' }}
                  >
                    <h2 className="slider-title h2 m-0 text-truncate mr-5">Recently Added</h2>
                  </div>
                  <Link to="/recently-added" className="btn btn-link top-right pr-0">
                    View all
                  </Link>               
                  <div style={{display: "flex"}}>
                    <div style={{flex: "1 2 25%", marginTop: "-23px", marginRight:"20px"}}>
                    {this.renderSmallTiles(recent.slice(3))}
                    </div>
                    <div className="section-hide" style={{display: "flex", flex: "1 2 75%", justifyContent:"space-between"}}>                 
                      {RecentContents.slice(0, 3)}
                    </div>                  
                  </div>
                </section>
              }
            </>
          )}
        </div>
        <Footer />
      </>
    );
  }
}

UserFeeds.propTypes = {
  focuses: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFeedContents: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => {
  return {
    focuses: state.app.focuses,
    recommended: state.content.recommendedFeeds,
    popular: state.content.popularFeeds,
    hotNew: state.content.hotNew,
    recent: state.content.recent,
    newPractitioner: state.content.newPractitioner,
    content: state.content.content,
    recommendedPrac: state.content.recommendedPracFeeds,
    featuredPractitioners: state.content.featuredPractitioners,
    newPractitioners: state.content.newPractitioners,
  };
};
export default connect(
  mapStateToProps,
  { getFeedContents, logoutUser }
)(UserFeeds);
