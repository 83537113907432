/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import TermsList from './TermsList';
import { openFeedBackModal } from '../../../store/actions/appActions';
import {connect} from 'react-redux'
import './TermsOfService.css';

class TermsOfService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'introduction',
    };
    this.myRef = React.createRef();
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.listenToScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.listenToScroll);
  };
  openFeedBackHandler = event => {
    const {openFeedBackModal}= this.props
    event.preventDefault();
    openFeedBackModal(true);
  };

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const nodes = Array.from(this.myRef.current.childNodes);
    let allNodes = [];
    nodes.forEach(qNode => {
      const etop = qNode.offsetTop;
      const diff = etop - winScroll;
      if (diff < -100) {
        this.setState({ active: qNode.id });
        allNodes = [...allNodes, qNode.id];
      }
    });    
  };

  onClick = item => {
    this.setState({ active: item });
  };

  render() {
    const { active } = this.state;
    return (
      <div>
        <Navbar />
        <div className="container" id="terms-and-service">
          <div className="row">
            <div className="col-md-12 col-12">
              <h1 className="termsofservice">TERMS OF SERVICE</h1>
            </div>
          </div>
          <div className="row">
            <div ref={this.myRef} className="col-md-8 col-12">
              <div id="introduction">
                <h1 className="introduction">INTRODUCTION</h1>
                <p className="terms">
                  GuideTrue is all about healing, learning, growth, and connection- within the
                  individual and throughout the world. We aim to provide a safe, supportive, and
                  fair platform to help facilitate your greatest personal development possible. The
                  world can be difficult enough, so please read the{' '}                  
                  <a href="/community-and-content-guidelines">Conduct Guidelines</a> so that GuideTrue may remain a safe harbor of
                  support for all people. We hope you enjoy the many tools and features available to
                  you, and we welcome any feedback      <a
                  className="cursor-pointer text-primary"
                  onClick={this.openFeedBackHandler}
                  role="presentation"
                >HERE.</a>
                  <br />
                  <br />
                  Displaying public content (practitioner profile, articles, audio, video) on
                  GuideTrue makes it available to view, hear, and download all over the world.
                  Publishing your content in this way necessitates legal responsibilities. Please
                  make sure you aren't breaking any laws, our <a href="/community-and-content-guidelines">Conduct Guidelines</a>, or
                  infringing on the rights of others by publishing your content through GuideTrue.
                  Respect others, as well as their products, services, and contributions.
                  <br />
                  <br />
                  It's important that you fully read the agreement below. Using this website means
                  you accept this Terms of Service agreement and will be held liable for violation
                  of these terms & conditions. If you do not agree with these terms & conditions, do
                  not use this website.
                  <br />
                  <br />
                  IF YOU ARE SUICIDAL, SELF-HARMING, OR ARE AWARE OF HARM TOWARDS OTHERS, PLEASE
                  IMMEDIATELY CONTACT EMERGENCY SERVICES:
                  <br />
                  <br />
                  Emergency: 911
                  <br />
                  National Domestic Violence Hotline:{' '}
                  <a href="tel:1- 800-799-7233" className="font-weight-bold">
                    1- 800-799-7233
                  </a>
                  <br />
                  National Suicide Prevention Lifeline:{' '}
                  <a href="tel:1-800-273-TALK (8255)" className="font-weight-bold">
                    1-800-273-TALK (8255)
                  </a>
                  <br />
                  National Hopeline Network:{' '}
                  <a href="tel:1-800-SUICIDE (800-784-2433)" className="font-weight-bold">
                    1-800-SUICIDE (800-784-2433)
                  </a>
                  <br />
                  Lifeline Crisis Chat (Online live messaging):{' '}
                  <a href="http://www.crisischat.org/">http://www.crisischat.org/</a> <br />
                  Crisis Text Line: Text "START" TO 741-741
                  <br />
                  Self-Harm Hotline:{' '}
                  <a href="tel:1-800-DONT CUT (1-800-366-8288)" className="font-weight-bold">
                    1-800-DONT CUT (1-800-366-8288)
                  </a>
                  <br />
                  Family Violence Helpline:{' '}
                  <a href="tel:1-800-996-6228" className="font-weight-bold">
                    1-800-996-6228
                  </a>
                  <br />
                  Planned Parenthood Hotline:{' '}
                  <a href="tel:1-800-230-PLAN (7526) " className="font-weight-bold">
                    1-800-230-PLAN (7526){' '}
                  </a>
                  <br />
                  American Association of Poison Control Centers:{' '}
                  <a href="tel:1-800-222-1222" className="font-weight-bold">
                    1-800-222-1222
                  </a>
                  <br />
                  National Council on Alcoholism & Drug Dependency Hope Line:{' '}
                  <a href="tel:1-800-622-2255" className="font-weight-bold">
                    1-800-622-2255
                  </a>
                  <br />
                  National Crisis Line - Anorexia and Bulimia:{' '}
                  <a href="tel:1-800-233-4357" className="font-weight-bold">
                    1-800-233-4357
                  </a>
                  <br />
                  GLBT Hotline:{' '}
                  <a href="tel:1-888-843-4564" className="font-weight-bold">
                    1-888-843-4564
                  </a>
                  <br />
                  TREVOR Teen Crisis Hotline (LGBTQIA):{' '}
                  <a href="tel:1-866-488-7386" className="font-weight-bold">
                    1-866-488-7386
                  </a>
                  <br />
                  AIDS Crisis Line:{' '}
                  <a href="tel:1-800-221-7044" className="font-weight-bold">
                    1-800-221-7044
                  </a>
                  <br />
                  Veterans Crisis Line:{' '}
                  <a href="https://www.veteranscrisisline.net">
                    https://www.veteranscrisisline.net
                  </a>
                  <br />
                  Suicide Prevention Wiki:{' '}
                  <a href="http://suicideprevention.wikia.com">
                    http://suicideprevention.wikia.com
                  </a>
                  <br />
                  <br />
                  Canada
                  <br />
                  Emergency: 911
                  <br />
                  Hotline: 1-888-353-2273
                  <br />
                  YourLifeCounts.org:{' '}
                  <a href="http://www.yourlifecounts.org/need-help/crisis-lines">
                    http://www.yourlifecounts.org/need-help/crisis-lines
                  </a>
                  <br />
                  <br />
                  GUIDETRUE IS NOT APPROPRIATE FOR EMERGENCY SITUATIONS. THIS WEBSITE IS NOT
                  INTENDED TO PROVIDE CLINICAL DIAGNOSIS.
                  <br />
                  <br />
                  The information on this website and its subsidiaries, including but not limited
                  to, text, graphics, images and other material contained on this website are for
                  informational purposes only. The purpose of this website is to promote broad
                  consumer understanding, entertainment, and knowledge of various wellness topics.
                  It is not intended to be a substitute for professional medical advice, diagnosis
                  or treatment. Always seek the advice of your physician or other qualified health
                  care provider with any questions you may have regarding a medical condition or
                  treatment and before undertaking a new health care regimen, and never disregard
                  professional medical advice or delay in seeking it because of something you have
                  read on this website.
                  <br />
                  <br />
                  Do not use this website if you need any official documentation or approvals for
                  purposes such as, but not limited to, court-ordered counseling or emotional
                  service dog certification. It is also not intended for any information regarding
                  which drugs or medical treatment may be appropriate for you, and you should
                  disregard any such advice if delivered through the platform.
                  <br />
                  <br />
                  Do not disregard, avoid, or delay in obtaining in-person care from your doctor or
                  other qualified professional because of information or advice you received through
                  the platform.
                </p>
              </div>
              {/* Legal Agreement */}
              <div id="legal-agreement">
                <h1 className="introduction">LEGAL AGREEMENT</h1>
                <p className="terms">
                  All visitors (“user”, "practitioner", "client", “you”, “your,” "customer",
                  “member”) to the GuideTrue website at
                  <a href="https://www.guidetrue.com"> www.guidetrue.com</a> (“the website”, "platform") are
                  entering a binding legal agreement on the following terms (the “agreement”) when
                  using the website. The agreement is between the user and GuideTrue (“we”, “us”,
                  “our”) and use of this website indicates continued acceptance of this agreement.
                  GuideTrue is not obligated to utilize any of the rights granted in this Agreement.
                  This indemnity will be applicable without regard to the negligence of any party,
                  including any indemnified person. By continuing you agree that you have read and
                  understood this agreement and you are of legal age to consent to this agreement.
                  This Agreement expresses the complete understanding of the parties.
                </p>
              </div>

              <div id="eligibilty">
                <h1 className="introduction">ELIGIBILITY</h1>
                <p className="terms">
                  All products and services are available only to, and may only be used by,
                  individuals who can form legally binding contracts under applicable law. Our
                  services are not available to persons under 16 years of age without parental/
                  guardian consent and supervision.
                </p>
              </div>

              <div id="amendments">
                <h1 className="introduction">AMENDMENTS</h1>
                <p className="terms">
                  We reserve the right to amend the terms of this agreement from time to time, and
                  we will let you know about these changes either by sending you an email to the
                  email address you have registered with GuideTrue or by displaying information
                  about the changes on our home page, or both. Either way, your continuing use of
                  the website will be taken to be acceptance of the new terms regardless of whether
                  you've read them or not.
                </p>
              </div>

              <div id="our-service">
                <h1 className="introduction">OUR SERVICE</h1>
                <p className="terms">
                  GuideTrue provides a range of services (the “GuideTrue service”) which, amongst
                  other things, enable you to publish, discuss, purchase content & services,
                  interact with other members, and receive the benefits of GuideTrue order
                  fulfillment, including payment processing, customer services, and delivery and/or
                  hosting of content.
                  <br />
                  <br />
                  The digital content on the website (“your content”) may be information, text,
                  data, graphics, images, photographs, sound, video, music or any other material
                  posted online by users. Any content that you upload into the profile or content
                  section of your account is described as your “content”. Your content may be viewed
                  by various users of the website depending on its category (Free, Premium, or
                  Exclusive) once you elect to publish it. If you or a customer decide to place an
                  order, then GuideTrue will forward the order confirmation and deliver the content
                  in the form specified (“the product”).
                </p>
              </div>
              <div id="members">
                <h1 className="introduction">MEMBERS</h1>
                <p className="terms">
                  You can become a registered user (“member”) of the website by setting up a
                  password protected account. You will be required to select a username and password
                  when registering to become a member. You must become a member before enjoying many
                  of the features on GuideTrue, including placing any content on the website,
                  contacting Practitioners, and writing any comments or reviews. In its sole
                  discretion, GuideTrue may prohibit any user name or information that it decides is
                  inappropriate, and / or refuse any person from becoming a member.
                  <br />
                  <br />
                  We ask that you please be mindful that any information you choose to publish in
                  the public section of your profile may be viewed, distributed, or linked to. This
                  does not include private messages, correspondences, or the results of your
                  Personal Assessment.
                  <br />
                  <br />
                  Practitioners are forbidden to divulge any client information without direct,
                  express (preferably written) consent of the client. All practitioners listed here
                  furthermore legally agree to abide by HIPAA privacy standards{' '}
                  <a href="https://www.hhs.gov/hipaa/for-professionals/privacy/index.html">
                    (https://www.hhs.gov/hipaa/for-professionals/privacy/index.html)
                  </a>
                  . Any Practitioner found to be in violation of these conditions will be
                  immediately terminated from GuideTrue and subject to legal repercussions to the
                  fullest extent of the law.
                  <br />
                  <br />
                  GuideTrue care about your privacy. You can read our{' '}
                  <a href="/privacy-policy">Privacy Policy</a> if you would like to know how we
                  handle personal information.
                </p>
              </div>

              <div id="passwords">
                <h1 className="introduction">PASSWORDS</h1>
                <p className="terms">
                  You are solely responsible for any actions made on the website using your
                  password, including any products purchased or sold, all content submitted, reviews
                  written, and messages sent, even if these actions were not approved or created by
                  you. You are solely responsible for any loss or damage caused by any use of your
                  password by you, or any other person. So if you use a public computer, or a device
                  that may be accessed by others, DO NOT save your password on the device and be
                  sure to sign out.
                  <br />
                  <br />
                  You agree that you will not disclose your personal password to any other person or
                  agency, and you will not keep your password where it can be copied or used by
                  anyone other than you. If you suspect someone else knows your password, please
                  change it immediately.
                </p>
              </div>

              <div id="adding-content-on-guideTrue">
                <h1 className="introduction">ADDING CONTENT ON GUIDETRUE</h1>
                <p className="terms">
                  You maintain copyright for any content you submit or upload to the website, unless
                  ownership and permission is granted elsewhere. In order to receive the services on
                  GuideTrue, you grant GuideTrue a non-exclusive royalty-free license to use,
                  display, and archive the content in accordance with or as reasonably contemplated
                  by this agreement, including use for marketing materials for which you may be
                  given attribution.
                  <br />
                  <br />
                  When you submit or upload content on the website you represent and warrant that:
                  <br />
                  <br />
                  - you own the primary copyright for the content, or if you are not the owner, that
                  you have express written permission to use the content, and that you have all of
                  the rights required to display, reproduce, and sell the content.
                  <br />
                  <br />
                  - the content you upload will not infringe the intellectual property rights or
                  other rights of any person or entity, including copyright, trademark, patent,
                  moral rights, or rights of privacy or publicity.
                  <br />
                  <br />
                  - your use of the website will comply with all applicable laws, rules and
                  regulations.
                  <br />
                  <br />
                  - your content does not contain material that vilifies, defames, or threatens any
                  person, people, races, religion or religious group.
                  <br />
                  <br />
                  - your content is not obscene, pornographic, indecent, harassing, threatening,
                  harmful, invasive of privacy or publicity rights, abusive, inflammatory, or
                  otherwise objectionable.
                  <br />
                  <br />
                  - your content does not include malicious or misleading code, including but not
                  limited to viruses, worms, trojan horses, spam, ransomware, cancelbots, time
                  bombs, or any other computer programming routines that may interfere with, damage,
                  surreptitiously intercept, or expropriate any system, program, data, or personal
                  information.
                  <br />
                  <br />
                  - the content is not misleading and deceptive, and does not offer, promote, or
                  disseminate fraudulent goods, services, schemes, or promotions.
                  <br />
                  <br />- GuideTrue reserves the right to review, and if in its sole discretion
                  deems necessary, remove any content and / or cancel your account because that
                  content breaches your agreement with us and / or any applicable laws, or
                  otherwise. You agree to indemnify GuideTrue in respect of any direct or indirect
                  damage caused due to a breach of one or more of these warranties, or any lack of
                  service.
                </p>
              </div>

              <div id="offering-your-content-and-services-for-sale">
                <h1 className="introduction">OFFERING YOUR CONTENT AND SERVICES FOR SALE</h1>
                <p className="terms">
                  Any practitioner may offer their content for sale on the website by appointing
                  GuideTrue to facilitate the transaction on the terms set out in the Services
                  Agreement in Appendix A. By agreeing to the terms of this user agreement you
                  expressly agree to the terms of the Services Agreement in Appendix A, which will
                  apply from the date on which you offer your first content or product for sale, and
                  your continued use of the website will constitute ongoing agreement to the terms
                  therein as updated occasionally.
                </p>
              </div>

              <div id="purchasing-a-product-on-guideTrue">
                <h1 className="introduction">PURCHASING A PRODUCT OR SERVICE ON GUIDETRUE</h1>
                <p className="terms">
                  Users can purchase products on the GuideTrue website using a valid credit card or
                  the PayPal system.
                  <br />
                  <br />
                  You do not have to be a member to purchase a product or service.
                  <br />
                  <br />
                  The price you pay is fixed at the time of ordering.
                  <br />
                  <br />
                  You may not cancel an order once it has been submitted, and there are no refunds
                  for products/ services that have already been delivered or made accessible.
                  <br />
                  <br />
                  In the case where an external delivery of an item is warranted, it is the
                  customer’s responsibility to ensure the product delivery email address is correct.
                  GuideTrue takes no responsibility for any product a customer does not receive
                  because of errors in the delivery email address given to us.
                </p>
              </div>

              <div id="getting-paid-for-your-content">
                <h1 className="introduction">GETTING PAID FOR YOUR CONTENT</h1>
                <p className="terms">
                  Payment terms are explained in the Services Agreement{' '}
                  <a href="/termsofservice#services-agreement">(APPENDIX A - Services Agreement)</a>.
                </p>
              </div>

              <div id="guideTrue-may-cancel-orders">
                <h1 className="introduction">GUIDETRUE MAY CANCEL ORDERS</h1>
                <p className="terms">
                  You acknowledge that despite our reasonable precautions, products may be listed at
                  an incorrect price or with incorrect information or may be unavailable. This may
                  be due to an error or similar oversight. You acknowledge that we cannot facilitate
                  an order where such an error exists and hereby instruct us to cancel such an order
                  and take other action as required.
                  <br />
                  <br />
                  You authorize us to cancel an order if we believe that it is being made in
                  contravention of this agreement, or in contravention of the rights of any person
                  or any law. We may cancel an order even if it has been confirmed and the
                  customer’s credit card or PayPal account has been charged. We reserve this right
                  up until the time of delivery of the product/ service to that customer. If a
                  cancellation of this nature occurs after the customer has been charged for the
                  product, we will credit the customer’s credit card or PayPal account for the
                  amount in question, minus a service processing fee of our sole discretion.
                </p>
              </div>

              <div id="delivery">
                <h1 className="introduction">DELIVERY</h1>
                <p className="terms">
                  Delivery of any products or services will be facilitated pursuant to the method
                  laid out on the website. In the event necessitating other means, delivery can be
                  made pursuant to customer's instructions by email or internal messaging service.
                </p>
              </div>

              <div id="damaged-product">
                <h1 className="introduction">DAMAGED PRODUCT</h1>
                <p className="terms">
                  Delivery of any products or services will be facilitated pursuant to the method
                  laid out on the website. In the event necessitating other means, delivery can be
                  made pursuant to customer's instructions by email or internal messaging service.
                </p>
              </div>

              <div id="reporting-inappropriate-content-to-guideTrue">
                <h1 className="introduction">REPORTING INAPPROPRIATE CONTENT TO GUIDETRUE</h1>
                <p className="terms">
                  GuideTrue does not manually screen all content before it is displayed on the
                  website, so occasionally members may inadvertently or deliberately submit and
                  display content that breaches this agreement.
                  <br />
                  <br />
                  Inappropriate content includes, but is not limited to, content that infringes the
                  copyright or other intellectual property rights of any person or company, or that
                  defames or vilifies any person, people, races, religion or religious group, is
                  obscene, pornographic, indecent, harassing, threatening, harmful, invasive of
                  privacy or publicity rights, abusive, inflammatory or otherwise objectionable.
                  GuideTrue reserves the right (but not the obligation) to remove or edit such
                  content.
                  <br />
                  <br />
                  Please help us by letting us know right away about any inappropriate, or
                  potentially inappropriate, content you see on the website. You can do this by
                  clicking the "Report Inappropriate Content or Copyright Infringement" link that
                  will be displayed underneath each piece of content. If you believe your copyright
                  or other intellectual property rights are being infringed, you are able to make a
                  formal complaint by using the processes described in our policy here (link to
                  "Content Complaint" page).
                </p>
              </div>

              <div id="specific-warnings">
                <h1 className="introduction">SPECIFIC WARNINGS</h1>
                <p className="terms">
                  You must ensure that your access to this website and the GuideTrue service is not
                  illegal or prohibited by laws that apply to you.
                  <br />
                  <br />
                  While we take our own precautions to protect the web experience of all users, you
                  must take your own precautions to ensure that the process that you employ for
                  accessing this website and the GuideTrue service does not expose you to the risk
                  of viruses, malicious computer code or other forms of interference which may
                  damage own computer system. We do not accept responsibility for any interference
                  or damage to any computer system that arises in connection with your use of this
                  website or any linked website.
                  <br />
                  <br />
                  We do not accept liability for any losses arising directly or indirectly from a
                  failure to provide the GuideTrue service, corruption to or loss of data, errors or
                  interruptions, any suspension or discontinuance of the GuideTrue service, or any
                  transmissions by others in contravention of the registered members’ obligations as
                  set out in this agreement.
                  <br />
                  <br />
                  You acknowledge that we may not be able to confirm the identity of other
                  registered members or prevent them acting under false pretenses or in a manner
                  that infringes the rights of any person.
                </p>
              </div>

              <div id="intellectual-property-rights-and-license">
                <h1 className="introduction">INTELLECTUAL PROPERTY RIGHTS AND LICENSE </h1>
                <p className="terms">
                  By submitting information to GuideTrue, you grant GuideTrue a non-exclusive,
                  worldwide, royalty-free, able and transferable license to use, reproduce,
                  distribute, prepare derivative works of and display the content of such listings
                  in connection with GuideTrue's (and its successors' and affiliates') services and
                  business in facilitating promotions, or the sale of your product, including
                  without limitation for promoting and redistributing part or all of the GuideTrue
                  site (and derivative works thereof) in any media formats and through any media
                  channels. You also hereby grant each user of the GuideTrue site a non-exclusive
                  license to access your content through the site, and to use, reproduce,
                  distribute, and display such content as permitted through the functionality of the
                  site and under this User Agreement. The above licenses will terminate within a
                  commercially reasonable time after you remove or delete your listings from the
                  GuideTrue site. The above licenses granted by you in user comments and messages
                  you submit, are perpetual and irrevocable.
                  <br />
                  <br />
                  All intellectual property rights in this website and the GuideTrue service
                  (including the software and systems underlying the GuideTrue service, and text,
                  graphics, logos, icons, sound recordings and software) are owned by or licensed to
                  us. Other than for the purposes of, and subject to the conditions prescribed under
                  relevant Copyright and Trade Mark legislation throughout the world, and except as
                  expressly authorized by this agreement, you may not in any form or by any means:
                  <br />
                  <br />
                  - use, adapt, reproduce, store, distribute, print, display, perform, profit from,
                  publish or create derivative works from any part of this website; or
                  <br />
                  <br />
                  - commercialize any information, products or services obtained from any part of
                  this website, without our express written and authorized permission.
                  <br />
                  <br />
                  If you use any of our trade marks in reference to our activities, products or
                  services, you must include a statement attributing that trade mark to us. You must
                  not use any of our designs, branding elements, or trade marks in or as the whole
                  or part of your own trade marks; in connection with activities, products or
                  services which are not ours; in a manner which may be confusing, misleading or
                  deceptive; or in a manner that disparages us, our members, our information,
                  products or services.
                </p>
              </div>

              <div id="linked-websites">
                <h1 className="introduction">LINKED WEBSITES</h1>
                <p className="terms">
                  This website may contain links to other websites (“linked websites”). Those links
                  are provided for convenience only and may not remain current or be maintained.
                  <br />
                  <br />
                  We are not responsible for the content or privacy practices associated with linked
                  websites.
                  <br />
                  <br />
                  Our links with linked websites should not be construed as an endorsement, approval
                  or recommendation by us of the owners or operators of those linked websites, or of
                  any information, graphics, materials, products or services referred to or
                  contained on those linked websites, unless and to the extent stipulated to the
                  contrary.
                </p>
              </div>

              <div id="disclaimer">
                <h1 className="introduction">DISCLAIMER</h1>
                <p className="terms">
                  GUIDETRUE IS NOT APPROPRIATE FOR EMERGENCY SITUATIONS. THIS WEBSITE IS NOT
                  INTENDED TO PROVIDE CLINICAL DIAGNOSIS.
                  <br />
                  <br />
                  The information on this website and its subsidiaries, including but not limited
                  to, text, graphics, images and other material contained on this website are for
                  informational purposes only. The purpose of this website is to promote broad
                  consumer information, entertainment, and inspiration of various wellness topics.
                  It is not intended to be a substitute for professional medical advice, diagnosis,
                  or treatment. Always seek the advice of your physician or other qualified health
                  care provider with any questions you may have regarding a medical condition or
                  treatment and before undertaking a new health care regimen, and never disregard
                  professional medical advice or delay in seeking it because of something you have
                  read on this website.
                  <br />
                  <br />
                  Do not use this website if you need any official documentation or approvals for
                  purposes such as, but not limited to, court-ordered counseling or emotional
                  service dog certification. It is also not intended for any information regarding
                  which drugs or medical treatment may be appropriate for you, and you should
                  disregard any such advice if delivered through the platform. If you think you may
                  have a medical emergency, call your doctor, go to the emergency department, or
                  call 911 immediately. GuideTrue does not personally recommend or solely endorse
                  any specific tests, specialists, products, procedures, opinions, or other
                  information that may be mentioned on GuideTrue. Reliance on any information
                  provided by GuideTrue, or its employees, users, or practitioners presenting
                  content for publication to GuideTrue is solely at your own risk.
                  <br />
                  <br />
                  Medical information changes constantly. Therefore the information on this website
                  or on the linked websites should not be considered current, complete or
                  exhaustive, nor should you rely on such information to recommend a course of
                  treatment for you or any other individual. Reliance on any information provided on
                  this website or any linked websites is solely at your own risk. The website may
                  contain health- or medical- related materials or discussions regarding sexually
                  explicit disease states. If you find these materials offensive, you may not want
                  to use this website. GuideTrue and its content are provided on an "as is" basis.
                  <br />
                  <br />
                  Links to educational content not created by GuideTrue are taken at your own risk.
                  GuideTrue is not responsible for the claims of external websites and education
                  companies.
                  <br />
                  <br />
                  Do not disregard, avoid, or delay in obtaining in-person care from your doctor or
                  other qualified professional because of information or advice you received through
                  this platform.
                </p>
              </div>

              <div id="indemnity">
                <h1 className="introduction">INDEMNITY</h1>
                <p className="terms">
                  You agree to indemnify, defend and hold us, our officers, directors, employees,
                  agents and representatives harmless, as well as, all third parties hosting,
                  delivering, and/or otherwise fulfilling the content you are selling via the
                  website, their officers, directors, employees, agents and representatives
                  harmless, from and against any and all claims, damages, injuries, death, illegal
                  or illicit activity, losses, liabilities, costs (including reasonable legal fees)
                  or other expenses that arise directly or indirectly out of or from:
                  <br />
                  <br />
                  -your participation with GuideTrue
                  <br />
                  <br />
                  -your breach of any clause of this agreement;
                  <br />
                  <br />
                  -any allegation that any materials that you submit to us or transmit to the
                  website infringe or otherwise violate the copyright, trademark, trade secret or
                  other intellectual property or other rights of any third party; and/or
                  <br />
                  <br />
                  -your activities in connection with the website.
                  <br />
                  <br />
                  You irrevocably grant to GuideTrue and GuideTrue's assigns, licensees, and
                  successors the right to use your image and name in all forms and media including
                  composite or modified representations for all purposes, including advertising,
                  trade, or any commercial purpose throughout the world and in perpetuity. You waive
                  the right to inspect or approve versions of your image used for publication or the
                  written copy that may be used in connection with the images. You release GuideTrue
                  and GuideTrue’s assigns, licensees, and successors from any claims that may arise
                  regarding the use of your image, including any claims of defamation, invasion of
                  privacy, or infringement of moral rights, rights of publicity, or copyright.
                  GuideTrue is permitted, although not obligated, to include your name as a credit
                  in connection with the image.
                  <br />
                  <br />
                  GuideTrue is not obligated to utilize any of the rights granted in this Agreement.
                  This indemnity will be applicable without regard to the negligence of any party,
                  including any indemnified person. I have read and understood this agreement and I
                  am of legal age to consent to this agreement. This Agreement expresses the
                  complete understanding of the parties.
                </p>
              </div>

              <div id="privacy-policy">
                <h1 className="introduction">PRIVACY POLICY</h1>
                <p className="terms">
                  Your privacy is very important to us. Practitioners are forbidden to divulge any
                  client information without direct, express (preferably written) consent of the
                  client. All practitioners listed here furthermore legally agree to abide by HIPAA
                  privacy standards{' '}
                  <a href="https://www.hhs.gov/hipaa/for-professionals/privacy/index.html">
                    (https://www.hhs.gov/hipaa/for-professionals/privacy/index.html)
                  </a>
                  .
                  <br />
                  <br />
                  Users of our website should refer to our Privacy Policy – which is incorporated
                  into this agreement by reference, for information about how we collect and use
                  personal information.
                </p>
              </div>

              <div id="security-of-information">
                <h1 className="introduction">SECURITY OF INFORMATION</h1>
                <p className="terms">
                  No data transmission over the Internet can be forever guaranteed as completely
                  secure in every way. We strive to protect such information with an SSL security
                  certificate and other security measures, however we do not warrant and cannot
                  ensure the absolute security of any information that you transmit to us.
                  Accordingly, any information that you transmit to us is transmitted at your own
                  risk.
                </p>
              </div>

              <div id="termination-of-access">
                <h1 className="introduction">TERMINATION OF ACCESS</h1>
                <p className="terms">
                  Access to this website may be terminated at any time by us without notice. Our
                  disclaimer will nevertheless survive any such termination.
                </p>
              </div>

              <div id="dispute-resolution">
                <h1 className="introduction">DISPUTE RESOLUTION</h1>
                <p className="terms">
                  This User Agreement and all disputes relating to this User Agreement, or relating
                  to your use of any part of the GuideTrue service, will be exclusively resolved
                  under confidential binding arbitration held in Los Angeles, California. All
                  disputes will be resolved in accordance with the Rules of JAMS, applying
                  California law, without regard to conflicts of law principles.
                  <br />
                  <br />
                  You and GuideTrue agree to submit to the personal and exclusive jurisdiction of
                  the Federal and California State courts in Los Angeles County for purposes of
                  enforcing any arbitration award. Notwithstanding the foregoing, GuideTrue may seek
                  injunctive or other equitable relief, from a court of competent jurisdiction. You
                  and GuideTrue agree that any dispute resolution proceedings will be conducted only
                  on an individual basis and not in a class, consolidated or representative action.
                  You and GuideTrue agree that any cause of action arising out of or related to the
                  GuideTrue site (including but not limited to any services provided or made
                  available therein) or this Agreement must commence within six months after the
                  cause of action arose; otherwise, such cause of action is permanently barred.
                  <br />
                  <br />
                  If you have a dispute with one or more users or sellers, you release GuideTrue
                  (and GuideTrue's officers, directors, agents, subsidiaries, joint ventures and
                  employees) from claims demands and damages (actual and consequential) of every
                  kind of nature, known and unknown, arising out of or in any way connected with
                  such disputes. If you are a California resident, you waive California Civil Code
                  Section 1542 which states, "A general release does not extend to claims which the
                  creditor does not know or suspect to exist in his favor at the time of executing
                  the release, which if known by him must have materially affected his settlement
                  with the debtor".
                </p>
              </div>

              <div id="services-agreement">
                <h1 className="introduction">APPENDIX A - Services Agreement</h1>
                <p className="terms">
                  You wish to use GuideTrue services to facilitate marketing, publicity, sales and
                  purchasing of content through <a href="https://www.guidetrue.com">www.guidetrue.com</a>{' '}
                  ("the website"). GuideTrue will provide these services on the terms set out in
                  this Services Agreement. Additionally, GuideTrue will provide for delivery of such
                  products to the customer.
                </p>
                <ol>
                  <li>
                    Services
                    <div className="terms">
                      1.1 GuideTrue, acting as independent contractor under your instructions will
                      market to and obtain orders from customers for the purchase of your products
                      over the website and on instruction from you, GuideTrue will arrange for third
                      parties to fulfill those orders by facilitating payment for and producing/
                      delivering of your products and offerings (“Services”). GuideTrue will then
                      arrange for the availability/ delivery of your products and offerings as per
                      the customer’s instructions.
                      <br />
                      <br />
                      1.2 GuideTrue will provide the Services pursuant to this agreement until
                      termination in accordance with its terms.
                      <br />
                      <br />
                      1.3 You agree that GuideTrue is free to act in any capacity for any other
                      person interested in promoting, marketing and obtaining orders from members of
                      the public for the purchase of their products and services over the website,
                      including any products that are the same as, or similar to, your products
                      and/or services.
                    </div>
                  </li>
                  <br />

                  <li>
                    License and standing instructions
                    <div className="terms">
                      2.1 You grant GuideTrue a non-exclusive royalty free license to use your
                      intellectual property relating to your products for the purpose of enabling us
                      to carry out the Services.
                      <br />
                      <br />
                      2.2 You hereby instruct GuideTrue to facilitate the sale of your product which
                      includes display, advertisement, payment, processing, arrangement, and
                      transferral your product(s) in respect of the orders placed by the customers
                      via the website and GuideTrue will facilitate such payment and delivery in
                      accordance with reasonable business practices unless otherwise instructed and
                      agreed upon prior to the placement of that order by a customer.
                    </div>
                  </li>
                  <br />

                  <li>
                    Sale of your products
                    <div className="terms">
                      3.1 The price charged to customers who purchase your product is made up of the
                      processing fee charged by the third party affiliates, GuideTrue's fee for
                      hosting the marketplace and facilitating the transaction (the manufacturing
                      fee and GuideTrue's fee are referred to collectively, and inclusive of tax, as
                      the “base amount”), your creator margin (“your margin”), and any relevant
                      sales tax (such as Sales Tax, GST, etc) that GuideTrue and / or you (as the
                      case may be) are liable to account for to the appropriate tax authorities.
                      When making each individual work available for sale you are able to set any
                      price you wish, greater than or equal to zero.
                      <br />
                      <br />
                      3.2 GuideTrue will send you an email to the email address you registered in
                      your account to notify you when an order has been placed for your products.
                      <br />
                      <br />
                      3.3 You agree that GuideTrue makes no representation that it will be able to
                      procure an order for your products, whether at the retail price or at all, nor
                      that you will obtain any benefit by entering into this Services Agreement.
                      <br />
                      <br />
                      3.4 All items purchased from the website are delivered pursuant to
                      arrangements with third party suppliers under your instructions. This means
                      that title and risk for loss for such items typically pass from you to the
                      customer/purchaser without necessarily passing through us prior to the
                      products and/or services being delivered to the customer under the customer’s
                      instructions.
                    </div>
                  </li>
                  <br />

                  <li>
                    Payment terms
                    <div className="terms">
                      4.1 You authorize GuideTrue to collect, hold and distribute the price (“sale
                      proceeds”) from customers on the terms set out in this clause 4.
                      <br />
                      <br />
                      4.2 You authorize GuideTrue to deduct the base amount (which includes
                      GuideTrue's margin for facilitation services, including tax where applicable)
                      from the sales proceeds for your products before distributing your margin
                      (which will include tax where applicable).
                      <br />
                      <br />
                      4.3 We will pay your margin in accordance with the payment method you select
                      on the website. This will usually take place between the 15th and 20th days of
                      the month.
                      <br />
                      <br />
                      4.4 You may at any time request that we pay you the full amount in your
                      account. We will endeavor to pay you this amount within 7 days of your
                      request. A US$10 expediting administrative processing fee will apply to this
                      payment.
                      <br />
                      <br />
                      4.5It is your responsibility to ensure GuideTrue has current details of your
                      postal address and bank account. GuideTrue will not be liable for any loss
                      suffered by you if you provide us with incorrect details in relation to the
                      payment method. If we are unable to pay you because you have given us
                      incorrect details or your details are out of date, we will hold your margin
                      for up to 12 months from the payment date.
                      <br />
                      <br />
                      If you have not notified GuideTrue of any amendment to the payment method
                      details in that time your margin proceeds will be forfeited to GuideTrue or
                      donated to a charity of our choice. Any administrative processing fees and
                      associated Paypal/bank fees GuideTrue may have to pay for for returned
                      ACH/deposits on account of practitioner error will be deducted from the
                      Practitioner's margin. In a situation where the money is paid to an account
                      number given which is later found to be an inaccurate account number entered
                      by Practitioner error, and unable to be recalled, all money in the
                      Practitioner margin are irrevocably lost and considered forfeit.
                    </div>
                  </li>
                  <br />

                  <li>
                    Taxation responsibility
                    <div className="terms">
                      5.1 Each party is responsible for their own taxes associated with each
                      transaction and will account for any taxes imposed by governments or governing
                      authorities, and related accounting or audit requirements arising out of, as a
                      result of, incidental to, or in connection with obligations under this
                      Services Agreement. We recommend that you consult with your tax advisor as to
                      the application of taxes for you, as the seller of the merchandise. This may
                      include Sales Tax, VAT, GST and other transactional taxes.
                      <br />
                      <br />
                      5.2 GuideTrue will not collect or pay taxes on your behalf as GuideTrue is
                      merely acting as an agent in facilitating the sale of your product to the
                      customers. As stated in clause 4.2 of this Agreement, the amounts distributed
                      to you will be deemed to be inclusive of any taxes, if applicable. You will at
                      all times be solely responsible for reporting and remitting any tax
                      liabilities arising out of the sale of your products together with any
                      potential interest or penalties that any tax authority may levy as a result of
                      non-compliance.
                      <br />
                      <br />
                      5.3 For the avoidance of doubt, all tax-related reporting responsibilities by
                      you to relevant tax authorities are entirely your responsibility.
                    </div>
                  </li>
                  <br />

                  <li>
                    Indemnity
                    <div className="terms">
                      6.1 You hereby indemnify and will keep GuideTrue indemnified from and against
                      all claims, debts, accounts, expenses, costs, liens, actions and proceedings
                      of any nature whatsoever, whether known or unknown by any person, arising
                      from, incidental to, or by virtue of, the appointment, or any breach or non
                      performance of your obligations under this Services Agreement or arising out
                      of your willful act, neglect or default in the performance of such
                      obligations.
                      <br />
                      <br />
                      6.2 You agree to indemnify, defend and hold us, our officers, directors,
                      employees, agents and representatives harmless, as well as, all third parties
                      hosting, delivering, and/or otherwise fulfilling the content you are selling
                      via the website, their officers, directors, employees, agents and
                      representatives harmless, from and against any and all claims, damages,
                      injuries, death, illegal or illicit activity, losses, liabilities, costs
                      (including reasonable legal fees) or other expenses that arise directly or
                      indirectly out of or from:
                      <br />
                      <br />
                      -your participation with GuideTrue
                      <br />
                      <br />
                      -your breach of any clause of this agreement;
                      <br />
                      <br />
                      -any allegation that any materials that you submit to us or transmit to the
                      website infringe or otherwise violate the copyright, trademark, trade secret
                      or other intellectual property or other rights of any third party; and/or
                      -your activities in connection with the website.
                      <br />
                      <br />
                      6.3 This clause 6 will survive the termination of this Services Agreement.
                    </div>
                  </li>
                  <br />

                  <li>
                    Limitation of liability
                    <div className="terms">
                      7.1 In no case will GuideTrue be liable for any consequential loss or damage
                      suffered by you arising from this Services Agreement. To the extent permitted
                      by law, all warranties and conditions implied by law are hereby expressly
                      excluded.
                    </div>
                  </li>
                  <br />

                  <li>
                    Terminating this agreement
                    <div className="terms">
                      8.1 You can give notice of termination of this Services Agreement with future
                      effect by closing your account in the method described in the User Agreement.
                      <br />
                      <br />
                      8.2 GuideTrue may give notice of termination of this Services Agreement to you
                      in writing at any time.
                      <br />
                      <br />
                      8.3 After notice of termination in the manner described in either clause 8.1
                      or 8.2, you authorize us to complete any transactions in progress in relation
                      to your products, which we will do on the terms of this Services Agreement.
                      Termination of this Services Agreement will take effect once these
                      transactions have been completed.
                      <br />
                      <br />
                      8.4 Upon termination of this Services Agreement by either party, GuideTrue
                      will pay you any accrued member margin proceeds from your account, less a
                      US$10 administration fee.
                    </div>
                  </li>
                  <br />
                </ol>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="terms-list">
                {/* <h1 className='introduction'>Introduction</h1> */}
                <TermsList onClick={this.onClick} active={active} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = {
  openFeedBackModal,
};
export default connect(
  null,
  mapDispatchToProps
)(TermsOfService)