/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import { getRecentlyAddedContents, setRecentlyAddedContents } from '../../../store/actions/contentAction';
import ContentSkelton from '../../shared/Cards/ContentCard/ContentSkelton';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';

class RecentlyAdded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasMore: true,
      page: 1,
    };
  }

  componentDidMount() {
    const { clearRecent } = this.props;
    clearRecent();
    this.setState({ loading: true });
    this.fetchData();
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  fetchData = () => {
    const { getRecentlyAddedContents: getRecent } = this.props;
    const { page } = this.state;
    this.setState({ loading: true });
    getRecent(page, more => {
      this.setState({
        loading: false,
        hasMore: more,
        page: page + 1,
      });
    });
  };

  scrollHandler = () => {
    const { loading, hasMore } = this.state;
    if (loading || !hasMore) return;
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 200
    ) {
      this.fetchData();
    }
  };

  goToDetails = id => {
    const { history } = this.props;
    history.push(`/content/${id}`);
  };

  render() {
    const { loading } = this.state;
    const { recent } = this.props;
    let RecentContents = null;
    if(recent){
      RecentContents = recent.map(content => (
        <div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={content._id}>
          <ContentCard content={content} onClick={() => this.goToDetails(content._id)} />
        </div>
      ));
    }
    const LoadSkeleton = (
      <div className="row">
        {[1, 2, 3, 54, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
          <div className="col-lg-3 col-md-4 col-sm-6 mb-3" key={item}>
            <ContentSkelton />
          </div>
        ))}
      </div>
    );
    return (
      <>
        <Navbar />
        <div className="container my-4" style={{ minHeight: '80vh' }}>
          {loading && !recent.length ? (
            <p className="line-placeholder header-placeholder-h1 line-placeholder-long shine mb-3" />
          ) : (
            <h2 className="slider-title mb-4">Recently Added Content</h2>
          )}
          <div className="row">{RecentContents}</div>
          {loading && LoadSkeleton}
        </div>
        <Footer />
      </>
    );
  }
}

RecentlyAdded.propTypes = {
  recent: PropTypes.arrayOf(PropTypes.object).isRequired,
  getRecentlyAddedContents: PropTypes.func.isRequired,
  clearRecent: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  recent: state.content.recent,
});

const mapDispatchToProps = {
  getRecentlyAddedContents,
  clearRecent: () => dispatch => dispatch(setRecentlyAddedContents([])),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentlyAdded);
