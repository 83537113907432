import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './EmailSent.css';

const EmailSent = ({ email }) => {
  return (
    <div id="email-sent-view">
      <div className="container py-5">
        <div className="d-flex flex-column align-items-center justify-content-center email-sent-card py-3">
          <img className="mb-4" src="/images/email-sent.png" alt="email-sent" />
          <h3 className="mb-1">Just one more step.</h3>
          <p className="mb-5">
            We have sent an email to<span className="mail">{email}</span>
            to confirm the validity of your email. After receiving the email follow the link/action
            to complete your registration.
          </p>
          <p className="mb-5"><i>If you don't see our message, please check your spam and promotion folders also!</i></p>
          <Link to="/" className="btn gt-btn">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

EmailSent.propTypes = {
  email: PropTypes.string.isRequired,
};

export default EmailSent;
