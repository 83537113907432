import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { acceptBusinessInvitation } from '../../../store/actions/linkBusinessAction';

class JoinBusiness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasError: false,
      errMsg: '',
    };
  }

  componentDidMount() {
    const { location, acceptBusinessInvitation } = this.props;
    const usertoken = localStorage.getItem('token');
    if (!usertoken) return;
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const business = params.get('business');
    this.setState({ loading: true });
    acceptBusinessInvitation(business, token, err => {
      this.setState({ loading: false });
      if (err) {
        this.setState({ hasError: true, errMsg: err.errMsg || 'Somthing went wrong' });
      }
    });
  }

  render() {
    const { location, user } = this.props;
    const { loading, hasError, errMsg } = this.state;
    const params = new URLSearchParams(location.search);
    const emailExist = params.get('email_exist');

    if (emailExist === 'false') {
      const signUpUrl = `/signup${location.search}&u_type=practitioner`;
      return <Redirect to={signUpUrl} />;
    }
    const usertoken = localStorage.getItem('token');
    if (!usertoken) {
      const signInUrl = `/signin${location.search}`;
      return <Redirect to={signInUrl} />;
    }
    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
        {loading && (
          <div className="text-center">
            <p className="font-medium mb-2">Joining Business</p>
            <p className="m-0">Please wait...</p>
            <div className="spinner-grow text-primary mt-3" />
          </div>
        )}
        {!loading && hasError && <h4 className="font-medium text-capitalize">{errMsg}</h4>}
        {!loading && !hasError && (
          <div className="text-center">
            <h4 className="font-medium">Business is linked to your profile successfully.</h4>
            {user && user.u_prac_profile_completion_status && (
              <Link to={`/${user.u_prac_username}`} className="btn gt-btn mt-4">
                Go to Profile
              </Link>
            )}
            {user && !user.u_prac_profile_completion_status && (
              <Link
                to="/create-practitioner-profile?join-business=true"
                className="btn gt-btn mt-4"
              >
                Create Practitioner Profile
              </Link>
            )}
          </div>
        )}
      </div>
    );
  }
}

JoinBusiness.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    u_prac_username: PropTypes.string,
  }),
};

JoinBusiness.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.auth.currentUser,
});

export default connect(
  mapStateToProps,
  { acceptBusinessInvitation }
)(JoinBusiness);
