/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ModalComponent from '../Modal/Modal';
import Feedback from '../../Feedback/Feedback';
import './Footer.css';
import { openFeedBackModal } from '../../../store/actions/appActions';

const Footer = ({ openFeedBackModal, showModal }) => {
  // const [showfeedback, showModal] = useState(false);
  return (
    <div className="landing-footer">
      <ModalComponent
        className="feedback-modal"
        show={showModal}
        title="Feedback"
        onHide={() => openFeedBackModal(false)}
      >
        <Feedback />
      </ModalComponent>
      <ul className="d-lg-flex d-block justify-content-center m-0 px-0" id="navigation-list">
        <li className="text-lg-left text-center mb-lg-0 mb-3">
          <Link to="/">Home</Link>
        </li>
        <li className="text-lg-left text-center mb-lg-0 mb-3">
          <Link to="/faq" target="_blank">
            FAQ
          </Link>
        </li>
        <li className="text-lg-left text-center mb-lg-0 mb-3">
          <Link to="/termsofservice" target="_blank">
            Terms of Service
          </Link>
        </li>
        <li className="text-lg-left text-center mb-lg-0 mb-3">
          <Link to="/privacy-policy" target="_blank">
            Privacy Policy
          </Link>
        </li>
        <li className="text-lg-left text-center mb-lg-0 mb-3">
          <Link to="/community-and-content-guidelines" target="_blank">
            Community Content Guidelines
          </Link>
        </li>
        <li className="text-lg-left text-center mb-lg-0 mb-3">
          {/* <Link to="/" target="_blank"> */}
          <a href="mailto:guidetrue22@gmail.com">Contact</a>
          {/* </Link> */}
        </li>
        <li className="text-lg-left text-center mb-lg-0 mb-3">
          <a
            role="button"
            href="#"
            onClick={e => {
              e.preventDefault();
              openFeedBackModal(true);
            }}
          >
            Feedback
          </a>
        </li>
      </ul>
      <p className="footer-content text-center">
        <span>Copyright © 2019 Guidetrue</span>
        {/* <span>053 Runolfsson Mount Suite 816</span> */}
      </p>
      <div className="landing-footer-info text-center d-md-flex justify-content-md-center">
        <div className="mb-md-0 mb-2 mr-md-5 mr-0">
          <a
            href="https://www.facebook.com/GuideTrue.Online"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-facebook text-white" />
          </a>
          <a
            href="https://www.instagram.com/guidetrue_"
            className="mx-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="icon-instagram text-white" />
          </a>
          <a href="https://twitter.com/guide_true" target="_blank" rel="noopener noreferrer">
            <i className="icon-twitter text-white" />
          </a>
        </div>
        {/* <a href="tel:0701 749 5494" className="mr-5">
          0701 749 5494
        </a> */}
        <a href="mailto:info@guidetrue.com" className="">
          info@guidetrue.com
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  showModal: state.app.showFeedBackModal,
});

const mapDispatchToProps = {
  openFeedBackModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
