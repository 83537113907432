/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import './PurchaseModal.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Button from '../Button/Button';
import CreditCardDetails from '../CreditCardDetails/CreditCardDetails';
import { getMembershipDetails, getAllCreditCards } from '../../../store/actions/settingsActions';
import { makeContentPurchase, checkCouponCode, clearCouponCode } from '../../../store/actions/paymentActions';
import PriceCard from '../PriceCard/PriceCard';
import AddCreditCard from '../AddCard/AddCreditCard';
import Loader from '../Loader/Loader';

const planDescription = [
  {
    head: 'Unlimited Access!',
    subhead: 'Enjoy ALL Premium content and exclusive events! Cancel anytime.',
  },
  {
    head: 'Discount Prices',
    subhead: 'Save money on Exclusive content, special offers, and special events!',
  },
  {
    head: 'Priority Experience!',
    subhead: 'Advanced access to retreats, workshops, webinars, and new features to come!',
  },
];

class PurchaseModal extends Component {
  constructor() {
    super();
    this.state = {
      showContent: true,
      premiumType: 'purchase',
      couponCode:'',
      applyCouponClicked: false
    };
    this.elHeight = 0;
  }

  componentDidMount() {
    const { getAllCreditCards, getMembershipDetails } = this.props;
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      getAllCreditCards();
      getMembershipDetails();
    }
  }

  componentDidUpdate(prevProps) {
    const { openAddCard } = this.props;
    if (openAddCard !== prevProps.openAddCard) {
      this.setState({});
      if (openAddCard) {
        this.elHeight = document.getElementById('payment-type').scrollHeight + 300;
        return;
      }
      this.elHeight = document.getElementById('payment-type').scrollHeight - 300;
    }
  }

  onClick = () => {
    const { makeContentPurchase, content, history, promotions } = this.props;
    this.elHeight = document.getElementById('payment-type').scrollHeight;
    if (!this.user) {
      history.push(`/signin?target=content&id=${content._id}`);
      return false;
    }
    this.setState({ showContent: false });
    const { showContent } = this.state;
    let finalPrice = content.content_price;
    if(promotions){
      if(promotions.discount_type === "percentage"){
        finalPrice = content.content_price - (content.content_price * promotions.promotion_value / 100);
      } else{
        finalPrice = content.content_price - promotions.promotion_value;
      }
    }
    const couponId = promotions ? promotions._id : null;
    const usageRemaining = promotions ? promotions.usage_remaining : null;
    if (!showContent) {
      makeContentPurchase(content._id, finalPrice, couponId, usageRemaining);
    }
    return false;
  };

  loadSkelton = () => {
    const { cards, creditCardLoader } = this.props;
    if (creditCardLoader) {
      if (cards.length) {
        return cards.map(card => (
          <div key={card.id} className="col-12 pb-3">
            <div className="member-skelton shine w-100" />
          </div>
        ));
      }
      return (
        <div className="col-12 pb-3">
          <div className="member-skelton shine w-100" />
        </div>
      );
    }
    return null;
  };

  onBack = () => {
    const { clearCouponCode } = this.props;
    this.setState({ showContent: true });
    this.elHeight = 0;
    clearCouponCode();
  };

  onSelect = key => {
    this.setState({ premiumType: key });
    setTimeout(() => {
      this.elHeight = document.getElementById('payment-type').scrollHeight;
      this.setState({ showContent: false });
    }, 100);
  };

  checkCouponCode = value => {
    const { clearCouponCode, promotions } = this.props;
    if(promotions){
      clearCouponCode();
    }
    this.setState({couponCode: value, applyCouponClicked: false});
  }

  applyCouponCode = () => {
    const { checkCouponCode, content } = this.props;
    const { couponCode } = this.state;
    checkCouponCode(couponCode, content.content_owner._id);
    this.setState({applyCouponClicked: true});
  }

  render() {
    const { showContent, premiumType, applyCouponClicked, couponCode } = this.state;
    const {
      pricePlans,
      loading,
      cards,
      creditCardLoader,
      show,
      content,
      paymentLoader,
      history,
      user,
      openAddCard,
      promotions
    } = this.props;
    const thumbnail =
      (content && content.content_thumbnail_url) || '/images/thumbnail-placeholder.jpg';
    let contentPrice = content.content_price;
    if(promotions){
      if(promotions.discount_type === "percentage"){
        contentPrice = content.content_price - (content.content_price * promotions.promotion_value / 100);
      } else{
        contentPrice = content.content_price - promotions.promotion_value;
      }
    }
    return (
      <Modal show={show} size="lg" centered>
        <Modal.Body className="p-0 overflow-hidden">
          <>
            {paymentLoader && <Loader />}
            <div className={`purchase-modal ${!showContent && 'purchase-modal-content-hide'}`}>
              <div className="purchase-head-image position-relative">
                <div className="purchase-head-image overflow-hidden position-relative">
                 <div onClick={()=>history.goBack()} className="align-items-center d-flex justify-content-center purchase-back-button rounded-circle text-dark">
                    <i className="icon-back-arrow" />
                  </div>
                  <img className="head-image-size" src={thumbnail} alt="purchase" />
                </div>
                <div className="preimum-icon-position w-100 d-flex justify-content-center">
                  <div className="preimum-icon-container">
                    <img src="/icons/premium-article.svg" alt="premium" width="100%" />
                  </div>
                </div>
              </div>
              <div className="container mt-5">
                <div className="row justify-content-center pt-4">
                  <div className="col-md-5 col-10 text-center">
                    <h5 className="font-weight-bold">Interested in checking this out?</h5>
                    {content.content_access_type !=='exclusive'?<p className="text-black-50">
                      Get full access (and support this practitioner!) by purchasing it, or subscribing to a membership.
                    </p>:<p className="text-black-50">
                      Get full access (and support this practitioner!) by purchasing it.
                    </p>}
                  </div>
                </div>
                <Tabs
                  className="payment-tab d-flex justify-content-center pt-3"
                  defaultActiveKey="purchase"
                  activeKey={premiumType}
                  onSelect={key => this.onSelect(key)}
                  id="uncontrolled-tab-example"
                >
                  <Tab eventKey="purchase" title="Purchase" />
                 {content.content_access_type !=='exclusive' && <Tab eventKey="subscribe" title="Subscribe" />}
                </Tabs>
              </div>
            </div>
            <div className="container purchase-modal-payment px-md-5 px-3 pt-3">
              <div className="row pt-4 pb-3">
                <div className="col-md-2 col-6">
                  <img className="head-image-size" src={thumbnail} alt="purchase" />
                </div>
                <div className="col-md-7 col-6 pl-0 d-flex flex-column justify-content-between">
                  <div>
                    <p className="mb-0 font-weight-bold">{content && content.content_title}</p>
                    <p className="text-black-50 label feed-description">
                      {content && content.content_description}
                    </p>
                  </div>
                  <p className="label mb-0">
                    {content &&
                      content.content_owner &&
                      `${content.content_owner.u_first_name} ${content.content_owner.u_last_name ||
                        ''}`}
                  </p>
                </div>
                <div className="col-md-3 col-12 d-flex justify-content-md-end justify-content-center align-items-end pb-1">
                  <div>
                    <h5 className="font-weight-bold pt-md-0 pt-5 text-right">
                      {content && content.content_price && contentPrice}
                      <span className="label font-weight-normal pl-1">USD</span>
                    </h5>
                    <div>
                      {promotions ? 
                        <div className="d-flex" style={{fontSize:12}}>
                          <p className="mb-0 mr-1"
                            style={{color: '#7AC74F', width:110}}>
                            {promotions.promotion_value}{promotions.discount_type === "percentage" ? '%' : '$'} off code applied
                          </p>
                          <strike style={{color: '#979797', width:45}}>{content && content.content_price} USD</strike>
                        </div>
                        :<></>}
                    </div>
                  </div>
                </div>
              </div>
              {showContent ?
              <div className="pt-3 PromoCode border-top">
                <p className="mb-1" style={{color: '#D4AF37', fontSize: 14, fontWeight:'bold'}}>
                  Have a promo code?</p>
                <div className="d-flex">
                  <input className="mr-2 pl-3 w-100" type="text" placeholder="Enter coupon code"
                    onKeyUp={(e) => this.checkCouponCode(e.target.value)}
                    style={{
                      color: '#9C9C9C !important', 
                      border: '1px solid #DEDEDE', 
                      borderRadius: 3,
                      paddingRight:120
                    }}/>
                  {applyCouponClicked ? 
                    <>
                    {promotions ? 
                      <p className="mb-0 mr-3 d-flex align-items-center"
                        style={{
                          marginLeft: -75,
                          width:80,
                          color: '#7AC74F'
                        }}>{promotions.promotion_value}{promotions.discount_type === "percentage" ? '%' : '$'} off</p>
                      : 
                        <p className="mb-0 mr-3 d-flex align-items-center"
                        style={{
                          marginLeft: -106,
                          width:120,
                          color: '#C4C4C4'
                        }}>Invalid code</p>
                  } </> : <></>}
                  <Button 
                    disabled={!couponCode}
                    style={{ color: '#7AC74F', border: '1px solid #7AC74F', background: 'white', borderRadius: 3 }}
                    onClick={() => this.applyCouponCode()}>
                    Apply Code
                  </Button>
                </div>
              </div> :<></>}
              <div
                id="payment-type"
                style={{ height: !showContent && openAddCard ? 618 : this.elHeight, overflow:'hidden auto' }}
                className={`purchase-modal-payment-type mt-3 ${!showContent &&
                  'purchase-modal-payment-type-show '}`}
              >
                {premiumType === 'purchase' && (
                  <div className="row px-md-5 px-3 pt-2">
                    {this.loadSkelton()}
                    {!creditCardLoader &&
                      cards.map((card, index) => (
                        <div key={card.id} className="col-12">
                          <CreditCardDetails settings index={index} card={card} />
                        </div>
                      ))}
                    <div className="col-12">
                      {cards.length <= 9 ? (
                        user && <AddCreditCard />
                      ) : (
                        <p className="text-secondary font-italic mt-4">
                          Maximum cards added. Remove unused/expired card to add a new card
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {premiumType === 'subscribe' && (
                  <div className="px-5 pt-4">
                    <div className="row justify-content-center px-lg-5 px-md-2 pt-5">
                      {planDescription.map(description => (
                        <div
                          key={description.id}
                          className="col-lg-4 col-md-6 col-12 pb-3 text-center"
                        >
                          <img
                            className="pb-2 plan-tick-icon"
                            src="/icons/green-tick.svg"
                            alt="tick"
                          />
                          <p className="mb-0">{description.head}</p>
                          <p className="text-black-50 plan-description">{description.subhead}</p>
                        </div>
                      ))}
                    </div>
                    <div className="row justify-content-center px-md-2 pt-2">
                      {!loading &&
                        pricePlans.map(plan => (
                          <div key={plan.id} className="col-lg-4 col pb-3">
                            <PriceCard contentId={content._id} plan={plan} />
                          </div>
                        ))}
                      {loading &&
                        [1, 2, 3].map(skelton => (
                          <div key={skelton} className="col pb-3">
                            <div className="price-card-skelton shine w-100" />
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer className="p-4 mt-3 purchase-modal-footer">
          {!showContent && (
            <button type="submit" onClick={this.onBack} className="btn btn-cancel">
              Back
            </button>
          )}
          {premiumType !== 'subscribe' && (
            <Button disabled={paymentLoader} onClick={this.onClick}>
              {!this.user && 'Login'}
              {this.user && (!showContent ? 'Purchase' : 'Checkout')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

PurchaseModal.propTypes = {
  getAllCreditCards: PropTypes.func.isRequired,
  getMembershipDetails: PropTypes.func.isRequired,
  makeContentPurchase: PropTypes.func.isRequired,
  checkCouponCode: PropTypes.func.isRequired,
  clearCouponCode: PropTypes.func.isRequired,
  openAddCard: PropTypes.bool,
  content: PropTypes.shape({
    _id: PropTypes.string,
    content_title: PropTypes.string,
    content_description: PropTypes.string,
    content_owner: PropTypes.shape({
      u_first_name: PropTypes.string,
      u_last_name: PropTypes.string,
    }),
    content_price: PropTypes.number,
  }),
  pricePlans: PropTypes.arrayOf(PropTypes.object),
  creditCardLoader: PropTypes.bool,
  loading: PropTypes.bool,
  paymentLoader: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  cards: PropTypes.arrayOf(PropTypes.object),
  promotions: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

PurchaseModal.defaultProps = {
  openAddCard: false,
  content: null,
  pricePlans: [],
  creditCardLoader: false,
  loading: false,
  paymentLoader: false,
  cards: [],
  promotions: null
};

const mapStateToProps = state => ({
  pricePlans: state.settings.pricePlans,
  loading: state.app.loading,
  cards: state.settings.cards,
  creditCardLoader: state.payment.creditCardLoader,
  openAddCard: state.settings.openAddCard,
  paymentLoader: state.payment.paymentLoader,
  user: state.auth.currentUser,
  promotions: state.payment.promotions
});

export default withRouter(
  compose(
    connect(
      mapStateToProps,
      { getMembershipDetails, getAllCreditCards, makeContentPurchase, checkCouponCode, clearCouponCode }
    )
  )(PurchaseModal)
);
