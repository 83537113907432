/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CardDetails from './CardDetails';
import { toggleAddCard } from '../../../store/actions/settingsActions';
import UpdateCardDetails from './UpdateCardDetails';
import { STRIPE_API_KEY } from '../../../config'

class AddCreditCard extends React.Component {
  toggleAddCards = () => {
    const { toggleAddCard, openAddCard } = this.props;
    toggleAddCard(!openAddCard);
  };

  render() {
    const { openAddCard, update, card } = this.props;
    return (
      <div id="add-credit-card" className="w-100">
        <div
          className={`add-card-button mt-3 ${(update || openAddCard) && 'card-details-active'}`}
          style={{ backgroundColor: '#fdfbf3' }}
        >
          <span onClick={this.toggleAddCards} className="cursor-pointer">
            {update ? 'Enter New Card Details' : 'Add New Card'}
          </span>
          <div id="add-card">
            <StripeProvider apiKey={STRIPE_API_KEY}>
              <Elements>{update ? <UpdateCardDetails card={card} /> : <CardDetails />}</Elements>
            </StripeProvider>
          </div>
        </div>
      </div>
    );
  }
}

AddCreditCard.propTypes = {
  toggleAddCard: PropTypes.func.isRequired,
  openAddCard: PropTypes.bool.isRequired,
  card: PropTypes.shape({}),
  update: PropTypes.bool,
};

AddCreditCard.defaultProps = {
  update: false,
  card: {},
};

const mapStateToProps = state => ({
  cards: state.settings.cards,
  openAddCard: state.settings.openAddCard,
});

export default compose(
  connect(
    mapStateToProps,
    { toggleAddCard }
  )
)(AddCreditCard);
