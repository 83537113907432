import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getPaymentHistory } from '../../../store/actions/settingsActions';

class PaymentHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount(){
        const { getPaymentHistory } = this.props;
        this.setState({ loading: true })
        getPaymentHistory(()=>{
            this.setState({ loading: false })
        });
    }

    render() {
        const { transactions } = this.props;
        const { loading } = this.state;
        const rows =  transactions.map(tran => (
            <tr key={tran.id} style={{ opacity: tran.status !== 'succeeded' ? 0.65 : 1 }}>
                <td>
                    {tran.status === 'succeeded' ? 
                    <i className="icon-check_circle text-success" style={{ fontSize: '1.5rem' }} /> : 
                    <i className="icon-times-circle text-danger" style={{ fontSize: '1.5rem' }} />}
                </td>
                <td>{moment.unix(tran.created).format('DD MMM YYYY')}</td>
                <td>
                    <span className="text-capitalize">{tran.payment_method_details.card.brand} </span>
                    ends in {tran.payment_method_details.card.last4}
                </td>
                <td>${(tran.amount / 100).toFixed(2)}</td>
                <td>
                    <a className="text-primary" href={tran.receipt_url} rel="noreferrer noopener" target="_blank">receipt</a>
                </td>
            </tr>
        ));        
        
        return (
            <div className="py-4">
                {!loading && rows.length !== 0 &&
                    <table className="table table-borderless">
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Payment method</th>
                            <th>Amount</th>
                            <th>Receipt</th>
                        </tr>
                        {rows}
                </table>}
                {
                    (rows.length === 0 && !loading ) && (
                        <div className="text-center py-5">
                            <p className="font-medium">No transactions yet.</p>
                        </div>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    transactions: state.settings.payment_history
})

export default connect(mapStateToProps, { getPaymentHistory })(PaymentHistory);
