/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, reset, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '../../../common/Button/Button';
import Input from '../../../common/Input/Input';
import SelectInput from '../../../common/SelectInput/SelectInput';
import GTDatePicker from '../../../common/DatePicker/DatePicker';
import ModalComponent from '../../../shared/Modal/Modal';
import '../PractitionerSettings.css';
import PromotionCard from './PromotionCard';
import {
  setPromotion,
  editPromotion,
  changePromoStatus,
  addPromotions,
  getPromotions,
  deletePromotion,
} from '../../../../store/actions/settingsActions';
import { isEmpty } from '../../../../utils/validators';

const validators = values => {
  const errors = {};
  console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', values);

  if (!isEmpty(values.promotion_title)) {
    errors.promotion_title = 'This field is required';
  }
  if (!isEmpty(values.promotion_code)) {
    errors.promotion_code = 'This field is required';
  }
  if (!isEmpty(values.promotion_value)) {
    errors.promotion_value = 'This field is required';
  }
  if (!values.discount_type) {
    errors.discount_type = 'This field is required';
  }
  if (!values.usage_limit) {
    errors.usage_limit = 'This field is required';
  }
  if (!values.promotion_end_date) {
    errors.promotion_end_date = 'This field is required';
  } 
  // else if (
  //   new Date(values.promotion_start_date).getTime() > new Date(values.promotion_end_date).getTime()
  // ) {
  //   errors.promotion_end_date = 'Invalid end date.';
  // }
  console.log('@@@@@@@@@@@',errors);
  
  return errors;
};

class Promotions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addPromotion: false,
      edit: false,
    };
  }

  componentDidMount = () => {
    const { getPromotions } = this.props;
    getPromotions();
  };

  addPromotion = values => {
    const { addPromotions } = this.props;
    addPromotions(values);
    this.props.dispatch(reset('promotionsettingsForm'));
    this.props.destroy();
    this.props.initialize();
    this.setState({ addPromotion: false });
  };

  deletePromotion = promo => {
    const { deletePromotion } = this.props;
    deletePromotion(promo._id);
  };

  editPromotion = promo => {
    const { setPromotion } = this.props;
    this.setState({ addPromotion: true, edit: true });
    setPromotion(promo);
  };

  changePromotionStatus = promo => {
    const { changePromoStatus } = this.props;
    changePromoStatus(promo);
  };

  updatePromotion = values => {
    const { editPromotion, selectedPromotion } = this.props;
    const promoId = selectedPromotion._id;
    this.setState({ addPromotion: false, edit: false });
    editPromotion(promoId, values);
  };

  render() {
    const { addPromotion, edit } = this.state;
    const {
      handleSubmit,
      promotions,
      setPromotion,
      promotion_start_date,
      reset,
      invalid,
      submitting,
      loading
    } = this.props;

    if (loading) {
      return (
        <div className="d-flex p-5 text-center bg-light rounded" style={{ height: '50vh' }}>
          <span className="spinner-grow text-primary m-auto" />
        </div>
      );
    }
    return (
      <div id="promotions-settings">
        {
          promotions.length === 0 &&
          <div className="empty-promotion-container">
            <div className="row justify-content-center">
              <img src="/icons/empty_promotion.svg" alt="empty_promotion" />
            </div>
            <p className="text-center mt-4">
              It seems that you don't have any promotions going on. Create one now!
            </p>
            <div className="text-center">
              <Button
                onClick={() => {
                  this.setState({ addPromotion: true });
                  // this.props.dispatch(reset('promotionsettingsForm'));
                  reset();
                }}
              >
                <span>+ Add promotion</span>
              </Button>
            </div>
          </div>
        }
        {
          (promotions.length > 0) &&
          <>
            <div className="d-flex justify-content-between align-items-between">
              <div>
                <h1 className="promotions-title mb-0">Promotions</h1>
                <p>{promotions.length ? promotions.length : ''} coupons</p>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setPromotion(null);
                    this.setState({ addPromotion: true });
                    // reset();
                  }}
                >
                  <span>+ Add promotion</span>
                </Button>
              </div>
            </div>
            <div className="row mt-3">
              {promotions.map(
                item =>
                  item.promotion_active === 1 && (
                    <div className="col-md-6 col-12 mb-4" key={item._id}>
                      <PromotionCard
                        item={item}
                        deletePromotion={this.deletePromotion}
                        editPromotion={this.editPromotion}
                        changePromotionStatus={this.changePromotionStatus}
                      />
                    </div>
                  )
              )}
            </div>
          </>
        }
          <ModalComponent
            title={edit ? 'Edit Promotion' : 'Add Promotion'}
            show={addPromotion}
            onHide={() => {
              this.props.dispatch(reset('promotionsettingsForm'));
              this.setState({ addPromotion: false, edit: false });
              // reset();
            }}
          >
            <>
              <div className="text-center">
                <img
                  src="/icons/add_promotions.svg"
                  className="ml-auto mt-3 text-align-center"
                  alt="Close"
                  role="presentation"
                />
                <h3 className="mt-5 promo-modal-title">Get connected to people more</h3>
                <p className="mt-2 promo-modal-description">
                  Provide offers to connect to the community. If you wish to add offers start now.
                </p>
              </div>
              {addPromotion && (
                <form
                  onSubmit={
                    edit ? handleSubmit(this.updatePromotion) : handleSubmit(this.addPromotion)
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-12 mb-3">
                      <label className="promo-label">PROMOTION NAME</label>
                      <Field
                        name="promotion_title"
                        placeholder="Promotion Name"
                        maxLength={35}
                        component={Input}
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <label className="promo-label">PROMOTION CODE</label>
                      <Field
                        name="promotion_code"
                        placeholder="Promotion Code"
                        maxLength={15}
                        component={Input}
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <label className="promo-label">VALUE</label>
                      <div className="row">
                        <div className="col-8 pr-0">
                          <Field
                            name="promotion_value"
                            placeholder="$"
                            maxLength={15}
                            component={Input}
                          />
                        </div>
                        <div className="col-4 pl-0">
                          <Field name="discount_type" component={SelectInput}>
                            <option value="" disabled hidden>Select type</option>
                            <option value="dollar">Dollar</option>
                            <option value="percentage">Percentage</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <label className="promo-label">CODE EXPIRATION DATE</label>
                      <Field
                        name="promotion_end_date"
                        minDate={promotion_start_date || new Date()}
                        component={props => (
                          <GTDatePicker
                            {...props}
                            selected={props.input.value}
                            dateFormat="dd MMM yyyy"
                            className="form-control gt-form-control"
                            placeholderText="Expiry Date"
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <label className="promo-label">USAGE LIMIT</label>
                      <Field name="usage_limit" component={SelectInput}>
                        <option value="" disabled hidden>Select usage limit</option>
                        <option value="1">1 time</option>
                        <option value="2">2 times</option>
                        <option value="3">3 times</option>
                        <option value="5">5 times</option>
                        <option value="10">10 times</option>
                      </Field>
                    </div>
                    {/* <div className="col-md-12 col-12 mb-3">
                      <label className="promo-label">PROMOTION DESCRIPTION</label>
                      <Field
                        name="promotion_description"
                        placeholder="Promotion Description"
                        maxLength={75}
                        component={TextArea}
                        info="(Should not exceed more than 75 Characters)"
                      />
                    </div> */}
                    {/* <div className="col-md-6 col-12">
                      <label className="promo-label">START DATE</label>
                      <Field
                        name="promotion_start_date"
                        component={props => (
                          <GTDatePicker
                            {...props}
                            minDate={new Date()}
                            selected={props.input.value}
                            dateFormat="dd MMM yyyy"
                            className="form-control gt-form-control"
                            placeholderText="Start Date"
                          />
                        )}
                      />
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-end mt-3">
                    <Button type="submit" disabled={invalid || submitting}>
                      Publish
                    </Button>
                  </div>
                </form>
              )}
            </>
          </ModalComponent>
      </div>
    );
  }
}

Promotions = reduxForm({
  form: 'promotionsettingsForm',
  validate: validators,
  // destroyOnUnmount: true,
  enableReinitialize: true,
})(Promotions);

const mapDispatchToProps = {
  setPromotion,
  addPromotions,
  editPromotion,
  changePromoStatus,
  getPromotions,
  deletePromotion,
};

const mapStateToProps = state => {
  const selector = formValueSelector('promotionsettingsForm');
  const promotion_start_date = selector(state, 'promotion_start_date');
  return {
    promotions: state.settings.promotions && state.settings.promotions,
    loading: state.app.loading,
    selectedPromotion: state.settings.selectedPromotion,
    initialValues: {
      promotion_title:
        state.settings.selectedPromotion && state.settings.selectedPromotion.promotion_title,
      // (state.settings.selectedPromotion.promotion_end_date &&
      //   moment(state.settings.selectedPromotion.promotion_end_date).format('DD MMM YYYY')),
      promotion_description:
        state.settings.selectedPromotion && state.settings.selectedPromotion.promotion_description,
      promotion_code:
        state.settings.selectedPromotion && state.settings.selectedPromotion.promotion_code,
      promotion_value:
        state.settings.selectedPromotion && state.settings.selectedPromotion.promotion_value,
      discount_type:
        state.settings.selectedPromotion && state.settings.selectedPromotion.discount_type,
      usage_limit:
        state.settings.selectedPromotion && state.settings.selectedPromotion.usage_limit,
      promotion_start_date:
        state.settings.selectedPromotion &&
        (state.settings.selectedPromotion.promotion_start_date &&
          new Date(state.settings.selectedPromotion.promotion_start_date)),
      promotion_end_date:
        state.settings.selectedPromotion &&
        (state.settings.selectedPromotion.promotion_end_date &&
          new Date(state.settings.selectedPromotion.promotion_end_date)),
    },
    promotion_start_date,
  };
};

Promotions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  addPromotions: PropTypes.func.isRequired,
  getPromotions: PropTypes.func.isRequired,
  deletePromotion: PropTypes.func.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.shape({})),
  setPromotion: PropTypes.func.isRequired,
  changePromoStatus: PropTypes.func.isRequired,
  editPromotion: PropTypes.func.isRequired,
  selectedPromotion: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

Promotions.defaultProps = {
  promotions: [],
  selectedPromotion: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Promotions);
