/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-class-assign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, submit, touch, Form, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PractitionerRegistration1 from './PractitinerRegistration/PractitionerRegistration1';
import PractitionerRegistration2 from './PractitinerRegistration/PractitionerRegistration2';
import PractitionerRegistration3 from './PractitinerRegistration/PractitionerRegistration3';
import PractitionerRegistration4 from './PractitinerRegistration/PractitionerRegistration4';
import './PractitionerRegistrationContainer.css';
import PractitionerRegistrationComplete from './PractitinerRegistration/PractitionerRegistrationComplete';
import PractitionerRegistration6 from './PractitinerRegistration/PractitionerRegistration6';
import PractitionerRegistration5 from './PractitinerRegistration/PractitionerRegistration5';
import PractitionerRegistrationFooter from './PractitinerRegistration/PractitionerRegistrationFooter';
import { setPageActive, createProfile } from '../../../../store/actions/practitionerRegAction';
import validators from './PractitinerRegistration/validation';
import { getAllMetaData } from '../../../../store/actions/appActions';
import PractitionerRegistration0 from './PractitinerRegistration/PractitionerRegistration0';

class PractitionerRegistrationContainer extends Component {
  componentDidMount() {
    const { getAllMetaData, history } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.u_prac_profile_completion_status) {
      history.push(`/${user.u_prac_username}`);
    }
    getAllMetaData();
  }

  nextButtonHandler = () => {
    const { pages, invalid, p_licenses, p_certifications, dispatch, _error } = this.props;
    const activePageIndex = pages.findIndex(page => page.status === 'progress');
    if (pages[activePageIndex].step === 6) {
      dispatch(submit('practitionerProfileForm'));
      return false;
    }
    if (activePageIndex < pages.length - 1) {
      if (pages[activePageIndex].step === 0) {
        if (_error && _error.errorCode && _error.errorCode === 'NOUSERNAME') {
          return false;
        }
        const fields = ['u_prac_username', 'p_about'];
        dispatch(touch('practitionerProfileForm', ...fields));
        if (invalid) return false;
      }
      if (pages[activePageIndex].step === 1) {
        const fields = [
          'p_business_name',
          'p_phone_number',
          'p_address',
          'p_website',
          'p_country',
          'p_state',
          'p_city',
          'p_experience',
          'location'
        ];
        dispatch(touch('practitionerProfileForm', ...fields));
        if (invalid) return false;
      }

      if (pages[activePageIndex].step === 2) {
        const fields = ['p_specialization'];
        dispatch(touch('practitionerProfileForm', ...fields));
        if (invalid) return false;
      }

      if (pages[activePageIndex].step === 4) {
        const fields = ['p_service_type', 'p_session_type', 'p_professional_type'];
        if (p_licenses) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < p_licenses.length; i++) {
            fields.push(`p_licenses.${i}.institution`);
            fields.push(`p_licenses.${i}.year`);
          }
        }
        if (p_certifications) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < p_certifications.length; i++) {
            fields.push(`p_certifications.${i}.institution`);
            fields.push(`p_certifications.${i}.year`);
          }
        }
        dispatch(touch('practitionerProfileForm', ...fields));
        if (invalid) return false;
      }
      if (pages[activePageIndex].step === 5) {
        const fields = ['p_time_availability'];
        dispatch(touch('practitionerProfileForm', ...fields));
        if (invalid) return false;
      }

      if (pages[activePageIndex].step === 6) {
        const fields = ['p_session_price_type', 'p_accepted_insurances'];
        dispatch(touch('practitionerProfileForm', ...fields));
        if (invalid) return false;
      }

      const nextStep = pages[activePageIndex + 1].step;
      dispatch(setPageActive(nextStep));
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  getStep = () => this.props.pages.find(page => page.status === 'progress').step;

  backButtonHandler = () => {
    const { pages, dispatch } = this.props;
    const activePageIndex = pages.findIndex(page => page.status === 'progress');
    if (activePageIndex > 0) {
      const prevStep = pages[activePageIndex - 1].step;
      dispatch(setPageActive(prevStep));
    }
  };

  submitHandler = values => {
    console.log('profile values', values);
    const { dispatch } = this.props;
    dispatch(createProfile(values));
  };

  renderActivePage = () => {
    const { pages } = this.props;
    const page = pages.find(_page => _page.status === 'progress');
    switch (page.step) {
      case 0:
        return <PractitionerRegistration0 />;
      case 1:
        return <PractitionerRegistration1 />;
      case 2:
        return <PractitionerRegistration2 />;
      case 3:
        return <PractitionerRegistration3 />;
      case 4:
        return <PractitionerRegistration4 />;
      case 5:
        return <PractitionerRegistration5 />;
      case 6:
        return <PractitionerRegistration6 />;
      case 7:
        return <PractitionerRegistrationComplete />;
      default:
        break;
    }
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <div id="practitioner-registration-container">
        <div className="practitioner-create-profile">
          <div className="container">
            <div
              className="row justify-content-center align-items-center"
              style={{ fontSize: '1.25rem' }}
            >
              <div className="col-md-10 col-12">
                <Form onSubmit={handleSubmit(this.submitHandler)} autoComplete="off">
                  {this.renderActivePage()}
                </Form>
              </div>
            </div>
          </div>
        </div>
        {this.getStep() <= 6 && (
          <PractitionerRegistrationFooter
            onBack={this.backButtonHandler}
            onNext={this.nextButtonHandler}
            totalSteps={6}
            step={this.getStep()}
          />
        )}
      </div>
    );
  }
}

PractitionerRegistrationContainer.propTypes = {
  currentUser: PropTypes.shape({
    u_prac_username: PropTypes.string,
    u_prac_profile_completion_status: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object),
  // institutions: PropTypes.arrayOf(PropTypes.object),
  p_licenses: PropTypes.arrayOf(PropTypes.object),
  p_certifications: PropTypes.arrayOf(PropTypes.object),
  getAllMetaData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  invalid: PropTypes.bool.isRequired,
  _error: PropTypes.shape({
    errorCode: PropTypes.string,
  }),
};

PractitionerRegistrationContainer.defaultProps = {
  currentUser: null,
  pages: [],
  // institutions: [],
  p_licenses: [],
  p_certifications: [],
  _error: {},
};

const mapStateToProps = state => {
  // isFormValid
  const user = state.auth.currentUser;
  const name = (user && user.u_first_name ? user.u_first_name : '') + ' ' + (user && user.u_last_name ? user.u_last_name : '');
  const selecter = formValueSelector('practitionerProfileForm');
  return {
    pages: state.practitionerReg.pages,
    p_licenses: selecter(state, 'p_licenses'),
    p_certifications: selecter(state, 'p_certifications'),
    currentUser: user,
    _error: state.auth.error,
    initialValues: {
      p_business_name: name,
      p_session_price_type: 'hourly'
    }
  };
};

const mapDispatchToProps = {
  createProfile,
  getAllMetaData,
};

PractitionerRegistrationContainer = reduxForm({
  form: 'practitionerProfileForm',
  destroyOnUnmount: false,
  validate: validators,
  enableReinitialize: true,
})(PractitionerRegistrationContainer);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PractitionerRegistrationContainer)
);
