/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Footer from '../../../shared/Footer/Footer';
import './PractionerProfile.css';
import { getPractitionerProfile } from '../../../../store/actions/practitionerRegAction';
import PractitionerProfileInfo from './PractitionerProfileInfo/PractitionerProfileInfo';
import PractitionerUserOffers from './PractitionerUserOffers/PractitionerUserOffers';
import PractitionerAvailability from './PractitionerAvailability/PractitionerAvailability';
import PractitionerOffers from './PractitionerOffers/PractitionerOffers';
// import PractitionerMedia from './PractitionerMedia/PractitionerMedia';
import PractitionerLocationMap from './PractitionerLocationMap/PractitionerLocationMap';
// import Tag from '../../../common/Tag/Tag';
import PractitionerContent from './PractitionerContent/PractitionerContent';
import PractitionerReview from './PractitionerReview/PractitionerReview';
import PractitionerBusiness from './PractitionerBusiness/PractitionerBusiness';
import PractitionerLicenseCard from './PractitionerLicenseCard/PractitionerLicenseCard';
import ModalComponent from '../../../shared/Modal/Modal';
import LoginForm from '../../Login/LoginForm/LoginForm';
import { login, socialLogin, saveSocialUser } from '../../../../store/actions/authActions';
import SocialButton from '../../../common/SocialButton/SocialButton';

class PractitionerProfile extends Component {
  // prac_id;
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
    };
  }

  submitLoginFormHandler = values => {
    const { login } = this.props;
    login(values, (err, user) => {
      if (user) {
        this.setState({ showModal: false });
        window.location.reload();
      }
    });
  };

  oAuthSuccessHandler = info => {
    const { saveSocialUser, socialLogin } = this.props;
    saveSocialUser(info);
    socialLogin(info, (err, user) => {
      if (user) {
        this.setState({ showModal: false });
        window.location.reload();
      }
    });
  };

  triggerModal = show => this.setState({ showModal: show });

  render() {
    const { profile } = this.props;
    const { showModal } = this.state;
    let promotions = profile.promotions.filter(p => p.usage_remaining && p.promotion_status)
    return (
      <>
        <ModalComponent show={showModal} title="Login" onHide={() => this.triggerModal(false)}>
          <div className="row justify-content-center py-4">
            <div className="col-lg-8 col-10">
              <div className="pb-lg-5 pb-4">
                <div className="row pt-3 pb-md-5 pb-4 position-relative oauth-container">
                  <div className="col-md-6 col-12 m-md-0 mb-3">
                    <SocialButton provider="facebook" onSuccess={this.oAuthSuccessHandler}>
                      Login with Facebook
                    </SocialButton>
                  </div>
                  <div className="col-md-6 col-12">
                    <SocialButton provider="google" onSuccess={this.oAuthSuccessHandler}>
                      Login with Google
                    </SocialButton>
                  </div>
                </div>
              </div>
              <LoginForm onSubmit={this.submitLoginFormHandler} />
            </div>
          </div>
        </ModalComponent>
        {/* <Navbar /> */}
        {profile && (
          <div id="prac_profile">
            <div className="prac_body container py-3">
              <div className="row">
                <div className="col-lg-3">
                  <PractitionerProfileInfo
                    profile={profile}
                    triggerLoginModal={this.triggerModal}
                  />
                  {profile.ownPerspective && (
                    <PractitionerOffers promotions={promotions} className="mt-3" />
                  )}
                  {profile.certifications && profile.certifications.length > 0 && (
                    <PractitionerLicenseCard
                      className="mt-3"
                      title="Licenses"
                      certifications={profile.certifications}
                    />
                  )}
                </div>
                <div className="col-lg-6">
                  {!profile.ownPerspective && profile.promotions.length > 0 && (
                    <PractitionerUserOffers promotions={profile.promotions} className="mb-3" />
                  )}
                  <PractitionerContent
                    className="mt-lg-0 mt-3"
                    title="Contents"
                    ownProfile={profile.ownPerspective}
                  />
                  <PractitionerReview />
                </div>
                <div className="col-lg-3">
                  <PractitionerLocationMap locationAddress={profile.p_location_address} locationCoordinates={profile.p_location_cordinates}/>
                  <div className="mt-3">
                    <PractitionerAvailability availability={profile.p_time_availability} />
                  </div>
                  <div className="mt-3">
                    <PractitionerBusiness business={profile.businessInfo} ownProfile={profile.ownPerspective} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}

PractitionerProfile.propTypes = {
  profile: PropTypes.shape({
    ownPerspective: PropTypes.bool,
    p_time_availability: PropTypes.arrayOf(PropTypes.object),
    certifications: PropTypes.arrayOf(PropTypes.object),
    promotions: PropTypes.arrayOf(PropTypes.object),
    businessInfo: PropTypes.shape({}),
  }).isRequired,
  login: PropTypes.func.isRequired,
  saveSocialUser: PropTypes.func.isRequired,
  socialLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
    profile: state.auth.profile,
  };
};

export default connect(
  mapStateToProps,
  { getPractitionerProfile, login, socialLogin, saveSocialUser }
)(PractitionerProfile);
