export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_OAUTH_USER = 'SET_OAUTH_USER';
export const SET_UPDATE='SET_UPDATE'

export const SET_ERROR = 'SET_ERROR';
export const SET_LOADER = 'SET_LOADER';
export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const SET_STATES_LIST = 'SET_STATES_LIST';
export const SET_CITIES_LIST = 'SET_CITIES_LIST';
export const SET_META_DATA = 'SET_META_DATA';
export const SET_FOCUS_LIST = 'SET_FOCUS_LIST';
export const SET_POPULAR_CONTENTS = 'SET_POPULAR_CONTENTS';
export const SET_RECENT_CONTENTS = 'SET_RECENT_CONTENTS';
export const SET_FEATURED_PRACTITIONERS = 'SET_FEATURED_PRACTITIONERS';
export const SET_NEW_PRACTITIONERS = 'SET_NEW_PRACTITIONERS';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_USER_TYPE_MODAL = 'SET_USER_TYPE_MODAL';
export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const SIGNOUT_REQUEST = 'SIGNOUT_REQUEST';
export const SET_INTEREST_LIST = 'SET_INTEREST_LIST';

export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SET_TESTIMONIALS = 'SET_TESTIMONIALS';

export const SET_BANNER_OPEN = 'SET_BANNER_OPEN';
export const SET_BANNER_MSG = 'SET_BANNER_MSG';

// CATEGORY VIEW ACTIONS

export const SET_TRENDING_LIST = 'SET_TRENDING_LIST';
export const SET_PREMIUM_LIST = 'SET_PREMIUM_LIST';
export const SET_TOP_CONTRIBUTERS = 'SET_TOP_CONTRIBUTERS';
export const SET_FOCUS = 'SET_FOCUS';

// ASSESSMENT ACTIONS
export const SET_ASSESSMENT_QUESTIONS = 'SET_ASSESSMENT_QUESTIONS';
export const SET_ASSESSMENT_ALL_ANSWERS = 'SET_ASSESSMENT_ALL_ANSWERS';
export const SET_ASSESSMENT_ANSWERS = 'SET_ASSESSMENT_ANSWERS';
export const SET_ASSESSMENT_PROGESS = 'SET_ASSESSMENT_PROGESS';
export const SET_ASSESSMENT_USER = 'SET_ASSESSMENT_USER';
export const SET_ASSESSMENT_ERROR = 'SET_ASSESSMENT_ERROR';
export const ANSWERING = 'ANSWERING';
export const SET_ASSESSMENT_PAGE_PROGRESS = 'SET_ASSESSMENT_PAGE_PROGRESS';
export const SET_ASSESSEMENT_RESULT = 'SET_ASSESSEMENT_RESULT';
export const SET_ASSESSMENT_PAGE_ACTIVE = 'SET_ASSESSMENT_PAGE_ACTIVE';

export const SET_RECOMMENDED_CONTENT = 'SET_RECOMMENDED_CONTENT';
export const SET_RECOMMENDED_PRACT = 'SET_RECOMMENDED_PRACT';

// MEMBER ACTIONS

export const SET_MEMBER_PAGE_ACTIVE = 'SET_MEMBER_PAGE_ACTIVE';
export const SET_BOOKMARKED_PRAC_LIST = 'SET_BOOKMARKED_PRAC_LIST';
export const SET_BOOKMARKED_CONTENT_LIST = 'SET_BOOKMARKED_CONTENT_LIST';
export const SET_PURCHASED_CONTENT_LIST = 'SET_PURCHASED_CONTENT_LIST';

// PRACTITIONER REGISTRATION
export const SET_PRACTITIONER_PROFILE = 'SET_PRACTITIONER_PROFILE';
export const SET_PAGE_ACTIVE = 'SET_PAGE_ACTIVE';
export const SET_PRACTITIONER_REG_PAGE_PROGRESS = 'SET_PRACTITIONER_REG_PAGE_PROGRESS';

export const SET_SESSION_STATUS = 'SET_SESSION_STATUS'; // For Member => To Display Session Status with Practitioner
export const SET_PRAC_BOOKMARK_STATUS = 'SET_PRAC_BOOKMARK_STATUS';
export const SET_USER_BLOCK_STATUS = 'SET_USER_BLOCK_STATUS';
export const SET_SHARE_RESULT_STATUS = 'SET_SHARE_RESULT_STATUS';
export const SET_REQUEST_RESULT_STATUS = 'SET_REQUEST_RESULT_STATUS';

export const SET_SESSION_REQUESTS = 'SET_SESSION_REQUESTS';
export const UPDATE_SESSION_REQUESTS = 'UPDATE_SESSION_REQUESTS';

export const SET_PRACTITIONER_CONTENT_LIST = 'SET_PRACTITIONER_CONTENT_LIST';
export const UPDATE_PRACTITIONER_CONTENT_LIST = 'UPDATE_PRACTITIONER_CONTENT_LIST';
export const INCREMENT_RATING_COUNT = 'INCREMENT_RATING_COUNT';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const CHANGE_CONTENT_PUBLISH_STATUS = 'CHANGE_CONTENT_PUBLISH_STATUS';
export const SET_USER_REVIEW_LIST = 'SET_USER_REVIEW_LIST';
export const UPDATE_USER_REVIEW_LIST = 'UPDATE_USER_REVIEW_LIST';

// PUBLISH

export const SET_MODAL_ACTIVE = 'SET_MODAL_ACTIVE';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_CONTENT_FILE = 'SET_CONTENT_FILE';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const CLEAR_CONTENT_FILE = 'CLEAR_CONTENT_FILE';
export const SET_PUBLISH_PAGE = 'SET_PUBLISH_PAGE';

export const SET_CONTENT_CATEGORIES = 'SET_CONTENT_CATEGORIES';
export const PUBLISHED_CONTENT = 'PUBLISHED_CONTENT';

export const SET_EDIT_CONTENT = 'SET_EDIT_CONTENT';
export const UPDATE_EDIT_CONTENT = 'UPDATE_EDIT_CONTENT';

// LINK BUSINESS
export const SET_LINK_BUSINESS_PAGE_ACTIVE = 'SET_LINK_BUSINESS_PAGE_ACTIVE';
export const SET_BUSINESSES = 'SET_BUSINESSES';

// NOTIFICATIONS
export const SET_NOTIFICATIONS_LIST = 'SET_NOTIFICATIONS_LIST';
export const UPDATE_NOTIFICATION_LIST = 'UPDATE_NOTIFICATION_LIST';
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS'; // MARK AS READ ALL || MARK AS SEEN ALL
export const MARK_AS_READ = 'MARK_AS_READ';
export const SET_ADMIN_APPROVE_STATUS = 'SET_ADMIN_APPROVE_STATUS';
export const CHANGE_BUSINESS_LOGO = 'CHANGE_BUSINESS_LOGO';

// SEARCH
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST';
export const SET_PROMO_CODE = 'SET_PROMO_CODE';

// FEED ACTIONS

export const SET_RECOMMENDED_FEED_CONTENT = 'SET_RECOMMENDED_FEED_CONTENT';
export const SET_POPULAR_FEED_CONTENTS = 'SET_POPULAR_FEED_CONTENTS';
export const SET_HOT_NEW_CONTENTS = 'SET_HOT_NEW_CONTENTS';
export const SET_RECENTLYADDED_CONTENTS = 'SET_RECENTLYADDED_CONTENTS';
export const SET_FEATURED_FEED_PRACTITIONERS = 'SET_FEATURED_FEED_PRACTITIONERS';
export const SET_NEW_FEED_PRACTITIONERS = 'SET_NEW_FEED_PRACTITIONERS';
export const SET_NEWPRACTITIONER_CONTENTS = 'SET_NEWPRACTITIONER_CONTENTS';
export const SET_RECOMMENDED_FEED_PRACT = 'SET_RECOMMENDED_FEED_PRACT';

// CONTENT DETAIL VIEW

export const SET_CONTENT_VIEW = 'SET_CONTENT_VIEW';
export const SET_CONTENT_FEEDS = 'SET_CONTENT_FEEDS';
export const SET_CONTENT_LOADER = 'SET_CONTENT_LOADER';
export const SET_COMMENT_LIST = 'SET_COMMENT_LIST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const SET_CONTENT_BOOKMARK_STATUS = 'SET_CONTENT_BOOKMARK_STATUS';

// SET PROMOTIONS
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const DELETE_PROMOTION = 'DELETE_PROMOTION';
export const SET_PROMOTION = 'SET_PROMOTION';
export const ADD_PROMOTION = 'ADD_PROMOTION';
export const EDIT_PROMOTION = 'EDIT_PROMOTION';

export const SET_PAYMENT_HISTORY = 'SET_PAYMENT_HISTORY';

export const SET_BLOCKED_ACCOUNTS = 'SET_BLOCKED_ACCOUNTS';

export const SET_USER_PROFILES = 'SET_USER_PROFILES';

export const SET_ORDERS_LIST = 'SET_ORDERS_LIST';
export const SET_SALES_LIST = 'SET_SALES_LIST';

export const SET_BUSINESS_INFO = 'SET_BUSINESS_INFO';

// SHARED RESULTS
export const SET_SHARED_RESULTS = 'SET_SHARED_RESULTS';
// export const SET_SHARED_WITH_LIST = 'SET_SHARED_WITH_LIST';
export const SET_NEWRESULT_FIRST = 'SET_NEWRESULT_FIRST';
export const SET_OLDRESULT_FIRST = 'SET_OLDRESULT_FIRST';
export const UPDATE_SHARED_RESULT = 'UPDATE_SHARED_RESULT';

// MESSAGES
export const SET_ACTIVE_ROOM = 'SET_ACTIVE_ROOM';
export const RESET_ACTIVE_ROOM = 'RESET_ACTIVE_ROOM';
export const UPDATE_SEARCH_USER = 'UPDATE_SEARCH_USER';
export const UPDATE_RECENT_USER = 'UPDATE_RECENT_USER';
export const UPDATE_RECENT_SEARCH_KEY = 'UPDATE_RECENT_SEARCH_KEY';
export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const SET_READ_MESSAGE = 'SET_READ_MESSAGE';
export const NOTIFY_MESSAGE = 'NOTIFY_MESSAGE';
export const LOAD_CHAT = 'LOAD_CHAT';

// PAYMENTS
export const ADD_CARD = 'ADD_CARD';
export const SET_ALL_CARDS = 'SET_ALL_CARDS';
export const TOGGLE_ADD_CARD = 'TOGGLE_ADD_CARD';
export const ON_DELETE_CARD = 'ON_DELETE_CARD';
export const SET_PRICE_PLANS = 'SET_PRICE_PLANS';
export const SET_PAYMENT_CHECK_PLAN = 'SET_PAYMENT_CHECK_PLAN';
export const ON_PAYMENT = 'ON_PAYMENT';
export const MEMBERSHIP_DETAILS = 'MEMBERSHIP_DETAILS';
export const SET_PAYMENT_LOADER = 'SET_PAYMENT_LOADER';
export const SET_CREDIT_CARD_LOADER = 'SET_CREDIT_CARD_LOADER';
export const CLEAR_PAYMENT_FORM = 'CLEAR_PAYMENT_FORM';
export const ON_UPDATE_CARD = 'ON_UPDATE_CARD';
export const SET_PREMIUM_USER = 'SET_PREMIUM_USER';
export const SET_PURCHASED_CONTENT = 'SET_PURCHASED_CONTENT';

// PROGRESS CHECK
export const SET_PROGRESS_CHECK_ACTIVE = 'SET_PROGRESS_CHECK_ACTIVE';
export const SET_PROGRESS_CHECK_ANSWER = 'SET_PROGRESS_CHECK_ANSWER';
export const GET_PROGRESS_CHECK_QUESTIONS = 'GET_PROGRESS_CHECK_QUESTIONS';
export const SET_PROGRESS_CHECK_LAST_DATE = 'SET_PROGRESS_CHECK_LAST_DATE';
