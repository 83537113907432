/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import './CommunityGuidelines.css';
import ListGuidelines from './ListGuidelines';
import * as data from './guidelinesData.json';
import { openFeedBackModal } from '../../../store/actions/appActions';

const npGuidelines = data.default.nudityAndPornography;
const racismGuidelines = data.default.racism;
const criticismGuideLines = data.default.criticism;
const harrasmentGuidelines = data.default.harrasment;
const { deleberatemisuse } = data.default;
const { practitioners } = data.default;

const CommunityAndContentGuidelines = ({ openFeedBackModal }) => {
  const openFeedBackHandler = event => {
    event.preventDefault();
    openFeedBackModal(true);
  };
  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row" id="community-guidelines">
          <div className="col-md-8 col-12">
            <div>
              <h1 className="title">COMMUNITY AND CONTENT GUIDELINES</h1>
              <p className="guidelines-info">
                GuideTrue is a respectful, supportive, and encouraging community that's deeply
                passionate about wellness and personal development.
              </p>
            </div>
            <div>
              <h1>MEMBERSHIP GUIDELINES OF CONDUCT</h1>
              <p>
                The key words are: Integrity, Sincerity, Honesty, Humility, Compassion, and
                Self-Responsibility.
                <br />
                <br />
                By using GuideTrue, you henceforth agree to and warrant that you understand:
                <br />
                <br />
                - Every person is to be treated with respect, compassion, equanimity, and equality.
                <br />
                <br />- Users take self-responsibility and accountability for their words and
                emotional states. It is understood that we all have good and bad days. None of us
                are perfect. However, if you're feeling triggered or vitriolic, we suggest taking
                time away to process your emotions instead of acting rashly, and then provide
                feedback{' '}
                {/* eslint-disable-next-line */}
                <a
                  className="cursor-pointer text-primary"
                  onClick={openFeedBackHandler}
                  role="presentation"
                >
                  <u>HERE</u>
                </a>{' '}
                if you feel a user is in violation of any rule.
                <br />
                <br />
                - Users agree that they alone are responsible for any choices and decisions they
                make based on following any ideas, advice, and information on GuideTrue.
                <br />
                <br />
                - Users agree that any information on the forum is not intended to replace that of
                licensed medical services, or to divert persons from seeking medical professional
                care and help.
                <br />
                <br />
                - Users agree not to attempt to impose, solicit for, or manipulate using any
                religious or political views.
                <br />
                <br />
                - Uses agree to respect the rights of others, including but not limited to
                intellectual property, copyright, and trademarks. Respecting the intellectual
                property of other people is a fundamental principle of GuideTrue’s community.
                <br />
                <br />
                - Any perceived communication or exchanges that imply copyright infringement,
                stalking, trolling, personal agendas, harassment, or inappropriate behavior of any
                kind that threatens the GuideTrue community or well being of any person will result
                in the immediate ban from GuideTrue and cancellation of membership.
                <br />
                <br />
                GuideTrue asks that you do not seek or argue with content you don’t agree with
                (there's never a need for hostility). If you happen to see content that violates our
                guidelines, please flag it through one of the reporting functions on our site. Above
                all, we urge you to make the most of your time here by contributing and offering
                support to each other as constructively as possible. Contribute positively to the
                community, and you’ll find that GuideTrue is an uplifting and rewarding place.
                <br />
                <br />
              </p>
              <ul className="community-list p-0 mb-5">
                <li>
                  <a href="/community-and-content-guidelines#the-fine-print">- The Fine Print</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#age">- Age</a>
                </li>
                {/* <li>
                  <a href="#">- Mature Content</a>
                </li> */}
                <li>
                  <a href="/community-and-content-guidelines#tag-or-group-spamming">
                    - Tag or Group spamming
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#self-promotion">- Self-Promotion</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#adult-language">- Adult Language</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#nudity-and-pornography">
                    - Nudity/Pornography
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#violence">- Violence</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#racism">- Racism</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#country-specific-law">
                    - Country Specific Laws
                  </a>
                </li>

                <li>
                  <a href="/community-and-content-guidelines#defamation-or-libel">
                    - Defamation or libel
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#vilification">- Vilification</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#uninvited-criticism">
                    - Uninvited criticism
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#personal-attacks">
                    - Personal attacks
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#harassment">- Harassment</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#baiting">- Baiting</a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#threatening-behavior">
                    {' '}
                    - Threatening Behavior
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#copyright-and-other-forms-of-ip-protection">
                    - Copyright and other forms of IP protection
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#deliberate-misuse-of-guidetrue">
                    - Deliberate misuse of GuideTrue
                  </a>
                </li>
                <li>
                  <a href="/community-and-content-guidelines#">- Hosts</a>
                </li>
              </ul>
            </div>

            <div id="the-fine-print">
              <h1>THE FINE PRINT</h1>
              <p>
                The following paragraphs summarize GuideTrue's approach to behavior and content.
                These guidelines have been made public in the spirit of open communication but it
                should be noted that they are adaptable and will evolve over time. Depending on the
                severity, a breach of GuideTrue's guidelines can result in a formal warning, account
                suspension, or account closure and forfeiture of all user rights. GuideTrue has
                internal policies and procedures in place to ensure these guidelines are applied in
                a consistent and fair manner. In applying these guidelines, GuideTrue reserves the
                right to remove any content or close any account at our sole discretion.
              </p>
            </div>

            <div id="age">
              <h1>AGE</h1>
              <p>
                Members must be 16 years or older. This is a legal requirement as people under the
                age of 16 are not able to enter into a legal contract with GuideTrue.
              </p>
            </div>

            <div id="tag-or-group-spamming">
              <h1>TAG OR CATEGORY SPAMMING</h1>
              <p>
                Tag and group spamming are not permitted. Tag spamming is the use of irrelevant or
                inappropriate words in the tags on a work - for example, using the tag schizophrenia
                on content solely pertaining to fitness, or using adult themed tag words on works
                that reference children. Category spamming is when a user persistently or
                deliberately lists a work to inappropriate categories - for example, listing a yoga
                video to a relationship category.
              </p>
            </div>

            <div id="self-promotion">
              <h1>SELF-PROMOTION</h1>
              <p>
                Members can self-promote in their parts of the site (i.e. in their content and
                profile), but should refrain from uninvited self-promotion outside of these areas.
                For example, members should not self promote in the comments under another member’s
                work. Practitioners can promote products and services they provide in their own
                content or profile, but need to be clear and transparent about the details – in
                particular, the financial details, so as not to be misleading. We discourage
                practitioners from directing members to make purchases on external websites or other
                venues which detracts from GuideTrue. Doing so may be grounds for termination of
                membership without warning.
              </p>
            </div>

            <div id="adult-language">
              <h1>ADULT LANGUAGE</h1>
              <p>
                Malicious or hurtful swearing at another member is not permitted (it is considered a
                personal attack). Swearing and adult language in a more general context within
                polite reason is acceptable (try to keep it clean). Works containing adult language
                or imagery should preferably be marked as Mature Content at the onset of the content
                so as to serve as a warning, or else it may be subject to negative feedback from the
                community.
              </p>
            </div>

            <div id="nudity-and-pornography">
              <h1>NUDITY AND PORNOGRAPHY</h1>
              <p>The following material is not permissible on GuideTrue:</p>
              <ListGuidelines data={npGuidelines} />
            </div>

            <div>
              <h1>HATE SPEECH</h1>
              <p>
                All content should be without undesirable associations or connotations (for example,
                sexually or violently suggestive, demeaning, derogative or political material or
                phrasing that could be interpreted as such). Humorous slogans need to be tested
                against a broad range of views as they can cause unforeseen and unintended offense.
                Gender specific slogans also need careful consideration.
              </p>
            </div>

            <div id="violence">
              <h1>VIOLENCE AND HATRED</h1>
              <p>
                Work that glorifies or trivializes violence is not permitted. This includes graphic
                depictions of violence, works that trivialize violent acts, and work or behavior
                where the intent of the user is to incite hatred or violence.Works that deal with
                catastrophic events such as genocides or holocausts need to be sensitively handled.
                Works that have the potential to cause the victims serious distress may be removed.
              </p>
            </div>

            <div id="racism">
              <h1>RACISM</h1>
              <p>
                Racist content or behavior and material designed to incite racism is not permitted.
                We define racism as either the hatred or intolerance of another race, or
                discrimination, or antagonism directed against someone of a different race based on
                the belief that one's own race is superior, or a belief that all members of a racial
                group possess characteristics specific to that race, especially to distinguish it as
                being inferior.
                <br />
                <br />
                Racist Terms: Works that use racist terms in a casual and/or derogatory way are not
                permitted. The decision to remove content will depend on the context in which words
                are used, but as a rule of thumb this excludes situations where legitimate
                discussion or commentary is taking place. For example, a written work where the
                artist uses the word 'nigger' in the context of describing her personal experiences
                of racism would be allowed to remain, but an article featuring the word in a profane
                manner would not.
                <br />
                <br />
                Use of Symbols: Symbols and icons carry strong messages e.g. the swastika, crosses,
                the Confederate Flag, and the Koran, to name a few. These symbols and their like can
                be used in a work, but only with considerable care. Works may be removed if:
              </p>
              <ListGuidelines data={racismGuidelines} />
            </div>

            <div id="country-specific-law">
              <h1>COUNTRY SPECIFIC LAWS</h1>
              <p>
                Some countries regulate or prohibit the use of certain content, like symbols, images
                or words. To comply with the laws in these countries, GuideTrue may remove works
                that contain such content.
              </p>
            </div>
            <div id="defamation-or-libel">
              <h1>DEFAMATION OR LIBEL</h1>
              <p>
                Any work or behavior that can reasonably be considered defamatory, libelous or
                vilifying is not permitted. As a general rule, works or comments which are presented
                as an opinion or a criticism on a subject of public interest do not fall under this
                definition.
              </p>
            </div>

            <div id="vilification">
              <h1>VILIFICATION</h1>
              <p>
                We define vilification as conduct that is intended to invoke serious contempt,
                revulsion or severe ridicule of an individual or group of people because of race,
                religion, disability, gender identity, sexuality or they are deemed to be a
                vulnerable person or group eg: refugees. As a general rule, works which are
                presented as an opinion or a criticism on a subject of public interest do not fall
                under this definition.
              </p>
            </div>

            <div id="uninvited-criticism">
              <h1>CRITICISM</h1>
              <p>
                Criticism should only be provided In a manner that is tactful, compassionate, and
                constructive. Criticism includes:
              </p>

              <ListGuidelines data={criticismGuideLines} />
              <p>
                GuideTrue provides a supportive and encouraging environment for Practitioners of all
                skill levels and experience. Please be kind and refrain from belittling comments,
                especially ad hominem attacks. If someone is unprepared for criticism or is
                self-conscious about their work, uninvited criticism can be upsetting and can have a
                negative impact.
              </p>
            </div>

            <div id="personal-attacks">
              <h1>PERSONAL ATTACKS</h1>
              <p>
                A personal attack is where a member writes hurtful or insulting things about another
                member or makes personal remarks with the intention of hurting or upsetting them.
                Personal attacks can also include comments made about a group of members, and names
                don’t have to be mentioned in order for behavior to be considered a personal attack.
                Most importantly, provocation is not a justification for a personal attack on
                another member.
                <br />
                <br />
                If an exchange is becoming heated or if someone’s behavior isn’t appropriate we
                strongly advise taking a few deep breaths, reporting any instances where the
                behavior falls outside GuideTrue's guidelines, and then ceasing communication.
                <br />
                <br />
                GuideTrue staff do their very best to listen and address concerns but we ask that
                you treat staff as you treat each other. Staff welcome suggestions, ideas, feedback
                and constructive criticism but we ask that you share your thoughts in a mature,
                respectful and considerate manner. Personal attacks on staff will be followed up in
                line with our Community Guidelines.
                <br />
                <br />
                Depending on the severity, an isolated comment or message can usually be dealt with
                as advised in our help article on resolving conflict online.
              </p>
            </div>

            <div id="harassment">
              <h1>HARASSMENT</h1>
              <p>
                Harassing behavior includes: to disturb persistently, torment, humiliate, bother
                continually, pester or persecute. Names don’t have to be mentioned in order for
                behavior to be considered harassing. Harassment doesn’t have to be negative
                behavior; it also includes unwelcome behavior.
                <br />
                <br />
                Harassment can include:
              </p>
              <ListGuidelines data={harrasmentGuidelines} />
              <p>
                Depending on the severity, an isolated comment or private message can usually be
                dealt with as advised in our help article on{' '}
                <a className="text-primary" href="/community-and-content-guidelines#">
                  resolving conflict online
                </a>
                . If you are being harassed, as a first step please politely ask the person not to
                contact you again. If the harassment continues, please report all harassing
                comments, private messages, and other communication to GuideTrue staff using the
                reporting links provided around the site, and then block the user using the block
                function on the user's profile. GuideTrue is committed to taking action to prevent
                these offenses, but is not responsible for handling harassment claims and their
                subsequent potential professional, personal, or psychological consequences.
              </p>
            </div>

            <div id="baiting">
              <h1>BAITING</h1>
              <p>
                Baiting is behavior that deliberately provokes an angry response or argument over a
                topic. Baiting behavior includes posting controversial or inflammatory messages,
                which provoke other members into angry and emotional responses. This is frowned upon
                by GuideTrue, and may result in account closure depending on the severity or
                frequency.
              </p>
            </div>

            <div id="threatening-behavior">
              <h1>THREATENING BEHAVIOR</h1>
              <p>
                Threatening behavior includes threatening the life, safety or well being of another
                member. Threatening behavior will result in immediate account closure.
              </p>
            </div>

            <div id="copyright-and-other-forms-of-ip-protection">
              <h1>COPYRIGHT AND OTHER FORMS OF IP PROTECTION</h1>
              <p>
                GuideTrue respects Copyright and Trademark laws and will remove any work found to
                infringe Copyright or Trademark protection. If you believe your copyright or other
                intellectual property rights are being infringed, you are able to make a formal
                complaint by using the processes described in our{' '}
                <Link className="text-primary" target="_blank" to="/privacy-policy">
                  policy
                </Link>
                .
              </p>
            </div>

            <div id="deliberate-misuse-of-guidetrue">
              <h1>DELIBERATE MISUSE</h1>
              <p>
                Deliberate misuse is when a person or entity opens an account with the primary
                purpose of infringing the GuideTrue Community Guidelines or is found to be otherwise
                using the account for purposes GuideTrue was not designed for. In most cases,
                deliberate misuse will result in immediate account closure. If the account is found
                to be linked to an existing membership, the consequences for inappropriate behavior
                will be applied to all the artist’s accounts. Examples of deliberate misuse include
                but are not limited to the following:
              </p>

              <ListGuidelines data={deleberatemisuse} />
            </div>

            <div>
              <h1>PRACTITIONERS</h1>
              <p>
                As professionals, you should set a great example of how to participate and interact
                with others. Practitioners should be equipped with a good understanding of what is
                and isn’t acceptable behavior. As a practitioner, people look up to you as a leader
                and an example of how to behave on the site. By being active, positive and
                enthusiastic, practitioners encourage and inspire others to participate. Weʼd also
                like Practitioners to help make people feel welcome and provide a safe, encouraging
                and fun environment.
                <br />
                <br />
                What do we expect of Practitioners?
              </p>
              <ListGuidelines data={practitioners} />
            </div>
            <div />
          </div>
        </div>
      </div>

      <div style={{ marginTop: '3rem' }}>
        <Footer />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openFeedBackModal,
};

export default connect(
  null,
  mapDispatchToProps
)(CommunityAndContentGuidelines);
