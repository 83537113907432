/* eslint-disable no-empty */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import { toast } from 'react-toastify';
import {
  SET_LOADER,
  SET_COUNTRIES_LIST,
  SET_STATES_LIST,
  SET_CITIES_LIST,
  SET_META_DATA,
  SET_POPULAR_CONTENTS,
  SET_RECENT_CONTENTS,
  SET_FEATURED_PRACTITIONERS,
  SET_NEW_PRACTITIONERS,
  SET_USER_TYPE_MODAL,
  SET_USER_TYPE,
  SET_PAYMENT_CHECK_PLAN,
  SET_FOCUS_LIST,
  OPEN_FEEDBACK_MODAL,
  SET_INTEREST_LIST,
  SET_USER_LOCATION,
  SET_TESTIMONIALS,
  SET_BANNER_OPEN,
  SET_BANNER_MSG,
} from './types';
import axios from '../../axios';

export const setLoader = loaderState => {
  return {
    type: SET_LOADER,
    payload: loaderState,
  };
};

export const setPopularContents = contents => ({
  type: SET_POPULAR_CONTENTS,
  payload: contents,
});

export const setRecentContents = contents => ({
  type: SET_RECENT_CONTENTS,
  payload: contents,
});

export const setFeaturedPractitioner = paractitioners => ({
  type: SET_FEATURED_PRACTITIONERS,
  payload: paractitioners,
});

export const setNewPractitioner = contents => ({
  type: SET_NEW_PRACTITIONERS,
  payload: contents,
});

export const setUserTypeModal = show => dispatch => {
  dispatch({
    type: SET_USER_TYPE_MODAL,
    payload: show,
  });
};

export const setUserType = type => dispatch => {
  dispatch({
    type: SET_USER_TYPE,
    payload: type,
  });
};

export const openFeedBackModal = value => dispatch => {
  dispatch({
    type: OPEN_FEEDBACK_MODAL,
    payload: value,
  });
};

export const setUserLocation = (lat, lng) => dispatch => {
  dispatch({
    type: SET_USER_LOCATION,
    payload: { lat, lng }
  })
}

export const setStatusBanner = show => dispatch => {
  dispatch(_setStatusBanner(show));
}

const _setStatusBanner = show => ({
  type: SET_BANNER_OPEN,
  payload: show
})

export const setStatusBannerMsg = msg => ({
  type: SET_BANNER_MSG,
  payload: msg
})

export const getAppConfig = () => async dispatch => {
  try {
    const resp = await axios.get('/api/user/config');
    const { config } = resp.data;
    if(config.bannerMsg) {
      dispatch(setStatusBannerMsg(config.bannerMsg));
      dispatch(_setStatusBanner(true));
    }
  } catch (error) {
    // toast.error('Somthing went wrong');
  }
}

export const getCountries = () => async dispatch => {
  try {
    const response = await axios.get('/api/meta/countries');
    const countries = response.data.countries.map(country => {
      return {
        label: country.name,
        value: String(country.id),
      };
    });
    dispatch({
      type: SET_COUNTRIES_LIST,
      payload: countries,
    });
  } catch (error) {}
};

export const getTestimonials = () => async dispatch => {
  try {
    const response = await axios.get('/api/meta/testimonials');
    const { testimonials } = response.data;
    dispatch({
      type: SET_TESTIMONIALS,
      payload: testimonials,
    })
  } catch (error) {
    console.log('Error', error);
  }
}

export const getStates = (country_id = null) => async dispatch => {
  try {
    const response = await axios.get(`/api/meta/states?country=${country_id}`);
    const states = response.data.states.map(state => {
      return {
        label: state.name,
        value: String(state.id),
      };
    });

    dispatch({
      type: SET_STATES_LIST,
      payload: states,
    });
  } catch (error) {}
};

export const getCities = (state_id = null) => async dispatch => {
  try {
    const response = await axios.get(`/api/meta/cities?state=${state_id}`);
    const cities = response.data.cities.map(state => {
      return {
        label: state.name,
        value: String(state.id),
      };
    });

    dispatch({
      type: SET_CITIES_LIST,
      payload: cities,
    });
  } catch (error) {}
};

export const getMemberIntrestList = () => async dispatch => {
  dispatch(setLoader(true));
  try {
    const response = await axios.get('/api/meta/categories?area_to_improve=true');
    const categories = response.data.categories.map(category => {
      return {
        label: category.category_name,
        value: category._id,
      };
    });
    // dispatch({
    //   type: SET_META_DATA,
    //   payload: {
    //     categories,
    //   },
    // });
    dispatch({
      type: SET_INTEREST_LIST,
      payload: categories,
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getAllMetaData = () => async dispatch => {
  const requests = [
    axios.get('/api/meta/all'),
    axios.get('/api/meta/categories?specialization=true'),
  ];
  try {
    dispatch(setLoader(true));
    const [response, response1] = await axios.all(requests);

    const countries = response.data.countries.map(country => {
      return {
        label: country.name,
        value: String(country.id),
      };
    });
    const spiritual_orientations = response.data.spiritualOrientations.map(so => {
      return {
        label: so.name,
        value: so._id,
      };
    });
    const cultural_heritages = response.data.culturalHeritages.map(ch => {
      return {
        label: ch.name,
        value: ch._id,
      };
    });
    const insurances = response.data.insurances.map(insurance => {
      return {
        label: insurance.insurance_name,
        value: insurance._id,
      };
    });
    const universities = response.data.universities.map(university => {
      return {
        label: university.university_name,
        value: university._id,
      };
    });
    const categories = response1.data.categories.map(category => {
      return {
        label: category.category_name,
        value: category._id,
      };
    });
    dispatch({
      type: SET_META_DATA,
      payload: {
        countries,
        spiritual_orientations,
        cultural_heritages,
        universities,
        insurances,
        categories,
      },
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
  }
};

export const getMetaData = () => async dispatch => {
  try {
    const response = await axios.get('/api/meta/all');
    const countries = response.data.countries.map(country => {
      return {
        label: country.name,
        value: country.id,
      };
    });
    const spiritual_orientations = response.data.spiritualOrientations.map(so => {
      return {
        label: so.name,
        value: so._id,
      };
    });
    const cultural_heritages = response.data.culturalHeritages.map(ch => {
      return {
        label: ch.name,
        value: ch._id,
      };
    });
    const insurances = response.data.insurances.map(insurance => {
      return {
        label: insurance.insurance_name,
        value: insurance._id,
      };
    });

    const universities = response.data.universities.map(university => {
      return {
        label: university.university_name,
        value: university._id,
      };
    });

    dispatch({
      type: SET_META_DATA,
      payload: {
        countries,
        spiritual_orientations,
        cultural_heritages,
        universities,
        insurances,
      },
    });
  } catch (error) {}
};

export const getLandingPageData = cb => async dispatch => {
  try {
    const [response, response1, response2, response3, response4] = await axios.all([
      axios.get('/api/content/popular-contents-landing?page=1'),
      axios.get('/api/content/recent-contents?page=1'),
      axios.get('/api/practitioner?featured=true'),
      axios.get('/api/meta/focuses'),
      axios.get('/api/practitioner/new_practitioners'),
    ]);
    dispatch(setPopularContents(response.data.popularContent.contents));
    dispatch(setRecentContents(response1.data.contents));
    dispatch(setFeaturedPractitioner(response2.data.practitioners));
    dispatch({
      type: SET_FOCUS_LIST,
      payload: response3.data.focuses,
    });
    dispatch(setNewPractitioner(response4.data.practitioners));
    cb();
  } catch (error) {
    cb();
  }
};

export const loadPopularContents = (page, cb) => async (dispatch, getState) => {
  try {
    const response = await axios.get(`/api/content/popular-contents?page=${page}`);
    const { popularContent } = response.data;
    const { popular_contents } = getState().app;
    const updatedResult = [...popular_contents, ...popularContent.contents];
    dispatch(setPopularContents(updatedResult));
    cb(popularContent.hasMore);
  } catch (error) {
    cb();
    toast.error('Somthing went wrong');
  }
};

export const loadPopularContentsLanding = (page, cb) => async (dispatch, getState) => {
  try {
    const response = await axios.get(`/api/content/popular-contents-landing?page=${page}`);
    const { popularContent } = response.data;
    const { popular_contents } = getState().app;
    const updatedResult = [...popular_contents, ...popularContent.contents];
    dispatch(setPopularContents(updatedResult));
    cb(popularContent.hasMore);
  } catch (error) {
    cb();
    toast.error('Somthing went wrong');
  }
};

export const loadRecentContents = (page, cb) => async (dispatch, getState) => {
  try {
    const response = await axios.get(`/api/content/recent-contents?page=${page}`);
    const { contents, hasMore } = response.data;
    const { recent_contents } = getState().app;
    const updatedResult = [...recent_contents, ...contents];
    dispatch(setRecentContents(updatedResult));
    cb(hasMore);
  } catch (error) {
    cb();
    toast.error('Somthing went wrong');
  }
};

export const getPlanDetails = id => dispatch => {
  dispatch(setLoader(true));
  axios.get(`/api/meta/get-plan/${id}`).then(res => {
    dispatch(setLoader(false));
    const { data } = res;
    if (data.status) {
      dispatch({
        type: SET_PAYMENT_CHECK_PLAN,
        payload: data.pricePlan,
      });
    }
  });
};

export const createFeedback = (feedback, cb) => async dispatch => {
  try {
    await axios.post('/api/user/feedback', feedback);
    toast.success('Thank you for your feedback');
    cb(null);
  } catch (error) {
    cb(error);
    toast.error('Something went wrong! please try again');
  }
};
