/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { isUsernameAvailable } from '../../../store/actions/authActions';
import { updateUserProfile } from '../../../store/actions/settingsActions';
import Input from '../../common/Input/Input';
import { isEmpty, isValidUsername } from '../../../utils/validators';

const validaters = values => {
  const errors = {};
  if (!isEmpty(values.u_username)) {
    errors.u_username = 'This field is required';
  } else if (!isValidUsername(values.u_username)) {
    errors.u_username = 'Invalid username';
  }

  if (!isEmpty(values.u_prac_username)) {
    errors.u_prac_username = 'This field is required';
  } else if (!isValidUsername(values.u_prac_username)) {
    errors.u_prac_username = 'Invalid username';
  }
  if (
    isEmpty(values.u_username) &&
    isEmpty(values.u_prac_username) &&
    values.u_username === values.u_prac_username
  ) {
    errors.u_username = 'Username should be unique';
    errors.u_prac_username = 'Username should be unique';
  }
  return errors;
};

let UsernameUpdateForm = ({
  user,
  handleSubmit,
  isUsernameAvailable,
  updateUserProfile,
  submitting,
  changeMemberUsername,
  memberUsername,
  practitionerUsername,
  changePractitionerUsername
}) => {
  const [available, setAvailable] = useState('');

  const checkAvailability = (event, type) => {
    const username = event.target.value;
    setAvailable('');
    if (
      !isValidUsername(username) ||
      user.u_username === username ||
      user.u_prac_username === username
    ) {
      return false;
    }
    isUsernameAvailable(username, err => {
      if (err) {
        setAvailable(`${type}-error`);
      } else {
        setAvailable(`${type}-success`);
      }
    });
    if(type === 'member'){
      changeMemberUsername(event.target.value);
    } else {
      changePractitionerUsername(event.target.value);
    }
  };

  const updateHandler = values => {
    updateUserProfile(values, 'username');
  };

  return (
    <div>
      <form onSubmit={handleSubmit(updateHandler)}>
        <div className="mb-3">
          <label className="subtitle">MEMBER USERNAME</label>
          <div className="position-relative">
            {available === 'member-success' && (
              <i className="icon-check_circle text-success validation-badge" />
            )}
            {available === 'member-error' && (
              <i className="icon-times-circle text-danger validation-badge" />
            )}
            <Field
              name="u_username"
              type="text"
              placeholder="Username"
              component={Input}
              onChange={event => checkAvailability(event, 'member')}
            />
            {user && user.u_username &&
            <span className="d-block display2">
              Your GuideTrue URL: <strong> https://guidetrue.com/{memberUsername ? memberUsername : user.u_username}</strong>
            </span>}
          </div>
        </div>
        {user && user.u_prac_profile_completion_status && (
          <div className="">
            <label className="subtitle">PRACTITIONER USERNAME</label>
            <div className="position-relative">
              {available === 'prac-success' && (
                <i className="icon-check_circle text-success validation-badge" />
              )}
              {available === 'prac-error' && (
                <i className="icon-times-circle text-danger validation-badge" />
              )}
              <Field
                name="u_prac_username"
                type="text"
                placeholder="Username"
                component={Input}
                onChange={event => checkAvailability(event, 'prac')}
              />
              {user && user.u_prac_username &&
                <span className="d-block display2">
                  Your GuideTrue URL: <strong> https://guidetrue.com/{practitionerUsername ? practitionerUsername : user.u_prac_username}</strong>
                </span>}
            </div>
          </div>
        )}
        <div className="mt-4">
          <button type="submit" className="btn gt-btn" disabled={submitting}>
            Update
            {submitting && <span className="spinner-border spinner-border-sm ml-2" />}
          </button>
        </div>
      </form>
    </div>
  );
};
UsernameUpdateForm = reduxForm({
  form: 'update-username',
  validate: validaters,
  enableReinitialize: true,
})(UsernameUpdateForm);

UsernameUpdateForm.propTypes = {
  user: PropTypes.shape({
    u_username: PropTypes.string,
    u_prac_username: PropTypes.string,
    u_prac_profile_completion_status: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func,
  isUsernameAvailable: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  memberUsername: PropTypes.func.isRequired,
  practitionerUsername: PropTypes.func.isRequired,
  changeMemberUsername: PropTypes.string,
  changePractitionerUsername: PropTypes.string,
};

UsernameUpdateForm.defaultProps = {
  user: null,
};

const mapDispatchToProps = {
  isUsernameAvailable,
  updateUserProfile,
};

const mapStateToProps = state => {
  const user = state.auth.currentUser;
  return {
    user,
    initialValues: {
      u_username: user && user.u_username,
      u_prac_username: user && user.u_prac_username,
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsernameUpdateForm);
