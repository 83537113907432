import React from 'react';
import PropTypes from 'prop-types';

import Membership from './Membership';
import PaymentInfo from './PaymentInfo';
import OrderHistory from './OrderHistory';
import Notifications from './Notifications';
import ChangePassword from './ChangePassword';
import PersonalAsessmentSettings from './PersonalAsessmentSettings';

import Promotions from '../PractitionerSettings/Promotions/Promotions';
import SalesHistory from '../PractitionerSettings/SalesHistory';
import SharedResults from '../PractitionerSettings/SharedResult';
import BlockedAccounts from '../PractitionerSettings/BlockedAccounts';
import BusinessSettings from '../PractitionerSettings/BusinessSettings';
import PractitionerProfileSettings from '../PractitionerSettings/PractitionerProfile/PractitionerProfileSettings';

const SettingsContainer = ({ match }) => {
  switch (match.params.settings) {
    case 'general-settings':
      return <ChangePassword />;
    case 'profile':
      return <PractitionerProfileSettings />;
    case 'notifications':
      return <Notifications />;
    case 'membership':
      return <Membership />;
    case 'payment-info':
      return <PaymentInfo />;
    case 'order-history':
      return <OrderHistory />;
    case 'personal-asessment':
      return <PersonalAsessmentSettings />;
    case 'business':
      return <BusinessSettings />;
    case 'promotions':
      return <Promotions />;
    case 'sales-history':
      return <SalesHistory />;
    case 'shared-results':
      return <SharedResults />;
    case 'blocked-accounts':
      return <BlockedAccounts />;
    default:
      return <ChangePassword />;
  }
};

SettingsContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      topicId: PropTypes.string,
      settings: PropTypes.string,
    }),
  }).isRequired,
};

export default SettingsContainer;
