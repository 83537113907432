/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserComments from '../UserComments/UserComments';
import './Comment.css';
import { addComment, updateComment, deleteComment } from '../../../store/actions/contentAction';
import ModalComponent from '../../shared/Modal/Modal';
import { commentNameSpace } from '../../../socket.client';

// const comments = data.default.comments;
class CommentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: props.comments.length > 10,
      commentsToShow: 10,
      comment: '',
      commenting: false,
      modalOpen: false
    };
  }

  componentDidMount() {
    const { contentId, updateComment } = this.props;
    commentNameSpace.emit('room', `room:${contentId}`);
    commentNameSpace.on('new-comment', comment => {
      updateComment(comment);
    });
  }

  addCommentHandler = () => {
    const { addComment, contentId } = this.props;
    const { comment } = this.state;
    if (!comment.trim()) return;
    this.setState({ commenting: true });
    addComment(contentId, comment, () => {
      this.setState({ comment: '', commenting: false });
    });
  };

  deleteCommentHandler = (comment_id) => {
    this.setState({ modalOpen: true, commentId: comment_id})
  };

  deleteComment = () => {
    const { deleteComment, contentId } = this.props;
    const { commentId } = this.state;
    deleteComment(commentId, contentId, () => { this.setState({ modalOpen: false }); })
  }

  renderUserComments = () => {
    const { comments, content, user } = this.props;
    let content_owner = null;
    let user_id = null;
    if(content && content.content_owner){
      content_owner = content.content_owner._id;
      user_id = user && user._id ? user._id : null;
    }
    const userComment = comments.map((comment, i) => {
      let commentList;
      if (i < this.state.commentsToShow - 1)
        commentList = <UserComments key={comment._id} comment={comment} content_owner={content_owner} user_id={user_id} deleteComment={this.deleteCommentHandler}/>;
      return commentList;
    });
    return userComment;
  };

  enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if(code === 13 && !(event.key === 'Enter' && event.shiftKey)) {
        this.addCommentHandler();
    } 
  }

  render() {
    const { comments } = this.props;
    const { showMore, commentsToShow, comment, commenting, modalOpen } = this.state;
    return (
      <div className="commentComponent pb-3">
        <h6>{comments.length} Comments</h6>
        <textarea
          className="form-control comentBox"
          value={comment}
          onChange={e => this.setState({ comment: e.target.value })}
          placeholder="Enter your feedback your feedbacks……"
          onKeyPress={this.enterPressed.bind(this)}
          disabled = {commenting}
        />
        <div className="d-flex justify-content-end mt-2">
          <button type="button" className="btn gt-btn btn-sm p-2" onClick={this.addCommentHandler}>
            Comment
            {commenting && <span className="spinner-border spinner-border-sm ml-2" />}
          </button>
        </div>
        <ModalComponent
          size="small"
          onHide={() => this.setState({ modalOpen: false })}
          show={modalOpen}
          title="Delete Content"
          noHeader
        >
          <div className="d-flex flex-column p-2">
            <>
              <div className="d-flex align-items-center">
                <img src="/icons/delete.svg" alt="delete" />
                <div className="d-flex flex-column align-items-baseline pl-3 ml-2">
                  <h5 className="text-center font-weight-bold mb-0">Delete comment ?</h5>
                  <p className="text-black-50 mb-0">Are you sure you want to delete this comment ?</p>
                </div>
              </div>
              <div className="d-flex pt-4 justify-content-end">
                <button
                  type="submit"
                  onClick={() => this.setState({ modalOpen: false })}
                  className="btn p-2 btn-outline-white px-4 font-weight-normal"
                >
                  No
                </button>
                <button
                  type="submit"
                  onClick={this.deleteComment}
                  className="btn ml-3  btn-signup p-2 px-4 font-weight-normal"
                >
                  Yes
                </button>
              </div>
            </>
          </div>
        </ModalComponent>
        {this.renderUserComments(this.comments)}
        {showMore || comments.length > 10 ? (
          <div>
            <h6
              style={{ cursor: 'pointer' }}
              className="pl-5 pt-4"
              role="presentation"
              onClick={() =>
                this.setState({
                  commentsToShow: commentsToShow + 10,
                  showMore: !(commentsToShow + 10 >= comments.length),
                })
              }
            >
              SHOW MORE
            </h6>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  comments: state.content.comments,
});

CommentComponent.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.string.isRequired,
  content_owner: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
  addComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

CommentComponent.defaultProps = {
  comments: [],
};

export default connect(
  mapStateToProps,
  {
    addComment,
    updateComment,
    deleteComment
  }
)(CommentComponent);
