import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroller } from 'react-scroll';
import { setAssessmentAnswer } from '../../../../../store/actions/assessmentActions';
import TypeFormControl from '../../TypeFormControl/TypeFormControl';

class QuestionComponent extends Component {
  onAnswerChoose = (ans, noScroll = false) => {
    const nodes = Array.from(this.props.getContainer().childNodes);
    const nodeIndex = nodes.findIndex(node => {
      return node.id === this.props.question._id;
    });
    if (nodeIndex > -1 && nodes[nodeIndex].classList.contains('active')) {
      this.props.setAssessmentAnswer(ans, () => {
        if (noScroll) {
          return false;
        }
        const nodes = Array.from(this.props.getContainer().childNodes);
        const nodeIndex = nodes.findIndex(node => node.id === this.props.question._id);
        if (nodeIndex > -1 && nodeIndex + 1 < nodes.length) {
          const nextNode = nodes[nodeIndex + 1];
          scroller.scrollTo(nextNode.offsetTop - 200, {
            duration: 300,
            delay: 400,
            smooth: true,
            containerId: 'ContainerScroll',
          });
        }
      });
    }
  };

  getAnswer = qid => {
    const { answers } = this.props;
    const index = answers.findIndex(ans => ans.question_id === qid);
    if (index > -1) {
      const { assessment_answer, additional_detailes } = answers[index];
      return { assessment_answer, additional_detailes };
    }
    return {};
  };

  _getAnswer = question_id => {
    const { answers } = this.props;
    const index = answers.findIndex(ans => ans.question_id === question_id);
    return index > -1 ? answers[index] : {};
  };

  render() {
    const { question, onClick } = this.props;
    return (
      <div
        id={question._id}
        className="question-list-item"
        onClick={event => {
          onClick(event.target, event.currentTarget);
        }}
      >
        {question.question_order === 35 && (
          <h3 className="mb-5 font-weight-bold">
            <span className="d-block">ACE Trauma Assessment</span>
            {/* <span className="d-block">Prior to your 18th birthday:</span> */}
          </h3>
        )}        
        <p>          
          {question.question_order}. {question.question_order >= 35 && (<>Prior to your 18th birthday,<br /></>)}{`${question.question}?`}
        </p>
        <TypeFormControl
          name={question._id}
          additionalInfo={question.question_answer_additional_info}
          answer={this.getAnswer(question._id).assessment_answer}
          options={question.question_answer_options}
          textareaValue={this.getAnswer(question._id).additional_detailes}
          onAnswer={this.onAnswerChoose}
          question_order={question.question_order}
          questionnaire_type={question.questionnaire_type}
          sub_questions={question.sub_questions}
          id={question._id}
          _answer={this._getAnswer(question._id)}
          qType={question.question_type}
        />
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    answers: state.assessment.answers,
    questioned: state.assessment.questioned,
  };
};
export default connect(
  mapStatetoProps,
  { setAssessmentAnswer }
)(QuestionComponent);
