/* eslint-disable prefer-spread */
import React, { Component } from 'react';
import ModalComponent from '../../../shared/Modal/Modal';
import helplines from '../../../../data/helplines.json';
import './TypeFormControl.css';

class TypeFormControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textareaValue: props.textareaValue,
      suicideModal: false,
      moreInfoValue: '',
    };
    this.textAreaRef = React.createRef();
    this.therappyTextAreaRef = React.createRef();
    this.moreInfoField = React.createRef();
  }

  componentDidMount() {
    this.addAutoResize();
    const { _answer } = this.props;
    if (_answer.sub_question_answers && _answer.sub_question_answers.length) {
      const subAns = _answer.sub_question_answers.find(subAns => subAns.sub_question_id === '1');
      if (subAns) {
        this.therappyTextAreaRef.current.value = subAns.sub_question_answer;
      }
    }
    if(_answer && _answer.additional_detailes) {
      this.setState({ moreInfoValue: _answer.additional_detailes })
    }
    if (this.moreInfoField.current) {
      this.moreInfoField.current.style.boxSizing = 'border-box';
      const offset =
        this.moreInfoField.current.offsetHeight - this.moreInfoField.current.clientHeight;
      this.moreInfoField.current.style.height = 'auto';
      this.moreInfoField.current.style.height = `${this.moreInfoField.current.scrollHeight +
        offset}px`;
    }
    if (this.textAreaRef.current) {
      this.textAreaRef.current.style.boxSizing = 'border-box';
      const offset = this.textAreaRef.current.offsetHeight - this.textAreaRef.current.clientHeight;
      this.textAreaRef.current.style.height = 'auto';
      this.textAreaRef.current.style.height = `${this.textAreaRef.current.scrollHeight + offset}px`;
    }
  }

  therappyAnswer = () => {
    const { _answer } = this.props;
    if (_answer.sub_question_answers && _answer.sub_question_answers.length) {
      const therappyAns = _answer.sub_question_answers.find(
        subAns => subAns.sub_question_id === '2'
      );
      if (therappyAns) {
        return therappyAns.sub_question_answer;
      }
    }
  };

  componentDidUpdate() {
    this.addAutoResize();
    if (this.state.showTextArea === 'yes') {
      // this.textAreaRef.current.focus();
    }
  }

  addAutoResize = () => {
    document.querySelectorAll('[data-autoresize]').forEach(function(element) {
      element.style.boxSizing = 'border-box';
      const offset = element.offsetHeight - element.clientHeight;
      document.addEventListener('input', function(event) {
        event.target.style.height = 'auto';
        event.target.style.height = `${event.target.scrollHeight + offset}px`;
      });
      element.removeAttribute('data-autoresize');
    });
  };

  nextButtonHandler = event => {
    event.preventDefault();
    const { moreInfoValue } = this.state;
    const { onAnswer, id, _answer } = this.props;
    if (_answer) {
      onAnswer({
        question_id: id,
        assessment_answer: _answer.assessment_answer,
        additional_detailes: moreInfoValue,
      });
    }
  };

  onSelectOptions = event => {
    const { onAnswer, id, question_order } = this.props;
    const answer = event.target.value;
    if (answer === 'yes' && question_order === 34) this.setState({ suicideModal: true });
    onAnswer({
      question_id: id,
      assessment_answer: answer,
    }, answer === 'yes' ? true : false);
  };

  textAreaOkBtnHandler = event => {
    event.preventDefault();
    const { onAnswer, id } = this.props;
    onAnswer({
      question_id: id,
      assessment_answer: 'yes',
      additional_detailes: this.state.textareaValue,
    });
  };

  renderControls = type => {
    const { textareaValue, moreInfoValue } = this.state;
    const {
      id,
      answer,
      options,
      sub_questions,
      question_order,
      additionalInfo,
      onAnswer,
      qType,
      questionnaire_type,
    } = this.props;
    switch (qType) {
      case 'scale':
        return (
          <div className="range-input pb-4">
            <div
              className={['btn-group range-group', options.length === 5 ? 'often-group' : ''].join(
                ' '
              )}
              role="group"
              aria-label="Second group"
            >
              {Array.apply(null, { length: 11 }).map((e, i) => (
                <button
                  key={i}
                  type="button"
                  onClick={() => {
                    onAnswer({ question_id: id, assessment_answer: i }, true);
                    this.moreInfoField.current.focus();
                    const strLen = this.moreInfoField.current.value.length;
                    this.moreInfoField.current.setSelectionRange(strLen, strLen);
                  }}
                  className={[
                    'btn btn-scale',
                    +answer === i ? 'active' : '',
                    options.length === 5 ? 'often' : '',
                  ].join(' ')}
                >
                  {i}
                </button>
              ))}
            </div>
            <div className="mt-5 additinal-info-field">
              <textarea
                ref={this.moreInfoField}
                className="form-control gt-text-area"
                placeholder="Please provide details (optional)"
                data-autoresize
                onKeyPress={event => {
                  if (event.which === 13) this.nextButtonHandler(event);
                }}
                value={moreInfoValue}
                onChange={event => this.setState({ moreInfoValue: event.target.value })}
                rows="1"
              />
              <button
                type="button"
                className="btn btn-sm btn-light px-3 float-right ml-auto mt-3"
                onClick={this.nextButtonHandler}
              >
                Next
              </button>
            </div>
          </div>
        );
      case 'options':
        return (
          <>
            <div className="">
              {options.map((option, i) => (
                <div key={i} className="radio-option mr-3">
                  <input
                    name={id}
                    id={`${id}-${option}`}
                    onChange={this.onSelectOptions}
                    type="radio"
                    className="d-none"
                    checked={option === answer}
                    value={option}
                  />
                  <label htmlFor={`${id}-${option}`} className="">
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </label>
                </div>
              ))}
            </div>
            {questionnaire_type === 'therapy' && answer === 'yes' && (
              <div className="ml-3">
                {sub_questions.map((sq, i) => (
                  <div key={sq._id} className="mt-5">
                    <p>{`${i + 1}. ${sq.question} ?`}</p>
                    {sq.question_type === 'scale' && (
                      <div className="btn-group range-group" role="group" aria-label="Second group">
                        {Array.apply(null, { length: 11 }).map((e, i) => (
                          <button
                            key={i}
                            type="button"
                            onClick={() => {
                              onAnswer({
                                question_id: id,
                                assessment_answer: 'yes',
                                sub_question_id: sq._id,
                                sub_question_answer: i,
                              });
                            }}
                            className={[
                              'btn btn-scale',
                              +this.therappyAnswer() === i ? 'active' : '',
                            ].join(' ')}
                          >
                            {i}
                          </button>
                        ))}
                      </div>
                    )}
                    {sq.question_type === 'text' && (
                      <div className="col-lg-8 col-12 position-relative pl-0">
                        <textarea
                          placeholder="If yes, please provide details (optional)"
                          ref={this.therappyTextAreaRef}
                          data-autoresize
                          rows="1"
                          className="form-control gt-text-area"
                          onBlur={event => {
                            const answer = event.target.value;
                            if (answer) {
                              onAnswer({
                                question_id: id,
                                assessment_answer: 'yes',
                                sub_question_id: sq._id,
                                sub_question_answer: answer,
                              }, true);
                            }
                          }}
                        />
                        <button
                          className="text-area-ok"
                          type="button"
                          onClick={event => {
                            event.preventDefault();
                            onAnswer({
                              question_id: id,
                              assessment_answer: 'yes',
                              sub_question_id: sq._id,
                              sub_question_answer: this.therappyTextAreaRef.current.value,
                            }, true);
                          }}
                        >
                          OK
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {additionalInfo && answer === 'yes' && questionnaire_type !== 'therapy' && (
              <div className="row mt-2">
                <div className="col-lg-8 col-12 position-relative">
                  <textarea
                    ref={this.textAreaRef}
                    placeholder="If yes, please provide details (optional)"
                    data-autoresize
                    rows="1"
                    value={textareaValue || this.props.textareaValue}
                    className="form-control gt-text-area"
                    onChange={e => this.setState({ textareaValue: e.target.value })}
                    onKeyPress={e => {
                      if (e.which === 13) this.textAreaOkBtnHandler(e);
                    }}
                    // onBlur={this.textAreaOkBtnHandler}
                  />
                  <button
                    className="text-area-ok"
                    type="button"
                    onClick={this.textAreaOkBtnHandler}
                  >
                    OK
                  </button>
                </div>
                {question_order === 34 && (
                  <ModalComponent
                    show={this.state.suicideModal}
                    title="Resources"
                    onHide={() => this.setState({ suicideModal: false })}
                  >
                    <div className="px-2" id="helpline-modal">
                      <div className="text-center border-bottom">
                        <img className="mb-4" src="/images/helpline-logo.svg" alt="Helpline" />
                        <h3 className="mb-4 get-help-text">
                          Please get immediate help now. Here are some resources
                        </h3>
                      </div>
                      <div className="row mt-4">
                        <h4 className="col-12 state-title">United State</h4>
                        {helplines.US.map((helpline, i) => (
                          <div key={i} className="col-md-6 helpline">
                            <span>{`${helpline.address}:`}</span>
                            <span>{helpline.contact}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </ModalComponent>
                )}
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  render() {
    return <>{this.renderControls()}</>;
  }
}
export default TypeFormControl;
