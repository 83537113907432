/* eslint-disable no-empty */
/* eslint-disable camelcase */
import { toast } from "react-toastify";
import axios from "../../axios";
import {
  SET_PAGE_ACTIVE,
  SET_PRACTITIONER_PROFILE,
  SET_SESSION_STATUS,
  SET_SESSION_REQUESTS,
  UPDATE_SESSION_REQUESTS,
  SET_PRACTITIONER_CONTENT_LIST,
  SET_PRAC_BOOKMARK_STATUS,
  SET_USER_BLOCK_STATUS,
  SET_BLOCKED_ACCOUNTS,
  SET_SHARE_RESULT_STATUS,
  UPDATE_USER_REVIEW_LIST,
  SET_REQUEST_RESULT_STATUS,
} from "./types";

import { setCurrentUser, setError } from "./authActions";
import { setLoader } from "./appActions";

export const setPageActive = (step) => {
  return {
    type: SET_PAGE_ACTIVE,
    payload: step,
  };
};

export const setProfile = (profile) => {
  return {
    type: SET_PRACTITIONER_PROFILE,
    payload: profile,
  };
};

export const setBlockStatus = (blockStatus) => {
  return {
    type: SET_USER_BLOCK_STATUS,
    payload: blockStatus,
  };
};

export const updateSessionRequest = (sessionRequest) => (dispatch) => {
  dispatch({
    type: UPDATE_SESSION_REQUESTS,
    payload: sessionRequest,
  });
};

export const updateSessionStatus = (status) => (dispatch) => {
  dispatch({
    type: SET_SESSION_STATUS,
    payload: status,
  });
};

export const registerPractitioner = (practitionerInfo, callback) => async (
  dispatch
) => {
  dispatch(setError({}));
  dispatch(setLoader(true));
  try {
    const response = await axios.post(
      "/api/practitioner/signup",
      practitionerInfo
    );
    dispatch(setLoader(false));
    setCurrentUser(response.data.user);
    callback();
  } catch (error) {
    dispatch(setLoader(false));
    if (error.response.status === 409) {
      dispatch(
        setError({
          errorCode: error.response.status,
          errorMsg: "Email already exist.",
        })
      );
    } else {
      dispatch(
        setError({
          errorCode: error.response.status,
          errorMsg: "Something went wrong.Please try again!",
        })
      );
    }
  }
};

export const createProfile = (practitionerProfile) => (dispatch) => {
  dispatch(setLoader(true));
  axios
    .post("/api/practitioner/create_profile", practitionerProfile)
    .then((response) => {
      const { profile, user } = response.data;
      if (response.data.status) {
        dispatch(setProfile(profile));
        localStorage.setItem("user", JSON.stringify(user));
        dispatch(setCurrentUser(user));
        dispatch(setPageActive(7));
      }
      dispatch(setLoader(false));
    })
    .catch((error) => {
      dispatch(setLoader(false));
      console.log("error", error);
    });
};

export const requestSessionOrCancelSession = (practitioner_id) => async (
  dispatch
) => {
  try {
    dispatch(setLoader(true));
    const response = await axios.post("/api/practitioner/session_request", {
      practitioner_id,
    });
    const { sessionStatus } = response.data;
    dispatch({
      type: SET_SESSION_STATUS,
      payload: sessionStatus,
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    console.log("@@@@@@@@@@@@@", error.response.data);
  }
};

export const blockUser = (userId) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const response = await axios.post("/api/member/block_user", { userId });
    const { blockStatus, blockedAccounts } = response.data;
    // dispatch({
    //   type: SET_USER_BLOCK_STATUS,
    //   payload: blockStatus,
    // });
    dispatch(setBlockStatus(blockStatus));
    dispatch({
      type: SET_BLOCKED_ACCOUNTS,
      payload: blockedAccounts,
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    console.log("@@@@@@@@@@@@@", error.response.data);
  }
};

export const acceptOrRejectSessionRequest = (data) => async (dispatch) => {
  try {
    const response = await axios.put(
      "/api/practitioner/session_request_action",
      data
    );
    const { sessionRequest } = response.data;
    dispatch({
      type: UPDATE_SESSION_REQUESTS,
      payload: sessionRequest,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getSessionRequests = (cb) => async (dispatch) => {
  try {
    const response = await axios.get("/api/practitioner/session_requests");
    const { sessionRequests } = response.data;
    dispatch({
      type: SET_SESSION_REQUESTS,
      payload: sessionRequests,
    });
    cb();
    console.log("resp", sessionRequests);
  } catch (error) {
    cb();
  }
};

export const bookmarkPractitioner = (practitionerId) => async (dispatch) => {
  const data = {
    practitioner_id: practitionerId,
  };
  try {
    const response = await axios.post(
      "/api/member/bookmark_practitioner",
      data
    );
    const { bookmarkStatus } = response.data;
    console.log(response);
    dispatch({
      type: SET_PRAC_BOOKMARK_STATUS,
      payload: bookmarkStatus,
    });
  } catch (error) {
    toast.error("Something went wrong!");
  }
};

export const shareResult = (practitionerId) => async (dispatch) => {
  const data = {
    practitioner_id: practitionerId,
  };
  try {
    dispatch(setLoader(true));
    const response = await axios.post("/api/member/share_result", data);
    const { shareStatus } = response.data;
    dispatch({
      type: SET_SHARE_RESULT_STATUS,
      payload: shareStatus,
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    if (error && error.response && error.response.status === 402) {
      toast.error("Please Complete Your Assessment!");
    }
    console.log(error);
  }
};

export const ratePractitioner = (practitionerId, rating) => async (
  dispatch
) => {
  const data = {
    practitioner_id: practitionerId,
    rating,
  };
  try {
    await axios.post("/api/member/rating", data);
  } catch (error) {
    toast.error("Somthing Went wrong!");
  }
};

export const reviewPractitioner = (
  practitionerId,
  content,
  rating,
  cb
) => async (dispatch) => {
  const data = {
    practitioner_id: practitionerId,
    review: content,
  };
  try {
    dispatch(setLoader(true));
    const response = await axios.post("/api/member/review", data);
    const { review } = response.data;
    review.rating = rating;
    dispatch({
      type: UPDATE_USER_REVIEW_LIST,
      payload: review,
    });
    dispatch(setLoader(false));
    cb();
  } catch (error) {
    dispatch(setLoader(false));
    toast.error("Somthing Went wrong!");
  }
};

export const getPractitionerProfile = (id) => (dispatch) => {
  const query = id ? `/?id=${id}` : "";
  axios
    .get(`/api/practitioner/profile${query}`)
    .then((response) => {
      if (response.data.status) {
        dispatch(setProfile(response.data.profile));
        dispatch(setBlockStatus(response.data.profile.blockStatus));
      }
    })
    .catch(() => {});
};

export const getContents = () => async (dispatch) => {
  try {
    const response = await axios.get("/api/content/get_contents");
    const { contents } = response.data;
    dispatch({
      type: SET_PRACTITIONER_CONTENT_LIST,
      payload: contents,
    });
  } catch (error) {}
};

export const requestAssessmentResult = (userId) => async (dispatch) => {
  try {
    console.log("requestResultStatus ====:", 11);
    dispatch(setLoader(true));
    const response = await axios.post(
      "/api/practitioner/assessment_result_request",
      {
        user_id: userId,
      }
    );
    const { requestResultStatus } = response.data;
    console.log("requestResultStatus ====:", requestResultStatus);
    dispatch({
      type: SET_REQUEST_RESULT_STATUS,
      payload: requestResultStatus,
    });
    dispatch(setLoader(false));
  } catch (error) {
    dispatch(setLoader(false));
    console.log("Error", error);
  }
};
