/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Landing.css';
import PropTypes from 'prop-types';
import Button from '../../common/Button/Button';
import Navbar from './Navbar';
import Footer from '../../shared/Footer/Footer';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import PractitionerCard from '../../shared/Cards/PractitionerCard/PractitionerCard';
import { isAuthenticated } from '../../../auth.service';
import {
  getLandingPageData,
  loadRecentContents,
  loadPopularContentsLanding,
} from '../../../store/actions/appActions';
import SlidingCard from '../../shared/SlidingCard/SlidingCard';
import settings from '../../shared/SlidingCard/settings';
import categorySliderSettings from './categorySliderSettings';
import LandingSkeleton from './LandingSkeleton';
import Focus from './Focus';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      recentHasMore: false,
      recentPage: 1,
      popularHasMore: false,
      popularPage: 1,
      processing: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { history, getLandingPageData } = this.props;
    if (isAuthenticated()) {
      history.push(`/user-feeds`);
    } else {
      this.setState({ loading: true });
      getLandingPageData(() => {
        this.setState({ loading: false });
      });
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  onContentClick = content_id => this.props.history.push(`/content/${content_id}`);

  loadRecent = () => {
    const { recentPage } = this.state;
    const { loadRecentContents } = this.props;
    this.setState({ processing: 'recent' });
    loadRecentContents(recentPage + 1, hasMore => {
      this.setState({ recentHasMore: hasMore, processing: '' });
    });
  };

  loadPopular = () => {
    const { popularPage } = this.state;
    const { loadPopularContentsLanding } = this.props;
    this.setState({ processing: 'popular' });
    loadPopularContentsLanding(popularPage + 1, hasMore => {
      this.setState({ popularHasMore: hasMore, processing: '' });
    });
  };

  render() {
    const { loading, recentHasMore, popularHasMore, processing } = this.state;
    const {
      popular_contents,
      recent_contents,
      featured_practitioners,
      new_practitioners,
      history,
      focuses,
    } = this.props;

    const Focuses = focuses.map(focus => <Focus key={focus._id} focus={focus} />);

    const PopularContents = popular_contents.map(content => (
      <ContentCard
        key={`popular${content._id}`}
        content={content}
        onClick={() => this.onContentClick(content._id)}
      />
    ));

    const RecentContents = recent_contents.map(content => (
      <ContentCard
        key={`recent${content._id}`}
        content={content}
        onClick={() => this.onContentClick(content._id)}
      />
    ));

    const FeaturedPractitioners = featured_practitioners.map(practitioner => (
      <PractitionerCard
        onClick={username => history.push(`/${username}`)}
        key={practitioner._id}
        practitioner={practitioner}
      />
    ));

    const newPractitioners = new_practitioners.map(practitioner => (
      <PractitionerCard
        onClick={username => history.push(`/${username}`)}
        key={practitioner._id}
        practitioner={practitioner}
      />
    ));

    return (
      <div id="landing-page">
        <div id="landing-banner" className="position-relative">
          <Navbar />
          <div className="container position-relative d-flex flex-column align-items-center justify-content-center py-4 px-lg-5 px-3 banner-container">
            <div className="text-center banner-content mb-4">
              <h1 className="m-0">Custom Matched Wellness & Personal Development</h1>
            </div>
            <div className="row mx-0 w-100">
              <div className="col-md-6 col-12">
                <div
                  role="presentation"
                  onClick={() => history.push('/signin?target=assessment')}
                  className="banner-button mb-4"
                >
                  <h6>Start Your Assessment</h6>
                  <p className="m-0">Get Tailored Insight & Resources</p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div
                  role="presentation"
                  onClick={() => history.push('/signup?u_type=practitioner')}
                  className="banner-button"
                >
                  <h6>Register As Practitioner</h6>
                  <p className="m-0">Connect With Your Ideal Clients & Showcase Your Work </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!loading && (
          <div className="container mt-5">
            <section className="overflow-hidden" id="focuses-section">
              <SlidingCard
                settings={categorySliderSettings}
                cardNumber={5.5}
                heading="Choose Your Focus"
              >
                {Focuses}
              </SlidingCard>
            </section>
            {PopularContents.length > 0 && (
              <section className="overflow-hidden" id="popular-section">
                <SlidingCard settings={settings} cardNumber={4} heading="Most Popular Content">
                  {PopularContents}
                  {popularHasMore && (PopularContents.length > 12) &&(
                    <div className="show-more-slide">
                      <button
                        type="button"
                        className="btn btn-link m-auto"
                        onClick={this.loadPopular}
                      >
                        {processing === 'popular' ? (
                          <span className="spinner-border text-primary" />
                        ) : (
                          'Show more'
                        )}
                      </button>
                    </div>
                  )}
                </SlidingCard>
              </section>
            )}
            {RecentContents.length > 0 && (
              <section className="overflow-hidden" id="popular-section">
                <SlidingCard
                  settings={settings}
                  cardNumber={4}
                  beforeChange={this.beforeChange}
                  heading="Recently Added"
                >
                  {RecentContents}
                  {recentHasMore && (RecentContents.length > 12) &&(
                    <div className="show-more-slide">
                      <button
                        type="button"
                        className="btn btn-link m-auto"
                        onClick={this.loadRecent}
                      >
                        {processing === 'recent' ? (
                          <span className="spinner-border text-primary" />
                        ) : (
                          'Show more'
                        )}
                      </button>
                    </div>
                  )}
                </SlidingCard>
              </section>
            )}
            {FeaturedPractitioners.length > 0 && (
              <section className="overflow-hidden">
                <SlidingCard settings={settings} cardNumber={4} heading="Featured Practitioners">
                  {FeaturedPractitioners}
                </SlidingCard>
              </section>
            )}
            {newPractitioners.length > 0 && (
              <section className="overflow-hidden">
                <SlidingCard settings={settings} cardNumber={4} heading="New Practitioners">
                  {newPractitioners}
                </SlidingCard>
              </section>
            )}
            <section className="my-4">
              <h2 className="slider-title h2 my-5 text-center">How Does GuideTrue work ?</h2>
              <div className="row justify-content-center">
                <div className="col-lg-4 mb-3">
                  <div className="how-it-work-card border rounded border-gt">
                    <div className="border-bottom border-gt pt-3 d-flex flex-column align-items-center">
                      <img
                        className="img-fluid p-4"
                        src="/images/practitioner-signup.svg"
                        height="116"
                        alt=""
                      />
                      <p className="display1 font-weight-bold mb-1">Practitioner</p>
                    </div>
                    <div className="py-3 px-5">
                      <div className="border rounded border-gt d-flex align-items-baseline px-4 py-2">
                        <span className="bullet d-block bg-primary rounded-circle mr-3" />
                        <div>
                          <p className="font-medium mb-0">Register</p>
                          <p className="display3 text-secondary m-0">
                            Tell us about you & your business
                          </p>
                        </div>
                      </div>
                      <img src="/icons/arrow-down-full.svg" alt="" style={{ marginTop: '-1px' }} />
                      <div
                        className="border rounded border-gt d-flex align-items-baseline px-4 py-2"
                        style={{ marginTop: '-1rem' }}
                      >
                        <span className="bullet d-block bg-primary rounded-circle mr-3" />
                        <div>
                          <p className="font-medium mb-0">Upload Your Content</p>
                          <p className="display3 text-secondary m-0 display3">
                            Add your Writings, Audio, Video, Images. Make it Free or Paid
                          </p>
                        </div>
                      </div>
                      <img src="/icons/arrow-down-full.svg" alt="" style={{ marginTop: '-1px' }} />
                      <div className="border rounded border-gt" style={{ marginTop: '-1rem' }}>
                        <div className="d-flex align-items-baseline px-4 my-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            Get Paid when People Purchase Your Content
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            Enjoy Automatic Matching and Publicity
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            Get Custom-Matched to the Right Client
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            Streamline Your Client Intake
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">Grow Your Business</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3">
                  <div className="how-it-work-card border rounded border-gt">
                    <div className="border-bottom border-gt pt-3 d-flex flex-column align-items-center">
                      <img
                        className="img-fluid p-4"
                        src="/images/member-signup.svg"
                        height="116"
                        alt=""
                      />
                      <p className="display1 font-weight-bold mb-1">Member</p>
                    </div>
                    <div className="px-5 py-3">
                      <div className="border rounded border-gt d-flex align-items-baseline px-4 py-2">
                        <span className="bullet d-block bg-primary rounded-circle mr-3" />
                        <div>
                          <p className="font-medium mb-0">Personal Assessment</p>
                          <p className="display3 text-secondary m-0">Tell us about you</p>
                        </div>
                      </div>
                      <img src="/icons/arrow-down-full.svg" alt="" style={{ marginTop: '-1px' }} />
                      <div
                        className="border rounded border-gt d-flex align-items-baseline px-4 py-2"
                        style={{ marginTop: '-1rem' }}
                      >
                        <span className="bullet d-block bg-primary rounded-circle mr-3" />
                        <div>
                          <p className="font-medium mb-0">We custom-Match</p>
                          <p className="display3 text-secondary m-0 display3">
                            Get connected to practitioners, content, special events, and more!
                          </p>
                        </div>
                      </div>
                      <img src="/icons/arrow-down-full.svg" alt="" style={{ marginTop: '-1px' }} />
                      <div className="border rounded border-gt" style={{ marginTop: '-1rem' }}>
                        <div className="d-flex align-items-baseline px-4 my-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            Enjoy A Freemium Library of Personalised Content
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            Automatically Connect With the Right Practitioner for you
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            Leave Community Reviews for Practitioner & Content
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">
                            See Your Progress Visualized
                          </p>
                        </div>
                        <div className="d-flex align-items-baseline px-4 mb-3">
                          <span className="bullet d-block bg-primary rounded-circle mr-3" />
                          <p className="font-medium mb-0 guide-text">Achieve your Goals Faster</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        {loading && (
          <div className="container mt-5">
            <LandingSkeleton />
          </div>
        )}

        <div
          id="landing-register-now"
          className="d-md-flex d-block text-center justify-content-center align-items-center px-3 py-4"
        >
          <p className="mb-md-0 mb-2">Are you a wellness professional ?</p>
          <Button varient="outline" onClick={() => history.push('/signup?u_type=practitioner')}>
            Signup Now
          </Button>
        </div>
        <Footer />
      </div>
    );
  }
}

Landing.propTypes = {
  focuses: PropTypes.arrayOf(PropTypes.object),
  popular_contents: PropTypes.arrayOf(PropTypes.shape({})),
  recent_contents: PropTypes.arrayOf(PropTypes.shape({})),
  featured_practitioners: PropTypes.arrayOf(PropTypes.shape({})),
  new_practitioners: PropTypes.arrayOf(PropTypes.shape({})),
  getLandingPageData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Landing.defaultProps = {
  focuses: [],
  popular_contents: [],
  recent_contents: [],
  featured_practitioners: [],
  new_practitioners: [],
};

const mapStateToProps = state => ({
  focuses: state.app.focuses,
  popular_contents: state.app.popular_contents,
  recent_contents: state.app.recent_contents,
  featured_practitioners: state.app.featured_practitioners,
  new_practitioners: state.app.new_practitioners,
});

export default connect(
  mapStateToProps,
  { getLandingPageData, loadRecentContents, loadPopularContentsLanding }
)(Landing);
