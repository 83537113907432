/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import './PractitionerOffers.css';

const PractitionerOffers = ({ promotions, className }) => {
  let PromotionsList = promotions.map(promotion => (
    <div key={promotion._id} className="prac_offers_body">
      <h6 className="px-3 pt-3"
        style={{
          color:'#212121', fontSize:17, fontWeight:'bold'
        }}>{promotion.promotion_title}</h6>
      <p className="px-3 mb-0 mt-4"
        style={{
          color:'#212121', fontSize:16, fontWeight:600
        }}>GET {promotion.promotion_value}{promotion.discount_type === "percentage" ? '%' : '$'} OFF</p>
      <p className="px-3 mb-0"
        style={{
          color:'#B99458', fontSize:24, fontWeight:900, marginTop:-5, wordBreak:'break-word'
        }}>{promotion.promotion_code.toUpperCase()}</p>
      <p className="mb-0 text-center mt-2"
        style={{
          color:'#212121', opacity:0.5, fontSize:12
        }}>Valid Until</p>
      <p className="date text-center py-1">{moment(promotion.promotion_end_date).format('DD MMM YYYY').toUpperCase()}</p>
    </div>
  ));
  if (PromotionsList.length === 0) {
    PromotionsList = (
      <div className="text-center py-5 px-3">
        <p className="m-0">No Promotions</p>
      </div>
    );
  }
  return (
    <div className={['prac_offers', className].join(' ')}>
      <div className="prac_offers_header">
        <h6 className="m-0">Offers you provide</h6>
      </div>
      <div className="prac_offers_body_container">{PromotionsList}</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser,
  };
};

PractitionerOffers.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};
PractitionerOffers.defaultProps = {
  className: '',
};

export default connect(mapStateToProps)(PractitionerOffers);
