/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getSharedResults } from '../../../store/actions/settingsActions';
import './PractitionerSettings.css';

const SharedResultsTable = ({ results }) => {
  const resultTable = results.map(result => {
    const profileImage = result.shared_by.u_image_url || '/images/user.svg';
    return (
      result.share_status === 'SHARED' && (
        <tr key={result._id}>
          <td>
            <div className="d-flex flex-row">
              <Link to={`/${result.shared_by.u_username}`}>
                <img src={profileImage} className="practitioner-profile-image" alt="prac_profile" />
              </Link>
              <div className="d-flex flex-column ml-2">
                <span className="practitioner-name">{result.shared_by.u_first_name}</span>
                <span className="practitioner-email">{result.shared_by.u_email}</span>
              </div>
            </div>
          </td>
          <td className="practitioner-date-added">
            {moment(result.shared_at).format('DD MMM YYYY')}
          </td>
           <Link to={`/assessment-result/${result.shared_by.u_username}`} className="btn btn-sm gt-btn mr-lg-5">
             View Result
            </Link>
        </tr>
      )
    );
  });
  return resultTable;
};

class SharedResults extends React.Component {
  componentDidMount = () => {
    const { getSharedResults } = this.props;
    getSharedResults();
  };

  render() {
    const { sharedResults } = this.props;
    const sortedSharedResults = sharedResults.sort((a, b) => {
      return new Date(b.shared_at) - new Date(a.shared_at);
    });
    return (
      <div id="shared-results">
        <h1 className="shared-results-title">Shared Results</h1>
        <p className="shared-results-subtitle">
          The progress result of the users who shared their results with you will be shown here.
        </p>
        <div className="table-responsive-md">
          {sortedSharedResults.length > 0 && (
            <table className="practitioner-time-table table table-borderless">
              <thead>
                <tr>
                  <th scope="col" className="practitioner-table-head">
                    Name
                  </th>
                  <th scope="col" className="practitioner-table-head">
                    Shared Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <SharedResultsTable results={sortedSharedResults} />
              </tbody>
            </table>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sharedResults: state.settings.sharedWith,
});
const mapDispatchToProps = {
  getSharedResults,
};
SharedResults.propTypes = {
  sharedResults: PropTypes.arrayOf(PropTypes.shape({})),
  getSharedResults: PropTypes.func.isRequired,
};
SharedResults.defaultProps = {
  sharedResults: [],
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharedResults);
