import { toast } from 'react-toastify';
import {
  ON_PAYMENT,
  SET_ALL_CARDS,
  MEMBERSHIP_DETAILS,
  CLEAR_PAYMENT_FORM,
  ADD_CARD,
  SET_LOADER,
  ON_DELETE_CARD,
  SET_CREDIT_CARD_LOADER,
  SET_PAYMENT_LOADER,
  ON_UPDATE_CARD,
  TOGGLE_ADD_CARD,
  SET_PURCHASED_CONTENT,
  SET_PROMO_CODE
} from './types';
import axios from '../../axios';

export const onPayment = planId => dispatch => {
  const body = {
    planId,
  };
  axios.post('/api/user/add-membership', body).then(res => {
    const { data } = res;
    if (data.status) {
      if (data.subscription) {
        const paymentMessage = {
          status: 'success',
          head: 'Hooray!',
          subHead:
            'You are successfully subscribed to GuideTrue Premium! Please remember to rate the Premium content you enjoy so practitioners get rewarded, and we can give you more like it!',
        };
        dispatch({
          type: ON_PAYMENT,
          payload: paymentMessage,
        });
      }
    }
  });
};

export const setLoader = loaderState => {
  return {
    type: SET_LOADER,
    payload: loaderState,
  };
};

export const addCreditCard = (token,cb) => (dispatch, getState) => {
  const { cards } = getState().settings;
  dispatch(setLoader(true));
  dispatch({
    type: CLEAR_PAYMENT_FORM,
    payload: false,
  });
  const body = {
    token,
  };
  axios.post('/api/user/add_credit_card', body).then(res => {
    dispatch(setLoader(false));
    dispatch({
      type: CLEAR_PAYMENT_FORM,
      payload: true,
    });
    const { data } = res;
    if (data.status) {
      if (cards.length === 0) {
        data.card.default_card = true;
      }
      dispatch({
        type: TOGGLE_ADD_CARD,
        payload: false,
      });
      dispatch({
        type: ADD_CARD,
        payload: data.card,
      });
    }
    cb(null);
  }).catch(err => {
    console.error('Add Card Failed', err);
    dispatch(setLoader(false));
    if(err && err.response && err.response.status === 400){
      cb(err.response.data.message);
    }
  });
};

export const deleteCard = (index, card) => dispatch => {
  dispatch(setLoader(true));
  const body = {
    id: card,
  };
  axios.put('/api/user/delete-card', body).then(res => {
    const { data } = res;
    dispatch(setLoader(false));
    toast.success('Credit Card Deleted');
    if (data.status) {
      if (data.deleted) {
        dispatch({
          type: ON_DELETE_CARD,
          payload: index,
        });
      }
    }
  });
};

export const updateCard = (card, expiryMonth, expiryYear, name) => dispatch => {
  dispatch(setLoader(true));
  dispatch({
    type: ON_UPDATE_CARD,
    payload: { updated: false },
  });
  const body = {
    card,
    expiryMonth,
    expiryYear,
    name,
  };
  axios.put('/api/user/update-card', body).then(res => {
    const { data } = res;
    toast.success('Credit Card Updated');
    dispatch(setLoader(false));
    if (data.status) {
      dispatch({
        type: ON_UPDATE_CARD,
        payload: { card: data.card, updated: true },
      });
    }
  });
};

export const cancelSubscription = () => dispatch => {
  dispatch({
    type: SET_PAYMENT_LOADER,
    payload: true,
  });
  axios.put('/api/user/cancel-membership').then(res => {
    dispatch({
      type: SET_PAYMENT_LOADER,
      payload: false,
    });
    const { data } = res;
    if (data.status) {
      dispatch({
        type: MEMBERSHIP_DETAILS,
        payload: { premiumUser: data.userMembership, membershipDetails: data.subscription },
      });
    }
  });
};

export const changeDefaultCard = cardId => dispatch => {
  dispatch({
    type: SET_CREDIT_CARD_LOADER,
    payload: true,
  });
  const body = {
    cardId,
  };
  axios.post('/api/user/change-default-card', body).then(res => {
    dispatch({
      type: SET_CREDIT_CARD_LOADER,
      payload: false,
    });
    toast.success('Default Card Updated');
    const { data } = res;
    if (data.status) {
      dispatch({
        type: SET_ALL_CARDS,
        payload: data.cards,
      });
    }
  });
};

export const makeContentPurchase = (cardId, finalPrice, couponId, usageRemaining) => dispatch => {
  dispatch({
    type: SET_PAYMENT_LOADER,
    payload: true,
  });
  axios.post(`/api/purchase/${cardId}`, {finalPrice, couponId, usageRemaining}).then(res => {
    const { data } = res;
    if (data.status && data.paymentSuccess) {
      dispatch({
        type: SET_PAYMENT_LOADER,
        payload: false,
      });
      dispatch({
        type: SET_PURCHASED_CONTENT,
        payload: true,
      });
    }
  }).catch(err => {
    dispatch({
      type: SET_PAYMENT_LOADER,
      payload: false,
    });
    if(err && err.response && err.response.status === 400) {
      toast.error(err.response.data.message);
      return;
    }
    toast.error('Somthing went wrong!')
  });
};

export const checkCouponCode = (promoCode, contentOwner) => (dispatch) => {
  dispatch(setLoader(true));
  axios
    .get(`/api/practitioner/get_promotion/${promoCode}/${contentOwner}`)
    .then(res => {
      dispatch(setLoader(false));
      let promotions = res.data.promotions;
      if (res.data.status) {
        dispatch({
          type: SET_PROMO_CODE,
          payload: promotions,
        });
      }
    })
    .catch(err => {
      toast.error('Something went wrong');
    });
};

export const clearCouponCode = () => (dispatch) => {
  dispatch(setLoader(true));
  dispatch({
    type: SET_PROMO_CODE,
    payload: null,
  });
  dispatch(setLoader(false));
};