/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Accordion, Card} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Navbar from '../../shared/Navbar/Navbar';
import Footer from '../../shared/Footer/Footer';
import {
  setSearchQuery,
  setSearchType,
  onSearch,
  clearSearchResult,
  getSpecialitiesList
} from '../../../store/actions/searchAction';
import PractitionerCard from '../../shared/Cards/PractitionerCard/PractitionerCard';
import ContentCard from '../../shared/Cards/ContentCard/ContentCard';
import InputRange from '../../common/InputRangeSlider/InputRangeSlider';
import Location from '../../common/Location/Location';
import './SearchResults.css';
import * as metaData from '../../../../src/data/meta.json';

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedQuery: '',
      loading: false,
      page: 0,
      hasMore: true,
      value: { min: 100, max: 500 },
      age: { min: 15, max: 60 },
      years: { min: 0, max: 30 },
      openCategories: true,
      openSpeciality: true,
      openLocation: false,
      openPromotion: false,
      openBusiness: false,
      openPayment: false,
      openYears: false,
      filterContentType: 'All',
      filterAccessType: 'recommended',
      filterCategoryList: [],
      contentTypeFilter: [],
      contentSortBy: '',
      PractitionerSortBy: '',
      specialitiesFilter: [],
      serviceFilter: [],
      sexualOrientationFilter: [],
      sessionTypeFilter: [],
      culturalBackgroundFilter: [],
      spiritualOrientationFilter: [],
      genderFilter: [],
      filterlist: '',
      contentCategoryCount: 15,
      practitionerSpecialityCount: 15,
      practitionerSexOrientationCount: 5,
      practitionerculturalBackgroundCount: 15,
      practitionerSpriritualOrientationCount: 15,
      practitionerGenderCount: 5
    };
    this.typeOfServices = [
      { label: 'Individual', value: 'individual' },
      { label: 'Group', value: 'group' },
      { label: 'Parties', value: 'parties' },
      { label: 'Remote', value: 'remote' },
      { label: 'Retreats', value: 'retreats' },
      { label: 'Workshops', value: 'work-shops' },
    ];
    this.sessionTypes = [
      { label: 'In Office', value: 'in-office' },
      { label: 'Outcall', value: 'out-call' },
      { label: 'Video Chat', value: 'video-chat' },
      { label: 'Phone', value: 'phone' },
      { label: 'Text', value: 'text' },
    ];
    this.contentTypes = [
      { label: 'Free', value: 'free' },
      { label: 'Premium', value: 'premium' },
      { label: 'Exclusive', value: 'exclusive' },
    ];
    this.specialities = [];
    this.spiritualOrientations = [];
    this.culturalHeritages = [];
    this.genders = [];
    this.address = null;
    this.checked = false;
    this.contentFilters = {};
  }

  componentDidMount() {
    const { location, setSearchQuery, setSearchType, onSearch, getSpecialitiesList, dispatch } = this.props;
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    const query = params.get('query') || ""
    getSpecialitiesList(dispatch);
    
    onSearch(type, query, 0, this.contentFilters, (err, hasMore) => {
      if (!err) {
        this.setState({ searchedQuery: query, hasMore });
      }
    });
    if (type || query) {
      setSearchQuery(query);
      setSearchType(type);
    }
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentDidUpdate(prevProps) {
    const { location, onSearch, clearSearchResult } = this.props;
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    const query = params.get('query');
    const prevParam = new URLSearchParams(prevProps.location.search);
    const prevType = prevParam.get('type');
    const prevQuery = prevParam.get('query');

    if (type !== prevType || query !== prevQuery) {
      clearSearchResult();
      this.setState({ page: 0, hasMore: true });
      onSearch(type, query, 0, this.contentFilters, (err, hasMore) => {
        if (!err) {
          this.setState({ searchedQuery: query, hasMore });
        }
      });
    }
  }

  componentWillUnmount() {
    const { clearSearchResult } = this.props;
    clearSearchResult();
    document.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    const { loading, page, hasMore, filterlist } = this.state;
    if (loading || !hasMore) return;

    const { location, onSearch } = this.props;
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    const query = params.get('query');

    // if (window.scrollTop >= docHeight - windowHeight - 100) {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 200
    ) {
      this.setState({ loading: true });
      onSearch(type, query, page + 1, filterlist, (err, hasMore) => {
        if (!err) {
          this.setState({
            page: page + 1,
            loading: false,
            hasMore,
          });
        }
      });
    }
  };

  onFilterData = () => {
    const { page, filterlist } = this.state;
    console.log('filterlist is clicked', filterlist);
    const { location, onSearch } = this.props;
    const params = new URLSearchParams(location.search);
    const type = params.get('type');
    const query = params.get('query');
    // console.log('filterlist', filterlist);
    onSearch(type, query, 0, this.contentFilters, (err, hasMore) => {
      if (!err) {
        this.setState({
          page: page,
          loading: false,
          hasMore, filterlist
        });
      }
    });
  }

  goToUser = username => {
    const { history } = this.props;
    history.push(`/${username}`);
  };

  onContentClick = contentId => {
    const { history } = this.props;
    history.push(`/content/${contentId}`);
  };

  onFilterContentType = contentType => {    
    this.contentFilters.contentType = contentType === 'All' ? '' : contentType;
    console.log('this.contentFilters----->', this.contentFilters);
    this.onFilterData();
    this.setState({filterContentType: contentType});
  }

  changePractionerContentTypeFilter = (type, event) => {
    const {contentTypeFilter} = this.state;
    let tempcontentTypeFilter = contentTypeFilter;
    if(event.target.checked){
      tempcontentTypeFilter.push(type)
      this.setState({contentTypeFilter: tempcontentTypeFilter});
    } else{
      tempcontentTypeFilter = tempcontentTypeFilter.filter(item => item !== type)
      this.setState({contentTypeFilter: tempcontentTypeFilter});
    }
    this.contentFilters.contentAccessType = tempcontentTypeFilter;
    this.onFilterData();
  }

  onFilterAccessType = AccessType => {
    console.log('filteraccesstype is clicked', AccessType);
    this.contentFilters.contentAccessType = AccessType !== 'recommended' && AccessType !== 'hotnew' ? AccessType : ''
    this.setState({filterAccessType: AccessType});
    this.onFilterData();
  }

  changePractionerSpecialityFilter = (category, event) => {
    // console.log('heyyyyyyyyyyyyyyyyyy', value);
    const {specialitiesFilter} = this.state;
    let tempspecialitiesFilter = specialitiesFilter;
    if(event.target.checked){
      tempspecialitiesFilter.push(category)
      this.setState({specialitiesFilter: tempspecialitiesFilter});
    } else{
      tempspecialitiesFilter = tempspecialitiesFilter.filter(item => item !== category)
      this.setState({specialitiesFilter: tempspecialitiesFilter});
    }
    this.contentFilters.specialization = tempspecialitiesFilter;
    this.onFilterData();
  }

  changePractionerServiceFilter = (service, event) => {
    const {serviceFilter} = this.state;
    let tempserviceFilter = serviceFilter;
    if(event.target.checked){
      tempserviceFilter.push(service)
      this.setState({serviceFilter: tempserviceFilter});
    } else{
      tempserviceFilter = tempserviceFilter.filter(item => item !== service)
      this.setState({serviceFilter: tempserviceFilter});
    }
    this.contentFilters.serviceType = tempserviceFilter;
    this.onFilterData();
  }

  changePractionerSexOrientationFilter = (orientaion, event) => {
    const {sexualOrientationFilter} = this.state;
    let tempsexualOrientationFilter = sexualOrientationFilter;
    if(event.target.checked){
      tempsexualOrientationFilter.push(orientaion)
      this.setState({sexualOrientationFilter: tempsexualOrientationFilter});
    } else{
      tempsexualOrientationFilter = tempsexualOrientationFilter.filter(item => item !== orientaion)
      this.setState({sexualOrientationFilter: tempsexualOrientationFilter});
    }
    this.contentFilters.sexOrientation = tempsexualOrientationFilter;
    this.onFilterData();
  }

  changePractionersessionTypeFilter = (session, event) => {
    const {sessionTypeFilter} = this.state;
    let tempsessionTypeFilter = sessionTypeFilter;
    if(event.target.checked){
      tempsessionTypeFilter.push(session)
      this.setState({sessionTypeFilter: tempsessionTypeFilter});
    } else{
      tempsessionTypeFilter = tempsessionTypeFilter.filter(item => item !== session)
      this.setState({sessionTypeFilter: tempsessionTypeFilter});
    }
    this.contentFilters.sessionType = tempsessionTypeFilter;
    this.onFilterData();
  }

  changePractionerculturalBackgroundFilter = (background, event) => {
    const {culturalBackgroundFilter} = this.state;
    let tempculturalBackgroundFilter = culturalBackgroundFilter;
    if(event.target.checked){
      tempculturalBackgroundFilter.push(background)
      this.setState({culturalBackgroundFilter: tempculturalBackgroundFilter});
    } else{
      tempculturalBackgroundFilter = tempculturalBackgroundFilter.filter(item => item !== background)
      this.setState({culturalBackgroundFilter: tempculturalBackgroundFilter});
    }
    this.contentFilters.culturalHeritages = tempculturalBackgroundFilter;
    this.onFilterData();
  }

  changePractionerSpiritualOrientationFilter = (orientation, event) => {
    const {spiritualOrientationFilter} = this.state;
    let tempspiritualOrientationFilter = spiritualOrientationFilter;
    if(event.target.checked){
      tempspiritualOrientationFilter.push(orientation)
      this.setState({spiritualOrientationFilter: tempspiritualOrientationFilter});
    } else{
      tempspiritualOrientationFilter = tempspiritualOrientationFilter.filter(item => item !== orientation)
      this.setState({spiritualOrientationFilter: tempspiritualOrientationFilter});
    }
    this.contentFilters.spiritualOrientations = tempspiritualOrientationFilter;
    this.onFilterData();
  }

  changePractionerGenderFilter = (gender, event) => {
    const {genderFilter} = this.state;
    let tempgenderFilter = genderFilter;
    if(event.target.checked){
      tempgenderFilter.push(gender)
      this.setState({genderFilter: tempgenderFilter});
    } else{
      tempgenderFilter = tempgenderFilter.filter(item => item !== gender)
      this.setState({genderFilter: tempgenderFilter});
    }
    this.contentFilters.gender = tempgenderFilter;
    this.onFilterData();
  }

  changeMinYearsofPractice = (e) => {
    const {years} = this.state;
    // console.log('changed changeMinYearsofPractice----->', e.target.value);

    let minValue = e.target.value;
    this.setState({years: {min: minValue, max: years.max}})
    this.contentFilters.minExp = minValue;
    this.contentFilters.maxExp = years.max;
    this.onFilterData();
  }

  changeMaxYearsofPractice = (e) => {
    const {years} = this.state;
    // console.log('changed changeMaxYearsofPractice----->', e.target.value);
    let maxValue = e.target.value;
    this.setState({years: {min: years.min, max: maxValue}})
    this.contentFilters.minExp = years.min;
    this.contentFilters.maxExp = maxValue;
    this.onFilterData();
  }

  changeSliderYears = (years) => {
    // console.log('changed changeSliderYears----->', years);
    this.setState({ years });
    this.contentFilters.minExp = years.min;
    this.contentFilters.maxExp = years.max;
    this.onFilterData();
  }

  changeMinAge = (e) => {
    const {age} = this.state;
    // console.log('changed changeMinAge----->', e.target.value);
    let minValue = e.target.value;
    this.setState({age: {min: minValue, max: age.max}})
    this.contentFilters.minAge = minValue;
    this.contentFilters.maxAge = age.max;
    this.onFilterData();
  }

  changeMaxAge = (e) => {
    const {age} = this.state;
    // console.log('changed changeMaxAge----->', e.target.value);
    let maxValue = e.target.value;
    this.setState({age: {min: age.min, max: maxValue}})
    this.contentFilters.minAge = age.min;
    this.contentFilters.maxAge = maxValue;
    this.onFilterData();
  }

  changeSliderAge = (age) => {
    // console.log('changed changeSliderAge----->', age);
    this.setState({ age });
    this.contentFilters.minAge = age.min;
    this.contentFilters.maxAge = age.max;
    this.onFilterData();
  }

  onChangeLocation = (loc) => {
    // console.log('locccccccccc----->', loc);
    this.contentFilters.lat = loc.coordinates.lat;
    this.contentFilters.long = loc.coordinates.lng;
    this.setState({ coOrdinates: loc.coordinates, address: loc.address});
    this.onFilterData();
  }

  changeContentFilterCategory = (category, event) => {
    const {filterCategoryList} = this.state;
    let tempfilterCategoryList = filterCategoryList;
    if(event.target.checked){
      tempfilterCategoryList.push(category)
      this.setState({filterCategoryList: tempfilterCategoryList});
    } else{
      tempfilterCategoryList = tempfilterCategoryList.filter(item => item !== category)
      this.setState({filterCategoryList: tempfilterCategoryList});
    }
    this.contentFilters.categories = tempfilterCategoryList;
    this.onFilterData();
  }

  inputFilterContentCategory = (value) => {
    // console.log('value------->', value);
    this.specialities = this.specialities.filter(e => e.category_name.includes(value))
    this.setState({contentCategoryFilterText : value});
    // console.log('value------->', this.specialities);
  }

  inputFilterPractitionerSpeciality = (value) => {
    console.log('value------->', value);
    this.specialities = this.specialities.filter(e => e.category_name.includes(value))
    this.setState({practitionerSpecialtyFilterText : value});
    console.log('value------->', this.specialities);
  }

  inputFilterPractitionerBackground = (value) => {
    // console.log('value------->', value);
    this.culturalHeritages = this.culturalHeritages.filter(e => e.name.includes(value))
    this.setState({practitionerHeritageFilterText : value});
    // console.log('value------->', this.culturalHeritages);
  }

  inputFilterPractitionerSpiritual = (value) => {
    // console.log('value------->', value);
    this.spiritualOrientations = this.spiritualOrientations.filter(e => e.name.includes(value))
    this.setState({practitionerSpiritualFilterText : value});
    // console.log('value------->', this.spiritualOrientations);
  }

  inputFilterPractitionerGender = (value) => {
    // console.log('value------->', value);
    // this.genders = this.genders.filter(e => e.name.includes(value))
    this.setState({practitionerGenderFilterText : value});
    // console.log('value------->', this.genders);
  }

  onChangeFilterSort = (event) => {
    this.contentFilters.orderBy = event.target.value;
    this.setState({contentSortBy: event.target.value});
    this.onFilterData();
  }

  onChangePractitionerFilterSort = (event) => {
    this.contentFilters.orderBy = event.target.value;
    this.setState({PractitionerSortBy: event.target.value})
    this.onFilterData();
  }

  clearAllPractitionerSpecializations = () => {
    // console.log('clear All Practitioner Specializations')
    this.contentFilters.specialization = [];
    this.setState({specialitiesFilter: [], practitionerSpecialityCount: 15});
    this.onFilterData();
  }

  clearAllPractitionerServices = () => {
    // console.log('clear All Practitioner Services')
    this.contentFilters.serviceType = [];
    this.setState({serviceFilter: []});
    this.onFilterData();
  }

  clearAllPractitionerSexOrientation = () => {
    // console.log('clear All Practitioner SexOrientation')
    this.contentFilters.sexOrientation = [];
    this.setState({sexualOrientationFilter: [], practitionerSexOrientationCount: 5});
    this.onFilterData();
  }

  clearAllPractitionerSessionTypes = () => {
    // console.log('clear All Practitioner SessionTypes')
    this.contentFilters.sessionType = [];
    this.setState({sessionTypeFilter: []});
    this.onFilterData();
  }

  clearAllPractitionerCulturalBackgrounds = () => {
    // console.log('clear All Practitioner CulturalBackgrounds')
    this.contentFilters.culturalHeritages = [];
    this.setState({culturalBackgroundFilter: [], practitionerculturalBackgroundCount: 15});
    this.onFilterData();
  }

  clearAllPractitionerSpiritualOrientations = () => {
    // console.log('clear All Practitioner SpiritualOrientations')
    this.contentFilters.spiritualOrientations = [];
    this.setState({spiritualOrientationFilter: [], practitionerSpriritualOrientationCount: 15});
    this.onFilterData();
  }

  clearAllPractitionerGenders = () => {
    // console.log('clear All Practitioner Genders')
    this.contentFilters.gender = [];
    this.setState({genderFilter: [], practitionerGenderCount: 5});
    this.onFilterData();
  }

  clearAllPractitionerLocation = () => {
    // console.log('clear Location')
    this.contentFilters.lat = null;
    this.contentFilters.long = null;
    this.setState({coOrdinates: null, address: null});
    this.onFilterData();
  }

  clearAllPractitionerFilters = () => {
    this.contentFilters = {};
    this.setState({
      specialitiesFilter: [], practitionerSpecialityCount: 15,
      serviceFilter: [],
      sexualOrientationFilter: [], practitionerSexOrientationCount: 5,
      sessionTypeFilter: [],
      culturalBackgroundFilter: [], practitionerculturalBackgroundCount: 15,
      spiritualOrientationFilter: [], practitionerSpriritualOrientationCount: 15,
      coOrdinates: null, address: null
    });
    this.onFilterData();
  }
  
  clearAllContentSpecializations = () => { 
    this.setState({filterCategoryList: [], contentCategoryCount: 15});
    this.contentFilters.categories = [];
    this.onFilterData();
  }

  clearAllContentTypes = () => {
    this.setState({contentTypeFilter: []});
    this.contentFilters.contentAccessType = [];
    this.onFilterData();
  }

  clearAllContentFilters = () => { 
    this.setState({filterCategoryList: [], contentCategoryCount: 15});
    this.contentFilters = [];
    this.onFilterData();
  }

  render() {
    const { type, results, specialities } = this.props;
    const { 
      searchedQuery, loading, 
      openSpeciality, openLocation, openBusiness, openYears, openCategories, openContentType,
      age, years,
      filterContentType, filterCategoryList,
      specialitiesFilter, serviceFilter, sexualOrientationFilter, sessionTypeFilter, 
      culturalBackgroundFilter, spiritualOrientationFilter, genderFilter, contentTypeFilter,
      contentCategoryFilterText, practitionerSpecialtyFilterText, practitionerHeritageFilterText, practitionerSpiritualFilterText,
      practitionerGenderFilterText, address
    } = this.state;
    
    this.specialities = specialities.categories;
    this.culturalHeritages = specialities.culturalHeritages;
    this.spiritualOrientations = specialities.spiritualOrientations;
    this.genders = metaData.default.genders;
    this.minExp = years.min;
    this.maxExp = years.max;
    this.minAge = age.min;
    this.maxAge = age.max;
    this.address = address
    // console.log('resulttssssssssss', results);
    if(contentCategoryFilterText && type === 'content'){
      this.specialities = this.specialities.filter(e => e.category_name.toLowerCase().includes(contentCategoryFilterText.toLowerCase()))
    }
    if(practitionerSpecialtyFilterText && type === 'practitioner'){
      this.specialities = this.specialities.filter(e => e.category_name.toLowerCase().includes(practitionerSpecialtyFilterText.toLowerCase()))
    }
    if(practitionerHeritageFilterText && type === 'practitioner'){
      this.culturalHeritages = this.culturalHeritages.filter(e => e.name.toLowerCase().includes(practitionerHeritageFilterText.toLowerCase()))
    }
    if(practitionerSpiritualFilterText && type === 'practitioner'){
      this.spiritualOrientations = this.spiritualOrientations.filter(e => e.name.toLowerCase().includes(practitionerSpiritualFilterText.toLowerCase()))
    }
    if(practitionerGenderFilterText && type === 'practitioner'){
      this.genders = this.genders.filter((e, i) => metaData.default.genders[i].toLowerCase().includes(practitionerGenderFilterText.toLowerCase()))
    }
    
    return (
      <div className="search-results" id="search-results">
        <Navbar />
        <div className="container" style={{ minHeight: '80vh' }}>
          <div className="row">
            {/* <div className="col-lg-3 bg-white mt-1" /> */}
            <div className="col-lg-3 mb-3">
              <div className="d-flex justify-content-between px-3 filterHead align-items-center">
                <p className="m-0 FilterHeading">Filter Results
                  {/* {type === 'content' ? 'Categories' : 'Filter Results'} */}
                  </p>
                <p className="m-0 clearAll" style={{ color:'#d4af37' }}
                  onClick={() => type === 'content' ? this.clearAllContentFilters() : this.clearAllPractitionerFilters()}>Clear All</p>
              </div>
              {type === 'content' ?
              <div className="contentFilters">
                {/* <div className="contentFilters">
                  {this.specialities && 
                    <>
                    {this.specialities.map((speciality, i) => (
                      <div className="d-flex flex-column" key={i}>
                        {i < contentCategoryCount &&
                        <div className="d-flex align-items-center mb-2">
                          <input
                            name="specialities"
                            type="checkbox"
                            className="checkbox mr-2"
                            checked={filterCategoryList.includes(speciality.category_name)}
                            onChange={(e) => this.changeContentFilterCategory(speciality.category_name, e)}
                          />
                          <p className="filterList mb-0 mr-auto">{speciality.category_name}</p>
                        </div>
                        }
                      </div>
                    ))}
                    { this.specialities.length - contentCategoryCount ?
                      <div className="d-flex justify-content-center viewMore"
                        onClick={() => this.setState({ 
                          contentCategoryCount: contentCategoryCount+10 < this.specialities.length ? contentCategoryCount+10 : this.specialities.length
                        })}>
                        View {this.specialities.length - contentCategoryCount} More
                      </div>
                      : 
                      <div className="d-flex justify-content-center viewMore"
                        onClick={() => this.setState({contentCategoryCount: 15})}>View Less</div>
                    }
                    </>
                  }
                </div> */}
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.setState({ openCategories: !openCategories})}>
                        <div className="d-flex justify-content-between">
                          <p>Categories</p>
                          {openCategories ?
                            <p>-</p>
                            :
                            <p>+</p>
                          }
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={specialities.categories && specialities.categories.length ? '0' : '1'}>
                        <Card.Body className="pt-0">
                          <>
                            {filterCategoryList.length ?
                            <p className="clearALL"
                             onClick={() => this.clearAllContentSpecializations()}><span className="closeClearAll">x</span> Clear All</p>
                             :<></>}
                            {this.specialities && 
                              <>
                              <div className="d-flex">
                                <svg
                                  className="cursor-pointer mt-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 25 25"
                                  onClick={this.search}
                                  style={{zIndex: 1}}
                                >
                                  <path
                                    fill="#9C9C9C"
                                    fillRule="nonzero"
                                    d="M24.649 22.892l-4.635-4.6c3.736-4.662 3.18-11.432-1.267-15.42a11.237 11.237 0 0 0-15.454.424C-.929 7.52-1.114 14.31 2.87 18.76a11.237 11.237 0 0 0 15.408 1.268l4.597 4.601a1.249 1.249 0 0 0 1.774 0 1.25 1.25 0 0 0 0-1.738zm-13.38-2.863c-4.83 0-8.744-3.918-8.744-8.751 0-4.833 3.915-8.751 8.744-8.751 4.83 0 8.745 3.918 8.745 8.751a8.755 8.755 0 0 1-2.561 6.188 8.741 8.741 0 0 1-6.184 2.563z"
                                  />
                                </svg>
                                <input className="textFilter mb-3 w-100"
                                onChange={(e)=> this.inputFilterContentCategory(e.target.value)}/>
                              </div>
                              <div className="scrollFilterList mb-3 pt-2">
                              {this.specialities.map((speciality, i) => (
                                <div className="d-flex flex-column" key={i}>
                                  {/* {i < contentCategoryCount && */}
                                  <div className="d-flex align-items-center mb-2">
                                    <input
                                      name="specialities"
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={filterCategoryList.includes(speciality.category_name)}
                                      onChange={(e) => this.changeContentFilterCategory(speciality.category_name, e)}
                                    />
                                    <p className="filterList mb-0 mr-auto">{speciality.category_name}</p>
                                    {/* <p className="filterListCount mb-0">{speciality.count}</p> */}
                                  </div>
                                  {/* } */}
                                </div>
                              ))}
                              </div>
                              {/* { this.specialities.length - contentCategoryCount > 0?
                                <div className="d-flex justify-content-center viewMore"
                                  onClick={() => this.setState({ 
                                    contentCategoryCount: contentCategoryCount+10 < this.specialities.length ? contentCategoryCount+10 : this.specialities.length
                                  })}>
                                  View {this.specialities.length - contentCategoryCount} More
                                </div>
                                : 
                                this.specialities.length > 15 ?
                                <div className="d-flex justify-content-center viewMore"
                                  onClick={() => this.setState({contentCategoryCount: 15})}>View Less</div>
                                  :<></>
                              } */}
                              </>
                            }
                          </>
                          </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.setState({ openContentType: !openContentType})}>
                        <div className="d-flex justify-content-between">
                          <p>Content Type</p>
                          {openContentType ?
                            <p>-</p>
                            :
                            <p>+</p>
                          }
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          <>
                            {contentTypeFilter.length ? 
                            <p className="clearALL"
                              onClick={() => this.clearAllContentTypes()}><span className="closeClearAll">x</span> Clear All</p>
                            : <></>}
                            {this.contentTypes && 
                              this.contentTypes.map((type, i) => (
                                <div className="d-flex flex-column" key={type.value}>
                                  <div className="d-flex align-items-center mb-2">
                                    <input
                                      name="specialities"
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={contentTypeFilter.includes(type.value)}
                                      onChange={(e)=> this.changePractionerContentTypeFilter(type.value, e)}
                                    />
                                    <p className="filterList mb-0">{type.label}</p>
                                  </div>
                                </div>
                              ))
                            }
                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
                :
                <div className="practitionerFilters">
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => this.setState({ openSpeciality: !openSpeciality})}>
                        <div className="d-flex justify-content-between">
                          <p>SPECIALITY</p>
                          {openSpeciality ?
                            <p>-</p>
                            :
                            <p>+</p>
                          }
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={specialities.categories && specialities.categories.length ? '0' : '1'}>
                        <Card.Body className="pt-0">
                          <>
                            {specialitiesFilter.length ?
                            <p className="clearALL"
                             onClick={() => this.clearAllPractitionerSpecializations()}><span className="closeClearAll">x</span> Clear All</p>
                             :<></>}
                            {this.specialities && 
                              <>
                              <div className="d-flex">
                                <svg
                                  className="cursor-pointer mt-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 25 25"
                                  onClick={this.search}
                                  style={{zIndex: 1}}
                                >
                                  <path
                                    fill="#9C9C9C"
                                    fillRule="nonzero"
                                    d="M24.649 22.892l-4.635-4.6c3.736-4.662 3.18-11.432-1.267-15.42a11.237 11.237 0 0 0-15.454.424C-.929 7.52-1.114 14.31 2.87 18.76a11.237 11.237 0 0 0 15.408 1.268l4.597 4.601a1.249 1.249 0 0 0 1.774 0 1.25 1.25 0 0 0 0-1.738zm-13.38-2.863c-4.83 0-8.744-3.918-8.744-8.751 0-4.833 3.915-8.751 8.744-8.751 4.83 0 8.745 3.918 8.745 8.751a8.755 8.755 0 0 1-2.561 6.188 8.741 8.741 0 0 1-6.184 2.563z"
                                  />
                                </svg>
                                <input className="textFilter mb-3 w-100"
                                onChange={(e)=> this.inputFilterPractitionerSpeciality(e.target.value)}/>
                              </div>
                              <div className="scrollFilterList mb-3 pt-2">
                              {this.specialities.map((speciality, i) => (
                                <div className="d-flex flex-column" key={i}>
                                  {/* {i < practitionerSpecialityCount && */}
                                  <div className="d-flex align-items-center mb-2">
                                    <input
                                      name="specialities"
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={specialitiesFilter.includes(speciality.category_name)}
                                      onChange={(e) => this.changePractionerSpecialityFilter(speciality.category_name, e)}
                                    />
                                    <p className="filterList mb-0 mr-auto">{speciality.category_name}</p>
                                    {/* <p className="filterListCount mb-0">{speciality.count}</p> */}
                                  </div>
                                  {/* } */}
                                </div>
                              ))}
                              </div>
                              {/* { this.specialities.length - practitionerSpecialityCount > 0  ?
                                <div className="d-flex justify-content-start viewMore"
                                  onClick={() => this.setState({ 
                                    practitionerSpecialityCount: practitionerSpecialityCount+10 < this.specialities.length ? practitionerSpecialityCount+10 : this.specialities.length
                                  })}>
                                  {this.specialities.length - practitionerSpecialityCount} More
                                </div>
                                : 
                                this.specialities.length > 15 ?
                                <div className="d-flex justify-content-start viewMore"
                                  onClick={() => this.setState({practitionerSpecialityCount: 15})}>View Less</div>
                                  :<></>
                              } */}
                              </>
                            }
                          </>
                          </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                {/* <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.setState({ openPrice: !openPrice})}>
                        <div className="d-flex justify-content-between">
                          <p>PRICE</p>
                          {openPrice ?
                            <p>-</p>
                            :
                            <p>+</p>
                          }
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-2">
                          <div className="d-flex flex-column">
                            <InputRange
                              minValue={10}
                              maxValue={1000}
                              value={value}
                              onChangeComplete={value => this.setState({ value })}
                              // onChangeComplete={this.onChangeComplete}
                              // formatLabel={this.formateTime}
                              step={100}
                              // varient={10}
                            />
                            <div className="d-flex justify-content-between align-items-baseline priceInputDiv">
                              <input value={value.min} className="priceInput"/>
                              <p className="priceTo">to</p>
                              <input value={value.max} className="priceInput"/>
                            </div>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div> */}
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.setState({ openLocation: !openLocation})}>
                        <div className="d-flex justify-content-between">
                          <p>LOCATION</p>
                          {openLocation ?
                            <p>-</p>
                            :
                            <p>+</p>
                          }
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          {address ? 
                            <div>
                            <p className="clearALL mb-0"
                              onClick={() => this.clearAllPractitionerLocation()}><span className="closeClearAll">x</span> Clear All</p>
                            <p className="mb-0">{address}</p>
                            </div>
                            : <></>}
                          <form className="profile-form">
                            <div className="locationDiv">
                              <Field
                                name="practitioner_location"
                                component={({ input }) => 
                                  <Location onLocationSelect={(loc)=> this.onChangeLocation(loc)} value={input.value} />
                                }
                              />
                            </div>
                          </form>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.setState({ openBusiness: !openBusiness})}>
                        <div className="d-flex justify-content-between">
                          <p>TYPE OF SERVICE</p>
                          {openBusiness ?
                            <p>-</p>
                            :
                            <p>+</p>
                          }
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          <>
                            {serviceFilter.length ? 
                            <p className="clearALL"
                              onClick={() => this.clearAllPractitionerServices()}><span className="closeClearAll">x</span> Clear All</p>
                            : <></>}
                            {this.typeOfServices && 
                              this.typeOfServices.map((service, i) => (
                                <div className="d-flex flex-column" key={service.value}>
                                  <div className="d-flex align-items-center mb-2">
                                    <input
                                      name="specialities"
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={serviceFilter.includes(service.label)}
                                      onChange={(e)=> this.changePractionerServiceFilter(service.label, e)}
                                    />
                                    <p className="filterList mb-0">{service.label}</p>
                                  </div>
                                </div>
                              ))
                            }
                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => this.setState({ openYears: !openYears})}>
                        <div className="d-flex justify-content-between">
                          <p>YEARS IN PRACTICE</p>
                          {openYears ?
                            <p>-</p>
                            :
                            <p>+</p>
                          }
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <InputRange
                            minValue={0}
                            maxValue={50}
                            value={years}
                            // onChangeComplete={years => this.setState({ years })}
                            onChangeComplete={(years) => this.changeSliderYears(years)}
                            step={1}
                          />
                          <div className="d-flex justify-content-between align-items-baseline priceInputDiv">
                            <input value={this.minExp} className="priceInput"
                            onChange={(e) => this.changeMinYearsofPractice(e)}
                            // onChange={(e) => this.setState({years: {min: e.target.value, max: years.max}})}
                            />
                            <p className="priceTo">to</p>
                            <input value={this.maxExp} className="priceInput"
                            onChange={(e) => this.changeMaxYearsofPractice(e)}
                            // onChange={(e) => this.setState({years: {min: years.min, max: e.target.value}})}
                            />
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="d-flex justify-content-between">
                          <p>SEXUAL ORIENTATION</p>
                          <p>+</p>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          <>
                            {sexualOrientationFilter.length ? 
                              <p className="clearALL"
                                onClick={() => this.clearAllPractitionerSexOrientation()}><span className="closeClearAll">x</span> Clear All</p>
                              : <></>}
                            <div className="scrollFilterList mb-3 pt-2">
                            {metaData.default.sexualOrientation && 
                              metaData.default.sexualOrientation.map((orientation, i) => (
                                <div className="d-flex flex-column" key={i}>
                                  {/* {i< practitionerSexOrientationCount && */}
                                  <div className="d-flex align-items-center mb-2">
                                  <input
                                    name="sexualorientation"
                                    type="checkbox"
                                    className="checkbox mr-2"
                                    checked={sexualOrientationFilter.includes(metaData.default.sexualOrientation[i])}
                                    onChange={(e) => this.changePractionerSexOrientationFilter(metaData.default.sexualOrientation[i], e)}
                                  />
                                    <p className="filterList mb-0">{metaData.default.sexualOrientation[i]}</p> 
                                  </div>
                                  {/* } */}
                                </div>
                              ))
                            }
                            </div>
                            {/* { metaData.default.sexualOrientation.length - practitionerSexOrientationCount ?
                              <div className="d-flex justify-content-start viewMore"
                                onClick={() => this.setState({ 
                                  practitionerSexOrientationCount: practitionerSexOrientationCount+5 < metaData.default.sexualOrientation.length ? practitionerSexOrientationCount+10 : metaData.default.sexualOrientation.length
                                })}>
                                {metaData.default.sexualOrientation.length - practitionerSexOrientationCount} More
                              </div>
                              : 
                              <div className="d-flex justify-content-start viewMore"
                                onClick={() => this.setState({practitionerSexOrientationCount: 5})}>View Less</div>
                            } */}
                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="d-flex justify-content-between">
                          <p>AGE</p>
                          <p>+</p>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <InputRange
                            minValue={15}
                            maxValue={60}
                            value={age}
                            // onChangeComplete={age => this.setState({ age })}
                            onChangeComplete={(age) => this.changeSliderAge(age)}
                            step={1}
                          />
                          <div className="d-flex justify-content-between align-items-baseline priceInputDiv">
                            <input value={this.minAge} className="priceInput"
                            onChange={(e) => this.changeMinAge(e)}/>
                            <p className="priceTo">to</p>
                            <input value={this.maxAge} className="priceInput"
                            onChange={(e) => this.changeMaxAge(e)}/>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                {/* <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="d-flex justify-content-between">
                          <p>TIME AVAILABILITY</p>
                          <p>+</p>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>Hello! I'm the body</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div> */}
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="d-flex justify-content-between">
                          <p>SESSION TYPE</p>
                          <p>+</p>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          <>
                            {sessionTypeFilter.length ? 
                              <p className="clearALL"
                                onClick={() => this.clearAllPractitionerSessionTypes()}><span className="closeClearAll">x</span> Clear All</p>
                              : <></>}
                            {this.sessionTypes && 
                              this.sessionTypes.map((session, i) => (
                                <div className="d-flex flex-column" key={session.value}>
                                  <div className="d-flex align-items-center mb-2">
                                    <input
                                      name="specialities"
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={sessionTypeFilter.includes(session.label)}
                                      onChange={(e)=> this.changePractionersessionTypeFilter(session.label, e)}
                                    />
                                    <p className="filterList mb-0">{session.label}</p>
                                  </div>
                                </div>
                              ))
                            }
                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="d-flex justify-content-between">
                          <p>CULTURAL BACKGROUND</p>
                          <p>+</p>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          <>
                          {culturalBackgroundFilter.length ? 
                            <p className="clearALL"
                              onClick={() => this.clearAllPractitionerCulturalBackgrounds()}><span className="closeClearAll">x</span> Clear All</p>
                            : <></>}
                            {this.culturalHeritages && 
                              <>
                              <div className="d-flex">
                                <svg
                                  className="cursor-pointer mt-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 25 25"
                                  onClick={this.search}
                                  style={{zIndex: 1}}
                                >
                                  <path
                                    fill="#9C9C9C"
                                    fillRule="nonzero"
                                    d="M24.649 22.892l-4.635-4.6c3.736-4.662 3.18-11.432-1.267-15.42a11.237 11.237 0 0 0-15.454.424C-.929 7.52-1.114 14.31 2.87 18.76a11.237 11.237 0 0 0 15.408 1.268l4.597 4.601a1.249 1.249 0 0 0 1.774 0 1.25 1.25 0 0 0 0-1.738zm-13.38-2.863c-4.83 0-8.744-3.918-8.744-8.751 0-4.833 3.915-8.751 8.744-8.751 4.83 0 8.745 3.918 8.745 8.751a8.755 8.755 0 0 1-2.561 6.188 8.741 8.741 0 0 1-6.184 2.563z"
                                  />
                                </svg>
                                <input className="textFilter mb-3 w-100"
                                onChange={(e)=> this.inputFilterPractitionerBackground(e.target.value)}/>
                              </div>
                              <div className="scrollFilterList mb-3 pt-2">
                              {this.culturalHeritages.map((heritage, i) => (
                                <div className="d-flex flex-column" key={heritage._id}>
                                  {/* {i< practitionerculturalBackgroundCount && */}
                                  <div className="d-flex align-items-center mb-2">
                                    <input
                                      name="culturalHeritages"
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={culturalBackgroundFilter.includes(heritage.name)}
                                      onChange={(e)=> this.changePractionerculturalBackgroundFilter(heritage.name, e)}
                                    />
                                    <p className="filterList mb-0">{heritage.name}</p>
                                  </div>
                                  {/* } */}
                                </div>
                              ))}
                              </div>
                              {/* { this.culturalHeritages.length - practitionerculturalBackgroundCount > 0 ?
                                <div className="d-flex justify-content-start viewMore"
                                  onClick={() => this.setState({ 
                                    practitionerculturalBackgroundCount: practitionerculturalBackgroundCount+10 < this.culturalHeritages.length ? practitionerculturalBackgroundCount+10 : this.culturalHeritages.length
                                  })}>
                                  {this.culturalHeritages.length - practitionerculturalBackgroundCount} More
                                </div>
                                : 
                                this.culturalHeritages.length > 15 ?
                                <div className="d-flex justify-content-start viewMore"
                                  onClick={() => this.setState({practitionerculturalBackgroundCount: 15})}>View Less</div>
                                  :<></>
                              } */}
                              </>
                            }
                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="d-flex justify-content-between">
                          <p>SPIRITUAL ORIENTATION</p>
                          <p>+</p>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          <>
                            {spiritualOrientationFilter.length ? 
                              <p className="clearALL"
                                onClick={() => this.clearAllPractitionerSpiritualOrientations()}><span className="closeClearAll">x</span> Clear All</p>
                              : <></>}
                            {this.spiritualOrientations && 
                              <>
                                <div className="d-flex">
                                  <svg
                                    className="cursor-pointer mt-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 25 25"
                                    onClick={this.search}
                                    style={{zIndex: 1}}
                                  >
                                    <path
                                      fill="#9C9C9C"
                                      fillRule="nonzero"
                                      d="M24.649 22.892l-4.635-4.6c3.736-4.662 3.18-11.432-1.267-15.42a11.237 11.237 0 0 0-15.454.424C-.929 7.52-1.114 14.31 2.87 18.76a11.237 11.237 0 0 0 15.408 1.268l4.597 4.601a1.249 1.249 0 0 0 1.774 0 1.25 1.25 0 0 0 0-1.738zm-13.38-2.863c-4.83 0-8.744-3.918-8.744-8.751 0-4.833 3.915-8.751 8.744-8.751 4.83 0 8.745 3.918 8.745 8.751a8.755 8.755 0 0 1-2.561 6.188 8.741 8.741 0 0 1-6.184 2.563z"
                                    />
                                  </svg>
                                  <input className="textFilter mb-3 w-100"
                                  onChange={(e)=> this.inputFilterPractitionerSpiritual(e.target.value)}/>
                                </div>
                                <div className="scrollFilterList mb-3 pt-2">
                                {this.spiritualOrientations.map((orientation, i) => (
                                  <div className="d-flex flex-column" key={orientation._id}>
                                    {/* {i< practitionerSpriritualOrientationCount && */}
                                    <div className="d-flex align-items-center mb-2">
                                      <input
                                        name="spiritualOrientations"
                                        type="checkbox"
                                        className="checkbox mr-2"
                                        checked={spiritualOrientationFilter.includes(orientation.name)}
                                        onChange={(e)=> this.changePractionerSpiritualOrientationFilter(orientation.name, e)}
                                      />
                                      <p className="filterList mb-0">{orientation.name}</p>
                                    </div>
                                    {/* } */}
                                  </div>
                                ))}
                                </div>
                                {/* { this.spiritualOrientations.length - practitionerSpriritualOrientationCount > 0 ?
                                  <div className="d-flex justify-content-start viewMore"
                                    onClick={() => this.setState({ 
                                      practitionerSpriritualOrientationCount: practitionerSpriritualOrientationCount+10 < this.spiritualOrientations.length ? practitionerSpriritualOrientationCount+10 : this.spiritualOrientations.length
                                    })}>
                                    {this.spiritualOrientations.length - practitionerSpriritualOrientationCount} More
                                  </div>
                                  : 
                                  this.spiritualOrientations.length > 15 ?
                                  <div className="d-flex justify-content-start viewMore"
                                    onClick={() => this.setState({practitionerSpriritualOrientationCount: 15})}>View Less</div>
                                    : <></>
                                } */}
                              </>
                            }
                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
                <div>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <div className="d-flex justify-content-between">
                          <p>GENDER</p>
                          <p>+</p>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body className="pt-0">
                          <>
                            {genderFilter.length ? 
                              <p className="clearALL"
                                onClick={() => this.clearAllPractitionerGenders()}><span className="closeClearAll">x</span> Clear All</p>
                              : <></>}
                            {this.genders && 
                              <>
                              <div className="d-flex">
                                <svg
                                  className="cursor-pointer mt-2"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 25 25"
                                  onClick={this.search}
                                  style={{zIndex: 1}}
                                >
                                  <path
                                    fill="#9C9C9C"
                                    fillRule="nonzero"
                                    d="M24.649 22.892l-4.635-4.6c3.736-4.662 3.18-11.432-1.267-15.42a11.237 11.237 0 0 0-15.454.424C-.929 7.52-1.114 14.31 2.87 18.76a11.237 11.237 0 0 0 15.408 1.268l4.597 4.601a1.249 1.249 0 0 0 1.774 0 1.25 1.25 0 0 0 0-1.738zm-13.38-2.863c-4.83 0-8.744-3.918-8.744-8.751 0-4.833 3.915-8.751 8.744-8.751 4.83 0 8.745 3.918 8.745 8.751a8.755 8.755 0 0 1-2.561 6.188 8.741 8.741 0 0 1-6.184 2.563z"
                                  />
                                </svg>
                                <input className="textFilter mb-3 w-100"
                                onChange={(e)=> this.inputFilterPractitionerGender(e.target.value)}/>
                              </div>
                              <div className="scrollFilterList mb-3 pt-2">
                              {this.genders.map((gender, i) => (
                                <div className="d-flex flex-column" key={i}>
                                  {/* {i< practitionerGenderCount && */}
                                    <div className="d-flex align-items-center mb-2">
                                    <input
                                      name="genders"
                                      type="checkbox"
                                      className="checkbox mr-2"
                                      checked={genderFilter.includes(this.genders[i])}
                                      onChange={(e)=> this.changePractionerGenderFilter(this.genders[i], e)}
                                      />
                                    <p className="filterList mb-0">{this.genders[i]}</p> 
                                  </div>
                                  {/* } */}
                                </div>
                              ))}
                              </div>
                              {/* { this.genders.length - practitionerGenderCount > 0 ?
                                <div className="d-flex justify-content-start viewMore"
                                  onClick={() => this.setState({ 
                                    practitionerGenderCount: practitionerGenderCount+10 < this.genders.length ? practitionerGenderCount+10 : this.genders.length
                                  })}>
                                  {this.genders.length - practitionerGenderCount} More
                                </div>
                                : 
                                this.genders > 5 ?
                                <div className="d-flex justify-content-start viewMore"
                                  onClick={() => this.setState({practitionerGenderCount: 5})}>View Less</div>
                                  :<></>
                              } */}
                              </>
                            }
                          </>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>}
            </div>
            <div className="col-lg-9">
              <div className="pt-4 header d-flex">
                <div className="mr-auto">
                  <h3 className="font-weight-bold">
                    {type === 'content' ? 'Content' : 'Practitioner'} Search Results
                  </h3>
                  {searchedQuery !=="" ? 
                     <p>
                        {results.length} Results found on “{searchedQuery}"
                     </p>
                    :
                    <p>
                       {results.length} Results found
                    </p>
                  }
                 
                </div>
                {type !== 'content' ?
                <div className="d-flex align-items-center">
                  <select
                    className="custom-select display2 border-gt py-0"
                    onChange={(e) => this.onChangePractitionerFilterSort(e)}
                  >
                    <option value="" hidden>Sort By</option>
                    <option value="rating">Rating</option>
                    <option value="name">Name</option>
                  </select>
                </div>
                : <></> }
              </div>
              {type === 'content' &&
                <div className="contentSearchHead mb-3">
                  <div className="d-flex contentSearchHeader">
                    <div className="d-flex justify-content-center align-items-center contentType col-3 text-center"
                      style={{borderRight: '2px solid #eaedf3'}}>
                      <p className={filterContentType === 'All' ? "activeContentType heading mb-0" : 'heading mb-0'} onClick={() => this.onFilterContentType('All')}>All</p>
                      {filterContentType === 'All' ? <p className="filterDot mb-0">.</p> : <></>}
                    </div>
                    <div className="d-flex justify-content-center align-items-center contentType col-3 text-center"
                      style={{borderRight: '2px solid #eaedf3'}}>
                      <p className={filterContentType === 'article' ? "activeContentType heading mb-0" : 'heading mb-0'} onClick={() => this.onFilterContentType('article')}>Article</p>
                      {filterContentType === 'article' ? <p className="filterDot mb-0">.</p> : <></>}
                    </div>
                    <div className="d-flex justify-content-center align-items-center contentType col-3 text-center"
                      style={{borderRight: '2px solid #eaedf3'}}>
                      <p className={filterContentType === 'audio' ? "activeContentType heading mb-0" : 'heading mb-0'} onClick={() => this.onFilterContentType('audio')}>Audio</p>
                      {filterContentType === 'audio' ? <p className="filterDot mb-0">.</p> : <></>}
                    </div>
                    <div className="d-flex justify-content-center align-items-center contentType col-3 text-center">
                      <p className={filterContentType === 'video' ? "activeContentType heading mb-0" : 'heading mb-0'} onClick={() => this.onFilterContentType('video')}>Video</p>
                      {filterContentType === 'video' ? <p className="filterDot mb-0">.</p> : <></>}
                    </div>
                  </div>
                  {/* <div className="d-flex contentSearchFooter">
                    <div className="d-flex col-7 mr-auto justify-content-between">
                      <div className={filterAccessType === 'recommended'? 'footerFilterTextDivActive' : 'footerFilterTextDiv'} onClick={() => this.onFilterAccessType('recommended')}>
                        <p className="mb-0 footerFilterText">Recommended For You</p>
                      </div>
                      <div className={filterAccessType === 'hotnew'? 'footerFilterTextDivActive' : 'footerFilterTextDiv'} onClick={() => this.onFilterAccessType('hotnew')}>
                        <p className="mb-0 footerFilterText">Hot & New</p>
                      </div>
                      <div className={filterAccessType === 'premium'? 'footerFilterTextDivActive' : 'footerFilterTextDiv'} onClick={() => this.onFilterAccessType('premium')}>
                        <p className="mb-0 footerFilterText">Premium</p>
                      </div>
                      <div className={filterAccessType === 'exclusive'? 'footerFilterTextDivActive mr-4' : 'footerFilterTextDiv mr-4'} onClick={() => this.onFilterAccessType('exclusive')}>
                        <p className="mb-0 footerFilterText">Exclusive</p>
                      </div>
                    </div>
                    <div className="mr-4">
                      <div>
                        <select
                          className="custom-select display2 border-gt py-0"
                          onChange={(e) => this.onChangeFilterSort(e)}
                        >
                          <option value="" hidden>Sort By</option>
                          <option value="rating">Rating</option>
                          <option value="title">Title</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                </div>
              }
              <div className="row">
                {type === 'content'
                  ? results.map((content, i) => (
                      <div className="col-lg-4 mb-3" key={i}>
                        <ContentCard
                          content={content}
                          onClick={() => this.onContentClick(content._id)}
                        />
                      </div>
                    ))
                  : results.map(practioner => (
                      <div className="col-xl-4 col-lg-4 col-md-6 mb-3" key={practioner._id}>
                        <PractitionerCard onClick={this.goToUser} practitioner={practioner} />
                      </div>
                    ))}
                {loading && (
                  <div className="p-3 d-flex w-100">
                    <div className="spinner-grow text-primary m-auto" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

SearchResults.propTypes = {
  specialities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string,
  getSpecialitiesList: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  // query: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.element),
  history: PropTypes.func.isRequired,
  setSearchType: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  clearSearchResult: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

SearchResults.defaultProps = {
  type: 'practitioner',
  // query: '',
  results: [],
  specialities: []
};

const mapStateToProps = state => {
  return {
    type: state.search.type,
    results: state.search.results,
    query: state.search.query,
    specialities: state.search.categories
  };
};

const SearchResultsForm = reduxForm({
  form: 'edit-location-form',
  enableReinitialize: true,
})(SearchResults);

export default connect(
  mapStateToProps,
  {
    getSpecialitiesList,
    onSearch,
    setSearchQuery,
    setSearchType,
    clearSearchResult
  }
)(SearchResultsForm);
