/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import Navbar from '../../shared/Navbar/Navbar';
import Footer from "../../shared/Footer/Footer";
import "./UserProfile.css";
import UserCard from "./UserCard/UserCard";
import ProgressCheckinCard from "./ProgressCheckinCard/ProgressCheckinCard";
import ContactsCard from "./ContactsCard/ContactsCard";
// import NotesCard from './NotesCard/NotesCard';
// import ResultOverview from '../assesment/AssessmentResult/ResultOverview';
import GTCard from "../../common/GTCard/GTCard";
import ResultCard from "../assesment/AssessmentResult/ResultCard";
import ContentTab from "./ContentTab/ContentTab";
import { requestAssessmentResult } from "../../../store/actions/practitionerRegAction";
import { render } from "react-dom";
import lodash from "lodash";
import { updateAnswer, getProfile } from '../../../store/actions/authActions'

class UserProfile extends Component {
  constructor(props) {
    super(props)
    this.state={
      itemsToShow: 3,
      expanded: false,
    }
  }

  showMore() {
    if(this.state.itemsToShow === 3 ){
      this.setState((state,props)=>{
        return {
         itemsToShow:props.profile.assessmentResult.answers.length,
          expanded: true 
        }
      })
    }else{
      this.setState({ itemsToShow: 3, expanded: false })
    }
  }

  // for update assessment answer
  onChange = (event, question_id) => {
    const data = {
      question_id,
      assessment_answer: event.target.value,
      user_id: this.props.currentUser._id,
    }
    const { updateAnswer, getProfile } = this.props
    updateAnswer(data, () => {
      getProfile(this.props.currentUser.u_username, () => {})
    })
  }

  //update optional message
  handleBlur(event, question_id,assessment_answer){
    const data={
      question_id,
      additional_detailes: event.target.value,
      user_id: this.props.currentUser._id,
      assessment_answer
    }
    const { updateAnswer, getProfile } = this.props
      updateAnswer(data, () => {
        getProfile(this.props.currentUser.u_username, () => {})
      })
  }

  renderAnswers = () => {
    const { profile, } = this.props
    const answers = lodash.orderBy(
      profile.assessmentResult ? profile.assessmentResult.answers :null,
      ['question_id.question_order'],
      ['asc'],
    )
    
    let Answers = null
    Answers = answers.slice(0,this.state.itemsToShow).map((answer) => (

      <div key={answer._id} className="mb-4">
            <p className="questions mt-4 ml-3">{answer.question_id.question}</p>
            <p className="ml-3 mr-3 answer">
              Answer:
              <select
                className="custom-select display2 border-gt py-0 "
                value={answer.assessment_answer}
                onChange={(event) =>
                  this.onChange(event, answer.question_id._id)
                }
              >
                {answer.assessment_answer === 'yes' ||
                answer.assessment_answer === 'no' ? (
                  <>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </>
                ) : (
                  <>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                  </>
                )}
              </select>
            </p>
            {(answer.assessment_answer !== 'no') && (
              <div>
                <textarea class="form-control optional" 
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Optional"
                  onBlur={(event) =>
                 this.handleBlur(event, answer.question_id._id,answer.assessment_answer)}
                >
                 {answer.additional_detailes}
               </textarea>
               </div>            
            )} 
      </div>
    ))
    return Answers
  }

  requestForResult = () => {
    const { profile, requestAssessmentResult } = this.props;
    requestAssessmentResult(profile.c_user._id);
  };

  render() {
    const {
      profile,
      bookmarkedPractitioners,
      currentUser,
      requestAssessmentResult,
      requestResultStatus,
    } = this.props;

    console.log(requestResultStatus);
  

    let assessmentAns=null; // assessment-heading
    assessmentAns=(
      <div className="heading">
      Assesment Result
      </div>
    )

    
    let ExploreResult = null;
    if (profile.assessment_status === "TAKEN") {
      ExploreResult = (
        <div className="ml-auto" style={{ lineHeight: 1 }}>
          <button
            type="button"
            style={{ lineHeight: 1 }}
            onClick={() => {
              this.props.history.push(
                `/assessment-result/${profile.c_user.u_username}`
              );
            }}
            className="btn btn-link p-0"
          >
            Explore Result
          </button>
        </div>
      );
    }

    let AssessmentResultEmptyState = null;
    if (profile.ownPerspective) {
      AssessmentResultEmptyState = (
        <div className="text-center py-5">
          <img
            src="/images/assessment-not-taken.svg"
            className="mb-3"
            alt="Assessment not Taken"
          />
          <p className="font-medium mb-1">Get Customized Guidance</p>
          <p className="display2 text-secondary px-lg-5 px-3 mx-lg-5">
            Get matched with practitioners & wellness resources to streamline
            your personal growth.
          </p>
          {profile.assessment_status === "NOT_COMPLETED" ? (
            <Link to="/assessment" className="btn gt-btn">
              Complete Your Assessment
            </Link>
          ) : (
            <Link to="/assessment" className="btn gt-btn">
              Start Your Assessment
            </Link>
          )}
        </div>
      );
    } else {
      AssessmentResultEmptyState = (
        <div className="text-center px-3 py-5">
          <p className="mb-4">You can Request for Sharing Assessment Result</p>
          <button
            type="button"
            className="btn gt-btn"
            onClick={() => this.requestForResult()}
          >
            {requestResultStatus === "requested"
              ? "Cancel Assessment Share Request"
              : "Request Assessment Result"}
          </button>
        </div>
      );
    }
    return (
      <>
        {profile && (
          <div id="user-profile">
            <div className="container py-3">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <UserCard user={profile.c_user} />
                </div>
                {profile.ownPerspective && (
                  <div className="col-lg-3 order-lg-0 order-1">
                    <ContactsCard users={bookmarkedPractitioners} />
                  </div>
                )}
                <div className="col">
                <div className="result-title mt-4">Assessment Result</div>
                  {(profile.ownPerspective ||
                    (currentUser &&
                      currentUser.u_prac_profile_completion_status)) && (
                    <GTCard
                      className="mt-3 border-0 nopadding"
                    > 
                      {profile.assessment_status === "TAKEN" ? (
                        <ResultCard
                         onScore={(score)=>{
                       this.setState({score})
                         }}
                         onCategory={(Cat)=>{
                           this.setState({Cat})
                         }}
                          categories={profile.assessmentResult.categories}
                          total={profile.assessmentResult.wholeness}
                          owner={
                            !profile.ownPerspective
                              ? profile.c_user.u_first_name
                              : null
                          }
                        />
                      ) : (
                        AssessmentResultEmptyState
                      )}
                    </GTCard>
                  )}
                 
                 {(profile.assessment_status === "TAKEN"
                  && (this.props.currentUser._id === profile.c_user._id ))&&(
                  <GTCard  className="mt-3" 
                   title={assessmentAns}
                   header={ExploreResult } >
                   {this.renderAnswers()}
                   <p>
                     <button
                       class="showbtn" 
                       onClick={this.showMore.bind(this)}
                      >
                       {this.state.expanded ? (
                          <span>Show less</span>
                                ) : (
                          <span>Show more</span>
                       )}
                     </button>
                   </p>
                  </GTCard>)}

                  {((profile.assessment_status === "TAKEN" &&
                    profile.ownPerspective) ||
                    (profile.progressCheckInStatus === "TAKEN" &&
                      currentUser.u_prac_profile_completion_status)) && (
                    <ProgressCheckinCard
                      className="mt-3"
                      data={profile.progrees_check_in}
                      status={profile.progressCheckInStatus}
                      ownPerspective={profile.ownPerspective}
                    />
                  )}
                  {profile.ownPerspective && (
                    <ContentTab className="mt-3" title="Saved Contents" />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}

UserProfile.propTypes = {
  profile: PropTypes.shape({
    c_user: PropTypes.shape({
      u_first_name: PropTypes.string,
      _id: PropTypes.string,
    }).isRequired,
    assessment_status: PropTypes.string,
    assessmentResult: PropTypes.shape({
      wholeness: PropTypes.number,
      categories: PropTypes.array,
    }),
    ownPerspective: PropTypes.bool,
  }),
  bookmarkedPractitioners: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  currentUser: PropTypes.shape({
    u_type: PropTypes.string,
    u_prac_profile_completion_status: PropTypes.bool,
  }),
  requestAssessmentResult: PropTypes.func.isRequired,
  requestResultStatus: PropTypes.string,
};

UserProfile.defaultProps = {
  profile: null,
  currentUser: null,
  bookmarkedPractitioners: [],
  requestResultStatus: "",
};

const mapStateToProps = (state) => ({
  profile: state.auth.profile,
  currentUser: state.auth.currentUser,
  bookmarkedPractitioners: state.member.bookmarkedPractitioners,
  requestResultStatus: state.practitionerReg,
});

export default withRouter(
  connect(mapStateToProps, {
    requestAssessmentResult,
    updateAnswer,
    getProfile,
  })(UserProfile),
)
