/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Input from '../../../common/Input/Input';
import { getStates, getCities } from '../../../../store/actions/appActions';
import Location from '../../../common/Location/Location';

const normalizeYears= value => {
  let onlyNums = value.replace(/[^0-9]/, '')
  return onlyNums.slice(0,2)
}

function PractitionerBusinessBackground(props) {
  const { countries, states, cities, getStates, getCities } = props;
  return (
    <div className="professional-services">
      <h1 className="professional-title">Business Information</h1>
      <div className="row mt-3">
        <div className="col-md-6 col-12 mb-3">
          <label className="sub-title">BUSINESS NAME</label>
          <Field name="business_name" placeholder="Business Name" component={Input} />
        </div>
        <div className="col-md-6 col-12 mb-3">
          <label className="sub-title">CONTACT NUMBER</label>
          <div className="row mt-0 pt-0">
            <div className="col-md-12 col-12">
              <Field name="phone_number" placeholder="Contact Number" component={Input} />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-3">
          <label className="sub-title">ADDRESS</label>
          <Field name="address" placeholder="Address" component={Input} />
        </div>
        <div className="col-md-6 col-12 mb-3">
          <label className="sub-title">WEBSITE</label>
          <Field name="website" placeholder="http://www.example.com" component={Input} />
        </div>
        <div className="col-md-6 col-12 mb-3">
          <label className="sub-title">YEARS PROFESSIONALLY PRACTICING</label>
          <Field
            name="experience"
            placeholder="Years Professionally Practicing"
            component={Input}
            normalize ={normalizeYears}
          />
        </div>

        <div className="col-md-6 col-12 mb-3">
          <label className="sub-title">LOCATION</label>
          <Field
            name="location"
            component={({ input }) => 
              <Location onLocationSelect={(loc)=> input.onChange(loc)} value={input.value} />
            }
          />
      </div>
      <div className="col-md-6 col-12 mb-3">
      <Field
          name="hide_business_info"
          type="checkbox"
          varient="material"
          component={Input}
          label={
            <p className="mb-0 ml-2" style={{ lineHeight: 1}}>
              Hide business Information
             </p>
          }
        />
      </div>
      </div>
    </div>
  );
}

PractitionerBusinessBackground.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  states: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getStates: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    countries: state.app.countries,
    states: state.app.states,
    cities: state.app.cities,
  };
};

const mapDispatchToProps = {
  getStates,
  getCities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PractitionerBusinessBackground);
